import {Component, Inject, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {AppointmentAttribute} from "../../../../services/appointmentAttribute";
import {COMMA, ENTER} from "@angular/cdk/keycodes";
import {MatChipInputEvent} from "@angular/material/chips";
import {CdkDragDrop, moveItemInArray} from "@angular/cdk/drag-drop";
import {TemplateSearchComponent} from "../template-search/template-search.component";
import {AttributeTemplate} from "../../../../services/attribute-template";

@Component({
  selector: 'app-attribute-add',
  templateUrl: './attribute-add.component.html',
  styleUrls: ['./attribute-add.component.scss']
})
export class AttributeAddComponent implements OnInit {
  form: UntypedFormGroup;
  attribute: AppointmentAttribute

  name: UntypedFormControl;
  type: UntypedFormControl;
  mandatory: UntypedFormControl;

  //attributes for drag & drop of values
  selectable = true;
  removable = true;
  addOnBlur = true;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  values: string[] = [];

  valuesDisabled: boolean = false;

  constructor(formBuilder: UntypedFormBuilder,
              private dialogRef: MatDialogRef<AttributeAddComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private dialog: MatDialog) {
    this.attribute = (data?.attribute == undefined) ? new AppointmentAttribute() : data.attribute;
    this.name = new UntypedFormControl(this.attribute.name, Validators.required);
    this.type = new UntypedFormControl(this.attribute.type, Validators.required);
    this.mandatory = new UntypedFormControl({value: this.attribute.mandatory ? "yes" : "no", disabled: this.calcMandatoryDisabled(this.attribute.type)}, Validators.required);

    this.form = new UntypedFormGroup({
      name: this.name,
      type: this.type,
      mandatory: this.mandatory
    });
  }

  ngOnInit(): void {
    this.form.valueChanges.subscribe(values => {
      this.valuesDisabled = this.calcValuesDiabled(values.type);
      if (this.calcMandatoryDisabled(values.type)) {
        this.mandatory.disable({emitEvent: false});
      } else {
        this.mandatory.enable({emitEvent: false})
      }
    })

    this.values = [...this.attribute.values];
    this.valuesDisabled = this.calcValuesDiabled(this.attribute.type);
  }

  private calcValuesDiabled(type: string) {
    return type !== 'ENUM';
  }

  private calcMandatoryDisabled(type: string) {
    return type === 'AGREEMENT' || type === 'BOOLEAN' || type === 'ENUM';
  }

  submit() {
    this.attribute.mandatory = this.mandatory.value == 'yes';
    this.attribute.name = this.name.value;
    this.attribute.type = this.type.value;
    this.attribute.values = this.values
    this.dialogRef.close(this.attribute)
  }

  addValue(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    if ((value || '').trim()) {
      this.values.push(value.trim());
    }

    if (input) {
      input.value = '';
    }
  }

  removeValue(value: string): void {
    const index = this.values.indexOf(value);

    if (index >= 0) {
      this.values.splice(index, 1);
    }
  }

  /**
   * drop for drag an drop
   * @param event
   */
  dropValue(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.values, event.previousIndex, event.currentIndex);
  }

  search() {
    console.log("search");
      const dialogRef = this.dialog.open(TemplateSearchComponent);
      dialogRef.afterClosed().subscribe(result => {
        if (result == undefined)
          return;

        this.useTemplate(result);
      });
  }

  private useTemplate(template: AttributeTemplate) {
    console.log(template.values);
    this.form.get('name').setValue(template.name, {emitEvent: false});
    this.form.get('type').setValue(template.type, {emitEvent: false});
    this.values = template.values;
  }
}
