import {Component, Input,} from '@angular/core';
import {MatDialog} from "@angular/material/dialog";
import {Wod} from "../../services/wod";
import {Session} from "../../services/session";
import {AddAnonymDialogComponent, AddAnonymDialogData} from "./add-anonym-dialog.component";

@Component({
  selector: 'app-add-anonym-button',
  templateUrl: './add-anonym-button.component.html',
  styleUrls: ['./add-anonym-button.component.scss']
})
export class AddAnonymDialogButton {
  @Input() timeslot: Wod;
  constructor(private session: Session, private dialog: MatDialog) {}

  canShowAnonymSubscription() : boolean {
    return true;
  }

  addAnonym() {
    let data: AddAnonymDialogData = {timeslot: this.timeslot};
    this.dialog.open(AddAnonymDialogComponent, {data: data, disableClose: true});
  }
}
