import {Component, OnDestroy, OnInit} from '@angular/core';
import {Api} from "../services/api";
import {map, switchMap, tap} from "rxjs/operators";
import {ContractType} from "../services/customer";
import {BehaviorSubject, Subscription} from "rxjs";
import {Session} from "../services/session";


/**
 * Die Komponente pollt regelmäßig nach Änderungen die den Admin interessieren könnten.
 * Wenn wir einen Push-Service für so etwas haben, dann können wir das pollen ersetzen.
 *
 * Derzeit wird alle x Minuten geschaut ob es Nutzer gibt die noch keinen Vertrag und auch kein Guthaben haben.
 * Wenn ja, dann wird das dem Admin angezeigt, so dass er entsprechend reagieren kann.
 */
@Component({
  selector: 'app-hintbox',
  templateUrl: './hintbox.component.html',
  styleUrls: ['./hintbox.component.scss']
})
export class HintboxComponent implements OnInit, OnDestroy {
  public show = new BehaviorSubject(false);
  private subscription: Subscription;

  public messages = new BehaviorSubject([]);
  private hasNewMessages = false;
  private newMessages = [];
  private oldMessages = [];

  constructor(private api: Api, private session: Session) {
  }

  ngOnInit(): void {
    this.session.serviceProviderChanged.pipe(
      switchMap(() => this.api.getCustomers()),
      map(it => it.filter(c => c.contractType == ContractType.NO_CONTRACT && c.prepaidUnits <= 0).length),
      tap(it => {
        this.resetNewMessages();
        this.handleCustomerWithoutContract(it);
        this.showNewMessages();
      })
    ).subscribe();
  }

  close() {
    this.show.next(false);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private resetNewMessages() {
    this.hasNewMessages = false;
    this.newMessages = [];
  }

  private handleCustomerWithoutContract(count: number) {
    if (this.session.serviceProviderVariant == 'school')
      return;

    if (count <= 0)
      return;

    this.addMessage("Du hast noch " + count + " Nutzer ohne Vertrag und Guthaben.");
  }

  private showNewMessages() {
    if (this.hasNewMessages == false)
      return;

    this.oldMessages = this.newMessages;
    if (this.newMessages.length == 0)
      return;

    this.show.next(true);
    this.messages.next(this.newMessages);

  }

  private addMessage(message: string) {
    if (this.oldMessages.filter(m => m == message).length >= 1)
      return;

    this.hasNewMessages = true;
    this.newMessages.push(message);
  }
}
