<div class="filter">
  <mat-form-field>
    <mat-label>Aktionen</mat-label>
    <mat-select [formControl]="changeTypesCtrl" multiple>
      <mat-optgroup label="Vertrag">
        <mat-option [value]="'SET_CONTINGENT_WEEKLY'">{{'SET_CONTINGENT_WEEKLY' | translate}}</mat-option>
        <mat-option [value]="'SET_CONTINGENT_MONTHLY'">{{'SET_CONTINGENT_MONTHLY' | translate}}</mat-option>
        <mat-option [value]="'SET_FLATRATE'">{{'SET_FLATRATE' | translate}}</mat-option>
        <mat-option [value]="'DROP_CONTRACT'">{{'DROP_CONTRACT' | translate}}</mat-option>
      </mat-optgroup>
      <mat-optgroup label="Guthaben">
        <mat-option [value]="'CHARGE_UNITS'">{{'CHARGE_UNITS' | translate}}</mat-option>
        <mat-option [value]="'CONSUME_UNITS'">{{'CONSUME_UNITS' | translate}}</mat-option>
        <mat-option [value]="'CHARGEBACK_UNITS'">{{'CHARGEBACK_UNITS' | translate}}</mat-option>
      </mat-optgroup>
      <mat-optgroup label="Kontingent">
        <mat-option [value]="'CONSUME_CONTINGENT_UNITS'">{{'CONSUME_CONTINGENT_UNITS' | translate}}</mat-option>
        <mat-option [value]="'CHARGEBACK_CONTINGENT_UNITS'">{{'CHARGEBACK_CONTINGENT_UNITS' | translate}}</mat-option>
      </mat-optgroup>
      <mat-option *ngFor="let changeType of changeTypes" [value]="changeType">{{changeType | translate}}</mat-option>
    </mat-select>
  </mat-form-field>
  <div class="summary">
    <ng-container *ngIf="loading"><b>lade Daten</b></ng-container>
    <ng-container *ngIf="!loading"><b>{{data.length}}</b> Einträge</ng-container>

  </div>
</div>

<ng-container *ngIf="loading">
<skeleton-block width="100%" height="1.2em" effect="wave"></skeleton-block>
</ng-container>


<table mat-table [dataSource]="data" class="mat-elevation-z8" *ngIf="!loading">
  <ng-container matColumnDef="date">
    <th mat-header-cell *matHeaderCellDef>Datum</th>
    <td mat-cell *matCellDef="let element"> {{element.logTimestamp | amDateFormat: 'DD.MM.YYYY HH:mm'}} </td>
  </ng-container>

  <ng-container matColumnDef="change-type">
    <th mat-header-cell *matHeaderCellDef>Aktion</th>
    <td mat-cell *matCellDef="let element"> {{element.changeType | translate}} </td>
  </ng-container>

  <ng-container matColumnDef="unit-amount">
    <th mat-header-cell *matHeaderCellDef>Einheiten</th>
    <td mat-cell *matCellDef="let element"> {{element.unitAmount}} </td>
  </ng-container>

  <ng-container matColumnDef="from">
    <th mat-header-cell *matHeaderCellDef>von</th>
    <td mat-cell *matCellDef="let element"> {{element.newContractFrom | amDateFormat: 'DD.MM.YYYY'}} </td>
  </ng-container>

  <ng-container matColumnDef="until">
    <th mat-header-cell *matHeaderCellDef>bis</th>
    <td mat-cell *matCellDef="let element"> {{element.newContractUntil | amDateFormat: 'DD.MM.YYYY'}} </td>
  </ng-container>

  <ng-container matColumnDef="author">
    <th mat-header-cell *matHeaderCellDef>Verantwortlicher</th>
    <td mat-cell *matCellDef="let element"> {{element.author?.name}} </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

</table>

