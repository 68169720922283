import * as moment from "moment";
import {Moment} from "moment";

export class Covid {
  state: "VACCINATED_3RD" | "VACCINATED_2ND" | "RECOVERED" | null;
  verified: boolean;
  date: Moment | null;

  get stateStr() : string {
    switch (this.state) {
      case "VACCINATED_3RD":
        return "3. Impfung";
      case "VACCINATED_2ND":
        return "2. Impfung";
      case "RECOVERED":
        return  "genesen"
      default:
        return  "unbekannt";
    }
  }

  static fromJson(data: any) : Covid {
    let result = new Covid();

    result.state = data.state;
    result.verified = data.verified;
    if (data.date)
      result.date = moment(data.date);

    return result;
  }
}
