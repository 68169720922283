import {Component} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms";
import {currentAppointment} from "../../actions/selectors";
import {filter} from "rxjs/operators";
import {AppointmentsState} from "../../actions/appointments";
import {Store} from "@ngrx/store";
import {Wod} from "../../../services/wod";
import {VideoLink} from "../../../services/video-link";
import {updateVideoLink} from "../../actions/appointment.actions";

@Component({
  selector: 'app-appointment-detail-links',
  templateUrl: './appointment-detail-links.component.html',
  styleUrls: ['./appointment-detail-links.component.scss']
})
export class AppointmentDetailLinksComponent {
  private fcTitle: UntypedFormControl;
  private fcDescription: UntypedFormControl;
  private fcUrl: UntypedFormControl;
  public formGroup: UntypedFormGroup;
  private appointment: Wod;

  constructor(private store: Store<AppointmentsState>) {
    this.fcTitle = new UntypedFormControl('', Validators.required);
    this.fcDescription = new UntypedFormControl('');
    this.fcUrl = new UntypedFormControl('', Validators.required);

    this.formGroup = new UntypedFormGroup({title: this.fcTitle, description: this.fcDescription, url: this.fcUrl});

    this.store.select(currentAppointment)
      .pipe(filter(it => it != null))
      .subscribe(it => this.initWithAppointment(it));
  }

  private initWithAppointment(wod: Wod) {
    this.appointment = wod;
    this.fcTitle.setValue(wod.videoLink?.title);
    this.fcDescription.setValue(wod.videoLink?.description);
    this.fcUrl.setValue(wod.videoLink?.url);
  }

  public submit() {
    let videoLink = new VideoLink();
    videoLink.title = this.fcTitle.value;
    videoLink.description = this.fcDescription.value;
    videoLink.url = this.fcUrl.value;
    this.store.dispatch(updateVideoLink({videoLink: videoLink, appointment: this.appointment}))
  }
}
