export class Preferences {
  /**
   * wie sollen die Termine dargestellt werden.
   * calendar | list
   */
  eventListLayout: string = "calendar";

  static fromJson(json: any) : Preferences {
    if (!json)
      return new Preferences();

    let result = new Preferences();
    result.eventListLayout = this._parseEventListLayout(json.event_list_layout);
    return result;
  }

  toJson() {
    return JSON.stringify({
      event_list_layout: this.eventListLayout
    });
  }

  private static _parseEventListLayout(value: string) {
    switch (value) {
      case "list":
        return "list"
      case "fullcalendar":
        return "fullcalendar"
      case "calendar":
      default:
        return  "calendar"
    }
  }
}

