import {Appointment} from "./appointment";
import {Observable} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {catchError, map} from "rxjs/operators";
import {BaseApi} from "./BaseApi";
import {Session} from "./session";
import {Store} from "@ngrx/store";
import {AppState} from "../actions/reducers";
import {Injectable} from "@angular/core";
import {Moment} from "moment";
import {AppointmentDto} from "./appointmentDto";

@Injectable()
export class AppointmentApi extends BaseApi {
  constructor(protected http: HttpClient, protected session: Session, protected store: Store<AppState>) {
    super(http, session, store);
  }

  /**
   * erstellt eine Buchung
   */
  addAppointment(appointment: Appointment): Observable<Appointment> {
    let serviceProvider = this.getServiceProviderIdentifier();
    let url = `/api/v2/tyrael/sp/${serviceProvider}/appointments`
    let body = {
      start: appointment.start.format(),
      end: appointment.end.format(),
      name: appointment.name,
      resources: appointment.resources?.map(r => r.id),
      numberOfPersons: appointment.numberOfPersons,
      email: appointment.email,
      note: appointment.note,
      accessCheck: appointment.accessCheck
    };

    return this.http
      .post<AppointmentDto>(url, body, {headers: this.getHeaders()})
      .pipe(
        catchError(err => this.handleNetworkError(err)),
        map(response => Appointment.fromDto(response)),
      )
  }

  /**
   * liste der direkten Buchungen
   * @param from
   * @param to
   */
  getAppointments(from: Moment, to: Moment): Observable<Appointment[]> {
    let serviceProvider = this.getServiceProviderIdentifier();
    let startAsString = from.format("YYYY-MM-DD");
    let endAsString = to.format("YYYY-MM-DD");
    let url = `/api/v2/tyrael/sp/${serviceProvider}/appointments?from=${startAsString}&to=${endAsString}`;
    // if (resourceId && resourceId != '') {
    //   url = url + `&resource=${resourceId}`
    // }

    return this.http
      .get<AppointmentDto[]>(url, {headers: this.getHeaders()})
      .pipe(
        catchError(err => this.handleNetworkError(err)),
        map(response => {return (<any>response).map(Appointment.fromDto);}),
      )
  }

  deleteAppointment(appointment: Appointment) {
    let serviceProvider = this.getServiceProviderIdentifier();
    let url = `/api/v2/tyrael/sp/${serviceProvider}/appointments/${appointment.id}`

    return this.http
      .delete<AppointmentDto>(url, {headers: this.getHeaders()})
      .pipe(
        catchError(err => this.handleNetworkError(err)),
      )
  }


  updateAppointment(appointment: Appointment) : Observable<Appointment>{
    let serviceProvider = this.getServiceProviderIdentifier();
    let url = `/api/v2/tyrael/sp/${serviceProvider}/appointments/${appointment.id}`
    let body = {
      start: appointment.start.format(),
      end: appointment.end.format(),
      name: appointment.name,
      resources: appointment.resources?.map(r => r.id),
      numberOfPersons: appointment.numberOfPersons,
      email: appointment.email,
      note: appointment.note,
      accessCheck: appointment.accessCheck
    };

    return this.http
      .put<AppointmentDto>(url, body, {headers: this.getHeaders()})
      .pipe(
        catchError(err => this.handleNetworkError(err)),
        map(response => Appointment.fromDto(response)),
      )
  }
}
