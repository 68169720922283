<div [formGroup]="formGroup">
  <mat-form-field class="form-field">
    <mat-label>{{label}}</mat-label>
    <input matInput type="number" formControlName="value">
  </mat-form-field>

  <mat-form-field>
    <mat-select formControlName="unit">
      <mat-option *ngFor="let unit of units" [value]="unit.value">
        {{unit.viewValue}}
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>
