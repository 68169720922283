import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ServiceProvider} from "../services/service-provider";

@Component({
  selector: 'app-service-provider-item',
  templateUrl: './service-provider-item.component.html',
  styleUrls: ['./service-provider-item.component.scss']
})
export class ServiceProviderItemComponent implements OnInit {
  @Input() serviceProvider: ServiceProvider;
  @Output() selected =  new EventEmitter<ServiceProvider>();

  constructor() { }

  ngOnInit(): void {
  }

  select(serviceProvider: ServiceProvider) {
    this.selected.emit(serviceProvider);
  }
}
