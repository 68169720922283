import {Injectable} from '@angular/core';
import {Action, Selector, State, StateContext} from '@ngxs/store';
import {ResourceTypesActions} from "./resource-types.actions";
import {tap} from "rxjs/operators";
import {ResourceTypesApi, ResourceTypeUpate} from "../../services/ResourceTypesApi";
import {MessageService} from "../../../shared/message.service";
import {Observable} from "rxjs";
import {ResetForm} from "@ngxs/form-plugin";
import {ResourceType} from "../../model/resource-type";

export interface ResourceTypesStateModel {
  items: ResourceType[],
  current_item: ResourceType,
  editForm: {
    model: any,
    dirty: boolean,
    status: string,
    errors: any
  }
}

@State<ResourceTypesStateModel>({
  name: 'resourceTypes',
  defaults: {
    items: [],
    current_item: null,
    editForm: undefined
  }
})
@Injectable()
export class ResourceTypesState {
  constructor(private resourceTypesApi: ResourceTypesApi, private messageService: MessageService) {
  }

  @Selector()
  static items(state: ResourceTypesStateModel): ResourceType[] {
    return state.items;
  }

  @Selector()
  static canSave(state: ResourceTypesStateModel): boolean {
    return state.editForm.dirty && (state.editForm.status == 'VALID');
  }

  @Selector()
  static currentItem(state: ResourceTypesStateModel): ResourceType {
    return state.current_item;
  }

  @Action(ResourceTypesActions.LoadAll)
  loadAll(ctx: StateContext<ResourceTypesStateModel>) {
    return this.resourceTypesApi.getAll().pipe(
      tap(
        it => {
          ctx.patchState({items: it})
        },
        error => {
          ctx.patchState({items: []});
          this.messageService.showMessage(error.getDisplayMessage())
        }
      )
    )
  }

  @Action(ResourceTypesActions.Create)
  create(ctx: StateContext<ResourceTypesStateModel>) {
    let newItem = new ResourceType();
    ctx.dispatch(new ResetForm({path: "resourceTypes.editForm"}))
    ctx.patchState({
      current_item: newItem,
    })
  }

  @Action(ResourceTypesActions.Edit)
  edit(ctx: StateContext<ResourceTypesStateModel>, action: ResourceTypesActions.Edit) {
    return this.resourceTypesApi.getById(action.id).pipe(
      tap(
        it => {
          ctx.dispatch(new ResetForm({path: "resourceTypes.editForm"}))
          ctx.patchState({
            current_item: it,
            editForm: {
              model: {
                name: it.name,
              },
              dirty: false,
              status: '',
              errors: {}
            }
          })
        },
        error => {
          ctx.patchState({current_item: null});
          console.log(error);
          this.messageService.showMessage(error.getDisplayMessage())
        }
      )
    )
  }

  @Action(ResourceTypesActions.Save)
  save(ctx: StateContext<ResourceTypesStateModel>) {
    let obs: Observable<any>;
    let apiModel = this.formModelToApiModel(ctx.getState().editForm.model);
    let resourceId = ctx.getState().current_item.id;

    if (resourceId)
      obs = this.resourceTypesApi.update(resourceId, apiModel);
    else
      obs = this.resourceTypesApi.create(apiModel);

    return obs.pipe(
      tap(
        () => {
          ctx.dispatch(new ResourceTypesActions.LoadAll());
          ctx.dispatch(new ResetForm({path: "resourceTypes.editForm"}))
          ctx.patchState({current_item: null});
          this.messageService.showMessage("Der Typ wurde erfolgreich gespeichert.");
        },
        error => {
          this.messageService.showMessage(error.getDisplayMessage())
        }
      )
    )
  }

  @Action(ResourceTypesActions.Delete)
  delete(ctx: StateContext<ResourceTypesStateModel>, action: ResourceTypesActions.Delete): Observable<any> {
    return this.resourceTypesApi.delete(action.id).pipe(
      tap(
        () => {
          ctx.dispatch(new ResourceTypesActions.LoadAll());
          ctx.dispatch(new ResetForm({path: "resourceTypes.editForm"}))
          ctx.patchState({current_item: null});
          this.messageService.showMessage("Der Typ wurde erfolgreich gelöscht.");
        },
        error => {
          this.messageService.showMessage(error.getDisplayMessage())
        }
      )
    )
  }

  private formModelToApiModel(model: any): ResourceTypeUpate {
    return {
      name: model.name,
    }
  }
}
