export class VideoLink {
  url: string;
  title: string;
  description: string;

  public static fromJson(data: any): VideoLink {
    let result = new VideoLink();
    result.url = data.url;
    result.title = data.title;
    result.description = data.desc;
    return result;
  }

  public toJson() {
    return {url: this.url, title: this.title, desc: this.description};
  }
}
