import {Member} from "./member";
import * as moment from "moment";
import {Moment} from "moment";
import {Participant} from "./participant";
import {Customer} from "./customer";
import {Period} from "../appointments/period";
import {VideoLink} from "./video-link";
import {PublicSubscription, PublicSubscriptionCondition} from "./public-subscription";
import {Resource} from "../resource-managment/model/resource";

export class Wod {
  public id: string;
  public seriesId: string;
  public name: string;
  public maxMembers: number;
  public accessCheck: string;
  public memberIds: string[];
  public start: Moment;
  public duration_minutes: number;
  public publiclyVisible: boolean = false; //ist dieser Termin öffentlcih sichtbar
  public informationalOnly: boolean = false; //eine Veranstaltung ohne Buchungsmöglichkeit

  /**
   * Wie wird die Buchung verrechnet: CONTINGENT, CONFIRMATION, OPEN_TO_CUSTOMERS
   * Bei Confirmation erfolgt keine Abbuchung vom Kontingent sondern die Buchung
   * muss von einem Admin bestätigt werden. Das kann z.B. dann eingesetzt werden
   * wenn zur Buchung eine Bezahlung bis zu 10 Tage nach der Anmeldung
   * erfolgen muss.
   */
  public subscribe_condition: string;
  /**
   * Anzahl der Tage die der Admin hat um eine Buchung zu bestätgien.
   * Diese Zahl hat aber keine Auswirkung auf den Prozeß. Es wirk sich dann nur auf
   * die Anzeige aus.
   */
  public subscribe_confirmation_days: number;
  public subscription_opening: Period;
  public subscription_closing: Period;
  public unsubscription_closing: Period;
  public videoLink: VideoLink = null;
  public numberOfParticipants: number;

  public canceled: boolean
  public description: string;

  /**
   * Buchungsbedingungen für öffentliche Buchungen
   */
  public publicSubscription: PublicSubscription;

  /**
   * Art der Wiederholung: FIXED, DAILY, WEEKLY, MONTHLY
   */
  public type: string;

  /**
   * bestätgite Teilnehmer
   */
  public participants: Participant[] = [];

  /**
   * unbestätigte Teilnehmer
   */
  public requestedParticipants: Participant[] = [];

  /**
   * Teilnehmer auf der Warteliste
   */
  public waitingList: Participant[] = [];

  public resources: Resource[] = [];

  public promotion: boolean = false;

  constructor() {
    this.id = null;
    this.publiclyVisible = false;
    this.publicSubscription = new PublicSubscription();
    this.subscribe_condition = "CONTINGENT";
  }

  /**
   * erstellt einen Timeslot und setzt Defaultwerte abhängig von der Variante des ServiceProviders
   * @param serviceProviderVariant
   * @param start
   * @param durationInMinutes
   */
  static createForVariant(serviceProviderVariant: string, start: Moment, durationInMinutes?: number) : Wod{
    let result = new Wod();

    result.type='FIXED';
    result.maxMembers = 8;
    result.accessCheck = 'NONE';
    result.start = start;
    result.subscribe_condition = 'CONTINGENT';

    if (durationInMinutes) {
      result.duration_minutes = durationInMinutes;
    }

    switch (serviceProviderVariant){
      case "school":
        result.subscribe_condition = 'OPEN_TO_CUSTOMERS';
        result.maxMembers = 1;
        break;
      case "museum":
        result.subscribe_condition = 'OPEN_TO_CUSTOMERS';
        result.publiclyVisible = true;
        result.publicSubscription = new PublicSubscription();
        result.publicSubscription.subscribeCondition = PublicSubscriptionCondition.OPEN_TO_ALL;
        break;
    }

    return result;
  }

  public copy(): Wod {
    return Object.assign(new Wod(), this);
  }

  public static fromJson(data: any): Wod {
    let result = new Wod();
    result.id = data.id;
    result.seriesId = data.seriesId;
    result.name = data.name;
    result.maxMembers = data.maxParticipants;
    result.accessCheck = data.accessCheck;
    result.memberIds = data.memberIds;
    result.duration_minutes = data.durationMinutes;
    result.canceled = data.canceled;
    result.start = moment(data.start);
    result.description = data.description;
    result.type = data.type;
    result.publiclyVisible = data.publiclyVisible;
    result.informationalOnly = data.informationalOnly;
    result.numberOfParticipants = data.numberOfParticipants;
    result.promotion = data.promotion;
    if (data.videolink) {
      result.videoLink = VideoLink.fromJson(data.videolink);
    } else {
      result.videoLink = null;
    }

    result.participants = data.participants.map(p => Participant.fromJson(p));
    result.requestedParticipants = data.participantsRequested.map(p => Participant.fromJson(p));
    result.waitingList = data.waitingList.map(p => Participant.fromJson(p));

    if (data.customer) {
      const s = PublicSubscription.fromJson(data.customer);
      result.subscription_opening = s.subscriptionOpening;
      result.subscription_closing = s.subscriptionClosing;
      result.unsubscription_closing = s.unsubscriptionClosing;
      result.subscribe_condition = data.customer.subscribeCondition;
      result.subscribe_confirmation_days = s.subscribeConfirmationDays;
    }

    if (data.public) {
      result.publicSubscription = PublicSubscription.fromJson(data.public)
    }

    data.resources.forEach(it => result.resources.push(Resource.fromJson(it)));

    return result;
  }

  get end(): Moment {
    if (!this.start || this.duration_minutes == null)
      return null;

    return moment(this.start).add(this.duration_minutes, "minutes");
  };

  /**
   * Anzahl aller bestätgiten und unbestätigten Teilnehmer
   */
  get numberOfMembers(): number {
    return this.participants?.length + this.requestedParticipants?.length;
  }

  get numberOfPersons(): number {
    return this.participants?.reduce((sum, current) => sum + current.numberOfPersons, 0)
  }

  public getDisplayName(): string {
    return this.name;
  }

  public isSuscribed(member: Member) {
    return this.memberIds.indexOf(member.id) > -1;
  }

  public getIconCssClass(): string {
    if (this.numberOfMembers >= this.maxMembers)
      return "wod-icon-full fas";

    if (this.numberOfMembers >= (this.maxMembers / 2))
      return "wod-icon-critical fas";

    return "wod-icon-safe fas";
  }

  isParticipant(customer: Customer) {
    return this.participants.find(p => p.userId == customer.id) != undefined;
  }

  hasParticipants() {
    return this.participants?.length > 0;
  }

  hasRequestedParticipants() {
    return this.requestedParticipants?.length > 0;
  }

  canDelete() {
    return !this.hasParticipants() && !this.hasRequestedParticipants();
  }

  canCancel() {
    return this.start.isAfter(moment());
  }

  /**
   * ist das ein Einzeltermin
   */
  isFixed(): boolean {
    return this.type == 'FIXED';
  }

  hasId() {
    return this.id !== null;
  }

  hasVideoLink() {
    return this.videoLink !== null;
  }

  hasResources() {
    return this.resources.length > 0;
  }

  isForResourceId(id: string) {
    if (!this.hasResources())
      return false;

    return this.resources.findIndex(it => it.id == id) != -1;
  }

  isForResourceName(name: string) {
    if (!this.hasResources())
      return false;

    return this.resources.findIndex(it => it.name == name) != -1;
  }

  initValuesFor(serviceProviderVariant: string) {
    this.initWithDefaultValues()
    if (serviceProviderVariant == "museum")
      this.initValuesForMuseum();

  }

  private initWithDefaultValues() {
    this.maxMembers = 8;
    this.duration_minutes = 60;
    this.subscription_opening = Period.buildAsRta(24 * 60 * 7);
    this.subscription_closing = Period.buildAsRta(60);
    this.unsubscription_closing = Period.buildAsRta(60);
    this.type = 'FIXED';
    this.subscribe_condition = 'CONTINGENT';
    this.subscribe_confirmation_days = 0;
    this.promotion = false;
  }

  private initValuesForMuseum() {
    this.publiclyVisible = true;
    this.subscribe_condition = 'OPEN_TO_CUSTOMERS';

    this.publicSubscription.subscriptionOpening = Period.buildAsRta(24 * 60 * 7);
    this.publicSubscription.subscriptionClosing = Period.buildAsRta(60);
    this.publicSubscription.unsubscriptionClosing = Period.buildAsRta(0);
    this.publicSubscription.subscribeCondition = PublicSubscriptionCondition.OPEN_TO_ALL;

    this.subscribe_confirmation_days = 0;
  }

  public get weekday() {
    switch (this.start.day()) {
      case 0:
        return "Sonntag";
      case 1:
        return "Montag";
      case 2:
        return "Dienstag";
      case 3:
        return "Mittwoch";
      case 4:
        return "Donnerstag";
      case 5:
        return "Freitag";
      default:
      case 6:
        return "Samstag";
    }
  }

}
