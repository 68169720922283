import {BrowserModule} from '@angular/platform-browser';
import {ErrorHandler, Injectable, NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {Api} from './services/api';
import {Session} from './services/session';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import {LoginComponent} from './login/login.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {TopMenuComponent} from './top-menu/top-menu.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {TextMaskModule} from 'angular2-text-mask';
import {MomentModule} from 'ngx-moment';
import {MetaReducer, StoreModule} from '@ngrx/store';
import {StoreDevtoolsModule} from '@ngrx/store-devtools';
import {environment} from '../environments/environment';
import {EffectsModule} from '@ngrx/effects';
import {AuthGuard} from './guard/auth.guard';
import {ServiceProviderGuard} from './guard/service-provider-guard.service';
import * as Sentry from '@sentry/browser';
import {version} from '../environments/version';
import {ProfileComponent} from './profile/profile.component';
import {ClipboardModule} from '@angular/cdk/clipboard';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {ServiceProviderSelectComponent} from './service-provider-select/service-provider-select.component';
import {ServiceProviderItemComponent} from './service-provider-item/service-provider-item.component';
import {PasswordResetComponent} from './password-reset/password-reset.component';
import {PasswordChangeComponent} from './password-change/password-change.component';
import {ImpressumComponent} from './impressum/impressum.component';
import {HintboxComponent} from './hintbox/hintbox.component';
import {AppMaterialModule} from './material.module';
import {AppointmentsModule} from './appointments/appointments.module';
import {MatSortModule} from '@angular/material/sort';
import {CustomersModule} from './customers/customers.module';
import {NgxChartsModule} from '@swimlane/ngx-charts';
import {AppEffects} from './actions/effects';
import * as fromAppReducer from './actions/reducers';
import {clearStateMetaReducer} from './actions/reducers';
import {SharedModule} from './shared/shared.module';
import {MessageLayoutComponent} from './message-display/message-layout/message-layout.component';
import {MessageDisplayComponent} from './message-display/message-display.component';
import {NgxsModule} from '@ngxs/store';
import {NgxsReduxDevtoolsPluginModule} from '@ngxs/devtools-plugin';
import {ResourceManagmentModule} from './resource-managment/resource-managment.module';
import {NgxsRouterPluginModule, RouterStateSerializer} from '@ngxs/router-plugin';
import {NgxsFormPluginModule} from '@ngxs/form-plugin';
import {CustomRouterStateSerializer} from './custom-router-state-serializer';
import {LoginService} from "./login/login-service";
import {DownloadDirective} from "./shared/download.directive";
import {ApiError} from "./services/error";
import {FullCalendarModule} from "@fullcalendar/angular";
import dayGridPlugin from '@fullcalendar/daygrid';
// import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import interactionPlugin from '@fullcalendar/interaction';
import timelinePlugin from '@fullcalendar/timeline';
import timeGridPlugin from '@fullcalendar/timegrid';
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
import resourceTimeGridPlugin from '@fullcalendar/resource-timegrid';
import {LoginByLinkComponent} from "./login/login-by-link/login-by-link.component";
import {RouteReuseStrategy} from "@angular/router";
import {AppRouteReuseStrategy} from "./AppRouteReuseStrategy";
import {EventStore} from "./services/event-store";
import {AppointmentApi} from "./services/appointment-api";
import {MAT_DATE_LOCALE} from "@angular/material/core";
import {MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldDefaultOptions} from "@angular/material/form-field";

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

Sentry.init({
  dsn: 'https://0ec3b4d5599c49fdb3218c2c9b96de09@sentry.patrix.biz/8',
  environment: window.location.hostname,
  release: version
});

FullCalendarModule.registerPlugins([ // register FullCalendar plugins
  dayGridPlugin,
  interactionPlugin,
  listPlugin,
  resourceTimelinePlugin,
  timelinePlugin,
  timeGridPlugin,
  resourceTimeGridPlugin
]);

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
  handleError(error) {
    if (error instanceof ApiError) {
      console.error(error);
      return;
    }

    Sentry.captureException(error.originalError || error);
    if (!environment.production)
      console.error(error);
  }
}



export const metaReducers: MetaReducer<any>[] = [clearStateMetaReducer];

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    LoginByLinkComponent,
    TopMenuComponent,
    ProfileComponent,
    ServiceProviderSelectComponent,
    ServiceProviderItemComponent,
    PasswordResetComponent,
    PasswordChangeComponent,
    ImpressumComponent,
    HintboxComponent,
    MessageDisplayComponent,
    MessageLayoutComponent,
    DownloadDirective
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    AppMaterialModule,
    MatSortModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    BrowserAnimationsModule,
    NgxChartsModule,
    TextMaskModule,
    MomentModule,

    StoreModule.forRoot({app: fromAppReducer.reducer}, {metaReducers}),
    EffectsModule.forRoot([AppEffects]),
    StoreDevtoolsModule.instrument({maxAge: 25, logOnly: environment.production}),

    ClipboardModule,
    TranslateModule.forRoot({
      defaultLanguage: 'default',
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      }
    }),
    AppointmentsModule,
    CustomersModule,
    SharedModule,
    ResourceManagmentModule,

    NgxsModule.forRoot([], {developmentMode: !environment.production}),
    NgxsRouterPluginModule.forRoot(),
    NgxsFormPluginModule.forRoot(),
    NgxsReduxDevtoolsPluginModule.forRoot(),
  ],
  providers: [
    Api,
    AppointmentApi,
    Session,
    AuthGuard,
    ServiceProviderGuard,
    LoginService,
    // {provide: ErrorHandler, useClass: SentryErrorHandler},
    {provide: RouterStateSerializer, useClass: CustomRouterStateSerializer},
    {provide: RouteReuseStrategy, useClass: AppRouteReuseStrategy},
    {provide: MAT_DATE_LOCALE, useValue: 'de-DE'},
    {provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: {appearance: 'outline'}},
    EventStore
  ],
  exports: [],
  bootstrap: [AppComponent]
})
export class AppModule {
}
