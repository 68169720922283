import {Component} from '@angular/core';
import {ConfirmDialogComponent, ConfirmDialogModel} from "../../shared/confirm-dialog/confirm-dialog.component";
import {MatDialog} from "@angular/material/dialog";
import {CustomersState} from "../actions/reducers";
import {Store} from "@ngrx/store";
import {currentCustomer} from "../actions/selectors";
import {Observable} from "rxjs";
import {Customer} from "../../services/customer";
import {deleteCustomer, sendResetPasswordMail} from "../actions/customer.actions";

@Component({
  selector: 'app-customer-actions',
  templateUrl: './customer-actions.component.html',
  styleUrls: ['./customer-actions.component.scss']
})
export class CustomerActionsComponent {
  customer$: Observable<Customer>;

  constructor(public dialog: MatDialog, private store: Store<CustomersState>) {
     this.customer$ = this.store.select(currentCustomer);
  }

  delete(customer: Customer) {
    const dialogData = new ConfirmDialogModel("Benutzer löschen", "Willst Du den Benutzer "+customer.name+" wirklich löschen?");

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "400px",
      data: dialogData
    });

    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult == false)
        return;

      this.store.dispatch(deleteCustomer({customerId: customer.id}))
    });
  }

  sendPasswordMail(customer: Customer) {
    const dialogData = new ConfirmDialogModel("Reset-Password Mail", "Willst Du eine E-Mail zum setzen eines neuen Passworts an "+customer.email+" senden?");

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "400px",
      data: dialogData
    });

    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult == false)
        return;

      this.store.dispatch(sendResetPasswordMail({customerId: customer.id}))
    });
  }
}
