import {Component, EventEmitter, Output} from '@angular/core';
import {Store} from "@ngrx/store";
import {CustomersState} from "../actions/reducers";
import {currentCustomer} from "../actions/selectors";
import {Observable} from "rxjs";
import {Customer} from "../../services/customer";
import {CustomerActions} from "../actions/action-types";
import {MatDialog} from "@angular/material/dialog";
import {BookPrepaidDialogComponent} from "../book-prepaid-dialog/book-prepaid-dialog.component";
import {bookPrepaidUnits} from "../actions/customer.actions";
import {ChangeNameDialogComponent, ChangeNameDialogData} from "../change-name-dialog/change-name-dialog.component";
import {ChangeCovidStateComponent} from "../change-covid-state/change-covid-state.component";
import {Api} from "../../services/api";
import {MessageService} from "../../shared/message.service";

@Component({
  selector: 'app-customer-data',
  templateUrl: './customer-data.component.html',
  styleUrls: ['./customer-data.component.scss']
})
export class CustomerDataComponent {
  customer$: Observable<Customer>;
  @Output() dataChanged = new EventEmitter<string>();

  constructor(private store: Store<CustomersState>, private api: Api,
              public dialog: MatDialog, private messageService: MessageService) {
    this.customer$ = this.store.select(currentCustomer);
  }

  setInstructor(customerId: string) {
    this.store.dispatch(CustomerActions.setInstructor({customerId: customerId}))
  }

  unsetInstructor(customerId: string) {
    this.store.dispatch(CustomerActions.unsetInstructor({customerId: customerId}))
  }

  bookPrepaid(customerId: string) {
    const dialogRef = this.dialog.open(BookPrepaidDialogComponent, {
      data: {units: 10}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (!result)
        return;

      this.store.dispatch(bookPrepaidUnits({customerId: customerId, units: result}))
    });
  }

  /**
   * @param customer
   */
  updateCustomer(customer: Customer) {
    const dialogRef = this.dialog.open(ChangeNameDialogComponent, {
      data: {firstname: customer.firstname, name: customer.name, note: customer.note}
    });

    dialogRef.afterClosed().subscribe((result: ChangeNameDialogData) => {
      if (!result)
        return;

      return this.api.updateCustomer(customer.id, result)
        .subscribe({
          next: () => {
            this.messageService.showMessage("Die Kundendaten wurde aktualisiert.");
            this.dataChanged.emit();
          },
          error: () => {
            this.messageService.showError("Beim aktualisieren der Kundendaten trat ein Fehler auf.")
          }
        })
    });
  }

  changeCovid(customer: Customer) {
    const dialogRef = this.dialog.open(ChangeCovidStateComponent, {
      data: {customer: customer}
    });

    dialogRef.afterClosed().subscribe(() => {
      this.dataChanged.emit();
    });
  }

}
