import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Resource} from "../../../../resource-managment/model/resource";
import {UntypedFormGroup} from "@angular/forms";
import {TextInputDialogComponent, TextInputDialogModel} from "../../../../shared/text-input-dialog/text-input-dialog.component";
import {MatDialog} from "@angular/material/dialog";

@Component({
  selector: 'app-ts-detail-resources',
  templateUrl: './ts-detail-resources.component.html',
  styleUrls: ['./ts-detail-resources.component.scss']
})
export class TsDetailResourcesComponent {
  @Input() resourceGroups: Map<string, Resource[]>;
  @Input() parent: UntypedFormGroup;
  @Output() createResource = new EventEmitter<string>()

  constructor( public dialog: MatDialog) {
  }

  getGroupNames(): string[]{
    return Array.from(this.resourceGroups.keys());
  }

  newResource() {
    const dialogRef = this.dialog.open(TextInputDialogComponent, {
      maxWidth: "400px",
      data: new TextInputDialogModel("Ressource erstellen", "Name der Ressource", "z.B. Raum 1 oder Bahn 4")
    });

    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult == false)
        return;

      this.createResource.emit(dialogResult);
    });
  }
}
