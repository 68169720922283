<mat-form-field class="filter">
  <mat-label>Filter</mat-label>
  <input matInput (keyup)="applyFilter($event.target.value)" placeholder="z.B. Name oder E-Mail">
</mat-form-field>

<mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8">
  <ng-container matColumnDef="name">
    <mat-header-cell *matHeaderCellDef mat-sort-header>Name</mat-header-cell>
    <mat-cell *matCellDef="let element"> {{element.displayName}}</mat-cell>
  </ng-container>

  <ng-container matColumnDef="email">
    <mat-header-cell *matHeaderCellDef mat-sort-header>E-Mail</mat-header-cell>
    <mat-cell *matCellDef="let element"> {{element.email}}</mat-cell>
  </ng-container>

  <ng-container matColumnDef="prepaidUnits">
    <mat-header-cell *matHeaderCellDef mat-sort-header>Prepaid Guthaben</mat-header-cell>
    <mat-cell *matCellDef="let element"> {{element.prepaidUnits}}</mat-cell>
  </ng-container>

  <ng-container matColumnDef="operations">
    <mat-header-cell *matHeaderCellDef>Aktionen</mat-header-cell>
    <mat-cell *matCellDef="let element">
      <button mat-stroked-button color="primary" (click)="selectCustomer(element)">hinzufügen</button>
    </mat-cell>
  </ng-container>

  <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
  <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
</mat-table>
