import {Component, Inject} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup} from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {Period} from "../../../period";

/**
 * Dialog zur Eingabe einer Periode.
 * übergeben werden kann eine Periode und nach dem "Übernehmen" wird auch eine neue Periode zurück gegeben
 */
@Component({
  selector: 'app-period-dialog',
  templateUrl: './period-dialog.component.html',
  styleUrls: ['./period-dialog.component.scss']
})
export class PeriodDialogComponent {
  formGroup: UntypedFormGroup;
  period: Period;
  rtBomEnabled: boolean;
  opening = false;

  constructor(
    public dialogRef: MatDialogRef<PeriodDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { period: Period, rtBomEnabled: boolean, opening?: boolean },
    fb: UntypedFormBuilder
  ) {
    this.formGroup = fb.group({
        rta_minutes: [''],
        bom: [''],
        method: ['']
      },
    );
    this.period = data.period;
    this.rtBomEnabled = data.rtBomEnabled;
    this.opening = data.opening ?? false;
    this.initForm();
  }

  private initForm() {
    if (this.period.isAtEnd()) {
      this.formGroup.get('method').setValue('at_end');
      this.formGroup.get('bom').disable();
      return;
    }

    if (this.period.isAtStart()) {
      this.formGroup.get('method').setValue('at_start');
      this.formGroup.get('bom').disable();
      return;
    }

    if (this.period.isRTA()) {
      this.formGroup.get('method').setValue('relative_to_appointment');
      this.formGroup.get('rta_minutes').setValue(this.period.rta.minutes);
      this.formGroup.get('bom').disable();
      return;
    }

    if (this.period.isRTBOM()) {
      this.formGroup.get('method').setValue('relative_to_bom');
      this.formGroup.get('bom').setValue(this.period.rtbom);
      this.formGroup.get('rta_minutes').disable();
      return;
    }
  }

  methodChange() {
    switch (this.formGroup.get('method').value) {
      case 'at_start':
        this.formGroup.get('rta_minutes').disable();
        this.formGroup.get('bom').disable();
        break;
      case 'at_end':
        this.formGroup.get('rta_minutes').disable();
        this.formGroup.get('bom').disable();
        break;
      case 'relative_to_appointment':
        this.formGroup.get('rta_minutes').enable();
        this.formGroup.get('bom').disable();
        break;
      default:
        this.formGroup.get('rta_minutes').disable();
        this.formGroup.get('bom').enable();
        break;
    }
  }

  onNoClick() {
    this.dialogRef.close();
  }

  onSubmitClick() {
    this.dialogRef.close(this.buildDialogData());
  }

  private buildDialogData() {
    let minutes = this.formGroup.get('rta_minutes').value;
    let bomForm = this.formGroup.get('bom').value;

    switch (this.formGroup.get('method').value) {
      case 'at_start':
        return Period.buildAsAtStart();
      case 'at_end':
        return Period.buildAsAtEnd();
      case 'relative_to_appointment':
        return Period.buildAsRta(minutes);
      case 'relative_to_bom':
        return Period.buildAsRtBom(bomForm.days, bomForm.hour, bomForm.minute);
      default:
        return null;
    }
  }

}
