export interface ApiErrorDto {
  errorCode: string;
  errorMessage: string;
  status: number;
  statusText: number;
}

export class ApiError {
  constructor(private code: string) {
  }

  public getDisplayMessage(): string {
    return this._code2Message();
  }


  private _code2Message() {
    switch (this.code) {
      case 'error.subscription':
        return 'Anmeldung nicht möglich. (unbekannter Grund)';
      case 'error.subscription.no_contract':
        return 'Du hast keinen gültigen Vertrag.';
      case 'error.subscription.customer_not_assigned':
        return 'Keine Kundenbeziehung';
      case 'error.subscription.before_opening':
        return 'Anmeldung nicht möglich da Anmeldezeit noch nicht gestartet ist.';
      case 'error.subscription.after_closing':
        return 'Anmeldung nicht möglich da Anmeldezeit abgelaufen ist.';
      case 'error.subscription.too_many_participants':
        return 'Anmeldung nicht möglich da Termin bereits voll';
      case 'error.unsubscription.not_subscribed':
        return 'Abmeldung nicht möglich da Du nicht angemeldet bist.';
      case 'error.unsubscription.before_opening':
        return 'Abmeldung nicht möglich, Anmeldezeit noch gar nicht gestartet';
      case 'error.unsubscription.after_closing':
        return 'Abmeldung nicht möglich da die Frist bereits abgelaufen ist.';
      case 'error.wodlist.loading':
        return 'Beim laden der Termine ist ein Fehler aufgetreten.';
      case 'error.providers.loading':
        return 'Beim laden der Anbieter ist ein Fehler aufgetreten.';
      case 'session.invalid':
        return 'Deine Session / Dein Token ist ungültig oder abgelaufen. Bitte melde Dich erneut an.';
      case 'operation.unauthorized':
        return 'Du hast keinen Erlaubnis diese Operation durchzuführen.';
      case 'authentication.data.wrong':
        return 'Deine Zugangsdaten sind falsch.';
      case 'network.communication.error':
        return 'Bei der Kommunikation mit dem Server trat ein unbekannter Fehler auf.';
      case 'error.subscription.no_contingent':
        return 'Das Kontingent ist aufgebraucht, so dass du dieses Workout nicht mehr buchen kannst.';
      case 'error.waiting_list.already_subscribed':
        return 'Du befindest dich bereits auf der Warteliste.';
      case 'error.waiting_list.already_participant':
        return 'Du bist bereits Teilnehmer an dem zu dem Termin.';
      case 'error.waiting_list.not_subscribed':
        return 'Du bist nicht auf der Warteliste.';

      case 'error.password_change.token.too_old':
        return 'Dieser Link zum ändern des Passwort ist leider nicht mehr gültig.';
      case 'error.password_change.token.invalid':
        return 'Dieser Link zum ändern des Passwort ist ungültig.';
      case 'error.password_change.previous_password.wrong':
        return 'Das aktuelle Passwort stimmt nicht.';
      case 'error.password_change.new_password.invalid':
        return 'Dein neues Passwort darf nicht leer sein.';

      case 'error.email_change':
        return 'Allgemeiner Fehler beim Ändern der E-Mail-Adresse';
      case 'error.email_change.email_taken':
        return 'Diese E-Mail-Adresse wird bereits von anderem Nutzer verwendet.';
      case 'error.email_change.email_invalid':
        return 'Die E-Mail-Adresse ist ungültig.';
      case 'error.email_change.token.invalid':
        return 'Das Token ist ungültig.';
      case 'error.email_change.token.too_old':
        return 'Das Token ist zu alt.';


      case 'error.delete_timeslot.participants_present':
        return 'Am Termin gibt es bereits Anmeldungen, welche vorher abgesagt werden müssen.';
      case 'error.delete_timeslot.started_in_the_past':
        return 'Der Terminbegin liegt in der Vergangenheit und kann nicht mehr gelöscht werden.';

      case 'error.serviceprovider.notfound':
        return 'Der Dienstleister wurde nicht gefunden.';

      case 'error.resource.name.missing':
        return 'Ressource hat keinen Namen.';
      case 'error.resource-type.name.missing':
        return 'Typ hat keinen Namen';
      case 'error.resource-group.name.missing':
        return 'Gruppe hat keinen Namen';
      case 'error.resource.type.missing':
        return 'Typ fehlt';
      case 'error.resource.type.invalid':
        return 'Typ ungültig';
      case 'error.resource.group.invalid':
        return 'Gruppe ungültig';
      case 'error.resource-type.delete-used':
        return 'Typ wird noch von mindestens einer Ressource verwendet.';


      case 'error.service-provider.name.invalid':
        return 'error.service-provider.name.invalid'
      case 'error.service-provider.name-long.invalid':
        return 'error.service-provider.name-long.invalid'
      case 'error.service-provider.variant.invalid':
        return 'error.service-provider.variant.invalid'
      case 'error.service-provider.subtitle.invalid':
        return 'error.service-provider.subtitle.invalid'
      case 'error.service-provider.website.invalid':
        return 'error.service-provider.website.invalid'
      case 'error.service-provider.mail.invalid':
        return 'error.service-provider.mail.invalid'
      case 'error.service-provider.address.invalid':
        return 'error.service-provider.address.invalid'
      case 'error.service-provider.phone.invalid':
        return 'error.service-provider.phone.invalid'
      case 'error.service-provider.geopos.invalid':
        return 'error.service-provider.geopos.invalid'

      case 'error.sections.invalid_name':
        return 'Der Name für die Abteilung / Klasse ist ungültig.';

      default:
        return 'Es trat ein unbekannter Fehler auf.';
    }
  }
}
