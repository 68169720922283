import { Component, OnInit } from '@angular/core';
import {Api} from "../services/api";
import {Observable} from "rxjs";

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
  public invitationLink$: Observable<string>;

  constructor(private api: Api) { }

  ngOnInit(): void {
    this.invitationLink$ = this.api.getInvitationLink();
  }
}
