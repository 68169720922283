import {Component, ViewChild} from '@angular/core';
import {Api} from "../../services/api";
import {Session} from "../../services/session";
import {Router} from "@angular/router";
import {Store} from "@ngrx/store";
import * as CustomerListActions from "../actions/customer-list-page.actions";
import {CustomersState} from "../actions/reducers";
import {allCustomers, loadingCustomers} from "../actions/selectors";
import {MatTableDataSource} from "@angular/material/table";
import {ContractType, Customer} from "../../services/customer";
import {MatSort} from "@angular/material/sort";
import {filter, map} from "rxjs/operators";
import {MatPaginator} from "@angular/material/paginator";
import {combineLatest, Observable, ReplaySubject} from "rxjs";

class ListItem {
  public id: string;
  public name: string;
  public email: string;
  public contract: string;
  public prepaidUnits: number;
  public status: string;
  public isInstructor: boolean;

  constructor(customer: Customer) {
    this.id = customer.id;
    this.name = customer.displayName;
    this.email = customer.email;
    this.contract = customer.contractDisplayName;
    this.prepaidUnits = customer.prepaidUnits;
    this.status = customer.isInstructor() ? 'Trainer' : 'Mitglied';
    this.isInstructor = customer.isInstructor();
  }
}

@Component({
  selector: 'app-customer-list',
  templateUrl: './customer-list.component.html',
  styleUrls: ['./customer-list.component.scss']
})
export class CustomerListComponent {
  displayedColumns = ['name', 'email', 'contract', 'prepaidUnits', 'status', 'operations'];
  dataSource = new MatTableDataSource<ListItem>();
  level1Filter: string;
  level1Filter$ =  new ReplaySubject<string>()
  loading$: Observable<boolean>;

  @ViewChild(MatSort) sort;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  constructor(private api: Api, private session: Session, private router: Router, private store: Store<CustomersState>) {
    this.store.dispatch(CustomerListActions.loadCustomers())
    this.loading$ = this.store.select(loadingCustomers);

    combineLatest([this.store.select(allCustomers), this.level1Filter$])
    // this.store.select(allCustomers)
      .pipe(
        filter(([customers, filter]) => customers != undefined),
        map(([customers, filter]) => customers.filter(customer => {

          if (filter == 'all') return true;
          if (filter == 'contract') return (customer.contractType != ContractType.NO_CONTRACT);
          if (filter == 'prepaid') return (customer.prepaidUnits > 0);
          if (filter == 'without_contract') return (customer.contractType == ContractType.NO_CONTRACT);
          if (filter == 'instructor') return customer.isInstructor();

          return true;
        })),
        map((customers) => customers?.map(customer => new ListItem(customer))
          .sort((a,b) => a.name.localeCompare(b.name))),
      )
      .subscribe(
        (data) => {
          this.dataSource.data = data;
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
        }
      );

    this.level1Filter = 'all';
    this.level1Filter$.next(this.level1Filter);
  }

  applyFilter(target: any) {
    this.dataSource.filter = target.value.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  showCustomer(id: string) {
    this.router.navigate(['customers/detail/', id]);
  }

  addCustomer() {
    this.router.navigate(["customers", "add"]);
  }

  level1FilterChanged() {
    this.level1Filter$.next(this.level1Filter);
  }
}
