<div class="section">
  <div class="header">Online Meeting</div>
  <div class="body">
    <form [formGroup]="formGroup" autocomplete="off" class="main">
      <mat-form-field id="title">
        <mat-label>Titel</mat-label>
        <input matInput formControlName="title" autofocus required>
      </mat-form-field>
      <mat-form-field id="url">
        <mat-label>URL</mat-label>
        <input matInput formControlName="url" required>
      </mat-form-field>
      <mat-form-field id="description">
        <mat-label>Beschreibung</mat-label>
        <textarea matInput formControlName="description" rows="6"></textarea>
      </mat-form-field>
      <div class="actions">
        <button mat-stroked-button (click)="submit()" id="submit" [disabled]="!formGroup.valid">Speichern</button>
      </div>
      <div class="description">
        Der Link, z.B. zu einer Zoom-Session, wird auch innerhalb einer Serie nur an diesem einzelnen
        Termin gespeichert. Dadurch kann man zu jedem Termin in einer Serie einen neuen Link hinterlegen.
        Der Link wird den Nutzern nur dann angezeigt, wenn diese sich für den Termin angemeldet haben und
        die Frist zum Abmelden abgelaufen ist.
      </div>
    </form>
  </div>
</div>
