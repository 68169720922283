<form [formGroup]="formGroup">
  <mat-radio-group aria-label="Select an option" formControlName="method" (change)="methodChange()">
    <ng-container *ngIf="opening">
      <mat-radio-button value="at_end">uneingeschränkt</mat-radio-button>
      <mat-radio-button value="at_start">zum Start des Termins</mat-radio-button>
    </ng-container>

    <ng-container *ngIf="!opening">
      <mat-radio-button value="at_start">zum Start des Termins</mat-radio-button>
      <mat-radio-button value="at_end">zum Ende des Termins</mat-radio-button>
    </ng-container>

    <mat-radio-button value="relative_to_appointment">relativ zum Termin</mat-radio-button>
    <app-period-rta formControlName="rta_minutes" label="" [class.inactive]="formGroup.get('method').value != 'relative_to_appointment'"></app-period-rta>

    <ng-container *ngIf="rtBomEnabled">
      <mat-radio-button value="relative_to_bom">relativ zum Monatsanfang</mat-radio-button>
      <app-period-bom formControlName="bom" [class.inactive]="formGroup.get('method').value != 'relative_to_bom'"></app-period-bom>
    </ng-container>
  </mat-radio-group>
  <div mat-dialog-actions>
    <button mat-stroked-button (click)="onNoClick()">Abbrechen</button>
    <button mat-stroked-button (click)="onSubmitClick()" cdkFocusInitial>Übernehmen</button>
  </div>
</form>

