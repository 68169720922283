import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {UntypedFormControl} from "@angular/forms";

@Component({
  selector: 'app-text-input-dialog',
  templateUrl: './text-input-dialog.component.html',
  styleUrls: ['./text-input-dialog.component.scss']
})
export class TextInputDialogComponent {
  title: string;
  placeholder: string;
  label: string;
  value: string;
  control = new UntypedFormControl();

  constructor(public dialogRef: MatDialogRef<TextInputDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: TextInputDialogModel) {
    this.title = data.title;
    this.placeholder = data.placeholder;
    this.label = data.label;
    this.control.setValue(data.value);
    // this.value = data.value;
  }

  onConfirm(): void {
    this.dialogRef.close(this.control.value);
  }

  onDismiss(): void {
    this.dialogRef.close(false);
  }
}

export class TextInputDialogModel {
  constructor(public title: string, public label: string, public placeholder: string, public value?: string) {
  }
}
