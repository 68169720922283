import {Component, Input, OnInit} from '@angular/core';
import {Wod} from "../../../services/wod";

@Component({
  selector: 'app-timeslot-detail-attachments',
  templateUrl: './timeslot-detail-attachments.component.html',
  styleUrls: ['./timeslot-detail-attachments.component.scss']
})
export class TimeslotDetailAttachmentsComponent implements OnInit {
  @Input() timeslot: Wod;
  constructor() { }

  ngOnInit(): void {
  }

}
