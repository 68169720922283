export namespace ResourcesActions {
  export class LoadAll {
    static readonly type = '[Resources] load all Resource for a serviceProvider';
    constructor() {
    }
  }

  export class Create {
    static readonly type = '[Resource-List] create new resource';

    constructor() {
    }
  }
  export class CreateDirect {
    static readonly type = '[Resource-Module-Api] create new resource';

    constructor(public name: string) {
    }
  }

  export class Edit {
    static readonly type = '[Resource-List] edit Resource'
    constructor(public id: string) {}
  }

  export class Save {
    static readonly type = '[Resources-Edit] save Resource';
  }

  export class Delete {
    static readonly type = '[Resource-List] delete Resource'
    constructor(public id: string) {}
  }

  export class LoadResourceTypes {
    static readonly type = '[Resource-Edit] load all ResourceTypes for a serviceProvider';
    constructor() {
    }
  }

  export class LoadResourceGroups {
    static readonly type = '[Resource-Edit] load all ResourceGroups for a serviceProvider';
    constructor() {
    }
  }
}
