import {Component, OnDestroy} from '@angular/core';
import {AppointmentsState} from "../../actions/appointments";
import {Store} from "@ngrx/store";
import {currentAppointment, currentAttributes, dirty} from "../../actions/selectors";
import {Observable, Subscription} from "rxjs";
import {Wod} from "../../../services/wod";
import {MatDialog} from "@angular/material/dialog";
import {AttributeAddComponent} from "./attribute-add/attribute-add.component";
import {AppointmentActions} from "../../actions/action-types";
import {AppointmentAttribute} from "../../../services/appointmentAttribute";
import {map} from "rxjs/operators";

@Component({
  selector: 'app-appointment-detail-attributes',
  templateUrl: './appointment-detail-attributes.component.html',
  styleUrls: ['./appointment-detail-attributes.component.scss']
})
export class AppointmentDetailAttributesComponent implements OnDestroy {
  public appointment$: Observable<Wod>;
  private attributes: AppointmentAttribute[];
  private sub1: Subscription;
  dirty$: Observable<boolean>;

  constructor(private store: Store<AppointmentsState>, private dialog: MatDialog) {
    this.appointment$ = this.store.select(currentAppointment);
    this.dirty$ = this.store.select(dirty).pipe(map(it => it.attributeSectionIsDirty));

    this.sub1 = this.store.select(currentAttributes).subscribe(it => this.attributes = it);
  }

  ngOnDestroy(): void {
    this.sub1?.unsubscribe();
  }

  addAttribute() {
    const dialogRef = this.dialog.open(AttributeAddComponent);
    dialogRef.afterClosed().subscribe(result => {
      if (result !== undefined)
        this.store.dispatch(AppointmentActions.addAppointmentAttribute({attribute: result}))
    });
  }

  save(appointmentId: string) {
    this.store.dispatch(AppointmentActions.updateAppointmentAttributes({appointmentId: appointmentId, attributes: this.attributes}))
  }
}
