import {Action, createReducer, MetaReducer, on} from '@ngrx/store';
import {environment} from '../../../environments/environment';
import {Customer} from "../../services/customer";
import * as CustomerListPageActions from './customer-list-page.actions';
import * as CustomerApiActions from './customer-api.actions';
import {CustomerSubscription} from "../../services/customerSubscription";

export interface CustomersState {
  selectedCustomerId: string
  selectedCustomer: Customer
  availableCustomers: Customer[]
  customerFilter: string,
  currentBookings: CustomerSubscription[],
  message: string,
  loadingCustomers: boolean,
}

export const initialState: CustomersState = {
  selectedCustomerId: null,
  selectedCustomer: null,
  availableCustomers: null,
  customerFilter: null,
  currentBookings: [],
  message: '',
  loadingCustomers: false,
};

export const customersFeatureKey = 'customers';
export const _customerListReducer = createReducer(
  initialState,
  on(CustomerListPageActions.selectCustomer, (state, action) => ({...state, selectedCustomerId: action.customerId})),
  on(CustomerListPageActions.applyFilter, (state, action) => ({...state, customerFilter: action.search})),

  on(CustomerApiActions.customerLoaded, (state, action) => {
    return {...state, selectedCustomer: action.customer}
  }),

  on(CustomerApiActions.bookingsLoaded, (state, action) => {
    return {...state, currentBookings: action.bookings}
  }),
  on(CustomerListPageActions.loadCustomers, (state) => {
    return {...state, loadingCustomers: true}
  }),
  on(CustomerApiActions.customersLoaded, (state, action) => {
    return {...state, availableCustomers: action.customers, loadingCustomers: false}
  }),
  on(CustomerApiActions.deleteCustomerSuccess, (state, action) => {
    let availableCustomers : Customer[] = [];

    state.availableCustomers
      .filter(it => it.id != action.customerId)
      .forEach(it => availableCustomers.push(it));

    return {...state, availableCustomers: availableCustomers}
  }),
);

export function reducers(state: CustomersState | undefined, action: Action) {
  return _customerListReducer(state, action)
}

export const metaReducers: MetaReducer<CustomersState>[] = !environment.production ? [] : [];
