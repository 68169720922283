import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AppointmentsRoutingModule} from './appointments-routing.module';
import {PeriodDialogComponent} from './component/period/period-dialog/period-dialog.component';
import {AppMaterialModule} from "../material.module";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {PeriodBomComponent} from './component/period/period-bom/period-bom.component';
import {PeriodEditorComponent} from './component/period/period-editor/period-editor.component';
import {PeriodRtaComponent} from "./component/period/period-rta/period-rta.component";
import {PeriodDisplayComponent} from './component/period/period-display/period-display.component';
import {TextMaskModule} from "angular2-text-mask";
import {TranslateModule} from "@ngx-translate/core";
import {AppointmentDetailComponent} from "./appointment-detail/appointment-detail.component";
import {AppointmentSeriesDeleteComponent} from "./appointment-delete/appointment-series-delete/appointment-series-delete.component";
import {AppointmentUpdateFixedComponent} from "./appointment-update-fixed/appointment-update-fixed.component";
import {AppointmentDetailEnquirersComponent} from "./appointment-detail/appointment-detail-enquirers/appointment-detail-enquirers.component";
import {AppointmentDetailParticipantsComponent} from "./appointment-detail/appointment-detail-participants/appointment-detail-participants.component";
import {AppointmentDetailWaitinglistComponent} from "./appointment-detail/appointment-detail-waitinglist/appointment-detail-waitinglist.component";
import {AppointmentDetailDataComponent} from "./appointment-detail/appointment-detail-data/appointment-detail-data.component";
import {AppointmentDeleteComponent} from "./appointment-delete/appointment-delete.component";
import {AppointmentUpdateSerieComponent} from "./appointment-update-serie/appointment-update-serie.component";
import {MomentModule} from "ngx-moment";
import * as fromAppointments from './actions/appointments';
import {StoreModule} from "@ngrx/store";
import {EffectsModule} from "@ngrx/effects";
import {AppointmentEffects} from "./actions/appointment-effects";
import {AppointmentCancelComponent} from './appointment-cancel/appointment-cancel.component';
import {AppointmentParticipantAddComponent} from './appointment-detail/appointment-participant-add/appointment-participant-add.component';
import {MAT_SNACK_BAR_DATA, MatSnackBarRef} from "@angular/material/snack-bar";
import {AppointmentDetailAttributesComponent} from './appointment-detail/appointment-detail-attributes/appointment-detail-attributes.component';
import {AttributeAddComponent} from './appointment-detail/appointment-detail-attributes/attribute-add/attribute-add.component';
import {AttributeListComponent} from './appointment-detail/appointment-detail-attributes/attribute-list/attribute-list.component';
import {AppointmentModuleApi} from "./appointment-module-api.service";
import {TemplateSearchComponent} from './appointment-detail/appointment-detail-attributes/template-search/template-search.component';
import {PublicSubscribeConditionComponent} from "./public-subscribe-condition/public-subscribe-condition.component";
import { AppointmentDetailLinksComponent } from './appointment-detail/appointment-detail-links/appointment-detail-links.component';
import {AddAnonymDialogComponent} from "./add-anonym/add-anonym-dialog.component";
import {AddAnonymDialogButton} from "./add-anonym/add-anonym-button.component";
import { TimeslotDetailAttachmentsComponent } from './appointment-detail/timeslot-detail-attachments/timeslot-detail-attachments.component';
import { TimeslotDetailAttachmentUploadComponent } from './appointment-detail/timeslot-detail-attachments/timeslot-detail-attachment-upload/timeslot-detail-attachment-upload.component';
import { TimeslotDetailAttachmentListComponent } from './appointment-detail/timeslot-detail-attachments/timeslot-detail-attachment-list/timeslot-detail-attachment-list.component';
import { EditParticipantComponent } from './edit-participant/edit-participant.component';
import { TsDetailResourcesComponent } from './appointment-detail/appointment-detail-data/ts-detail-resources/ts-detail-resources.component';
import {SharedModule} from "../shared/shared.module";

@NgModule({
  declarations: [
    PeriodDialogComponent, PeriodBomComponent,
    PeriodEditorComponent, PeriodRtaComponent,
    PeriodDisplayComponent,
    AppointmentDetailComponent,
    AppointmentDeleteComponent,
    AppointmentSeriesDeleteComponent,
    AppointmentUpdateFixedComponent,
    AppointmentUpdateSerieComponent,
    AppointmentDetailParticipantsComponent,
    AppointmentDetailWaitinglistComponent,
    AppointmentDetailDataComponent,
    AppointmentDetailEnquirersComponent,
    AppointmentCancelComponent,
    AppointmentParticipantAddComponent,
    AppointmentDetailAttributesComponent,
    AttributeAddComponent,
    AttributeListComponent,
    PublicSubscribeConditionComponent,
    TemplateSearchComponent,
    AppointmentDetailLinksComponent,
    AddAnonymDialogComponent,
    AddAnonymDialogButton,
    TimeslotDetailAttachmentsComponent,
    TimeslotDetailAttachmentUploadComponent,
    TimeslotDetailAttachmentListComponent,
    EditParticipantComponent,
    TsDetailResourcesComponent,
  ],
  exports: [
    PeriodEditorComponent,
    PeriodRtaComponent,
    AddAnonymDialogButton,
    AppointmentUpdateSerieComponent
  ],
  imports: [
    CommonModule,
    StoreModule.forFeature(fromAppointments.appointmentsFeatureKey, fromAppointments.reducer),
    EffectsModule.forFeature([AppointmentEffects]),
    AppointmentsRoutingModule,
    AppMaterialModule,
    FormsModule,
    ReactiveFormsModule,
    TextMaskModule,
    TranslateModule,
    MomentModule,
    SharedModule,
  ],
  providers: [
    AppointmentModuleApi,
    {
      provide: MatSnackBarRef,
      useValue: {}
    }, {
      provide: MAT_SNACK_BAR_DATA,
      useValue: {} // Add any data you wish to test if it is passed/used correctly
    }]
})
export class AppointmentsModule {
}
