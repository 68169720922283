import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {LoginComponent} from './login/login.component';
import {AuthGuard} from './guard/auth.guard';
import {ServiceProviderGuard} from './guard/service-provider-guard.service';
import {ProfileComponent} from './profile/profile.component';
import {ServiceProviderSelectComponent} from './service-provider-select/service-provider-select.component';
import {PasswordResetComponent} from './password-reset/password-reset.component';
import {PasswordChangeComponent} from './password-change/password-change.component';
import {ImpressumComponent} from './impressum/impressum.component';
import {ConfirmEmailChangeWithTokenComponent} from './self-service-user/confirm-email-change-with-token/confirm-email-change-with-token.component';
import {LoginByLinkComponent} from "./login/login-by-link/login-by-link.component";
import {TokenLoginComponent} from "./login/token-login/token-login.component";

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./time-scheduling/time-scheduling.module').then(m => m.TimeSchedulingModule),
    canActivate: [AuthGuard, ServiceProviderGuard]
  },  {
    path: 'appointments',
    loadChildren: () => import('./time-scheduling/time-scheduling.module').then(m => m.TimeSchedulingModule),
    canActivate: [AuthGuard, ServiceProviderGuard]
  },
  {
    path: 'schedule',
    loadChildren: () => import('./time-scheduling/time-scheduling.module').then(m => m.TimeSchedulingModule),
    canActivate: [AuthGuard, ServiceProviderGuard]
  },
  {
    path: 'school-parent-talk',
    loadChildren: () => import('./school-parent-talk/school-parent-talk.module').then(m => m.SchoolParentTalkModule),
    canActivate: [AuthGuard, ServiceProviderGuard]
  },
  {
    path: 'section',
    loadChildren: () => import('./section/section.module').then(m => m.SectionModule),
    canActivate: [AuthGuard, ServiceProviderGuard]
  },

  {path: 'impressum', component: ImpressumComponent},
  {path: 'login', component: LoginComponent},
  {path: 'login-by-link', component: LoginByLinkComponent},
  {path: "signin/:token", component: TokenLoginComponent},
  {path: 'service-provider-select', component: ServiceProviderSelectComponent, canActivate: [AuthGuard]},
  {path: 'confirmEmailChange/:token', component: ConfirmEmailChangeWithTokenComponent},

  {path: 'reset-password', component: PasswordResetComponent},
  {path: 'passwordChange/:token', component: PasswordChangeComponent},

  {path: 'profile', component: ProfileComponent, canActivate: [AuthGuard, ServiceProviderGuard]},

  {
    path: 'customers',
    loadChildren: () => import('./customers/customers.module').then(m => m.CustomersModule),
    canActivate: [AuthGuard, ServiceProviderGuard]
  },
  {
    path: 'self-service/service-provider',
    loadChildren: () => import('./self-service-service-provider/self-service-provider.module').then(m => m.SelfServiceProviderModule),
    canActivate: [AuthGuard, ServiceProviderGuard]
  },  {
    path: 'admins',
    loadChildren: () => import('./admin-managment/admin-managment.module').then(m => m.AdminManagmentModule),
    canActivate: [AuthGuard, ServiceProviderGuard]
  },
  {
    path: 'self-service/user',
    loadChildren: () => import('./self-service-user/self-service-user.module').then(m => m.SelfServiceUserModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'resources',
    loadChildren: () => import('./resource-managment/resource-managment.module').then(m => m.ResourceManagmentModule),
  },
  {
    path: 'demo',
    loadChildren: () => import('./demo/demo.module').then(m => m.DemoModule),
  },
  {
    path: 'appointment',
    loadChildren: () => import('./appointments/appointments.module').then(m => m.AppointmentsModule),
  },

  // { path: '', component: AppointmentListComponent, canActivate: [AuthGuard, ServiceProviderGuard] },
  // {
  //   path: 'appointments',
  //   loadChildren: () => import('./appointment-list/appointment-list.module').then(m => m.AppointmentListModule),
  // },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
