<mat-toolbar color="primary" id="toolbar">
  <img id="logo" src="../../assets/icons/icon-100x100.png" alt="logo"/>
  <span id="title">Zeitfrei Admin
        <small *ngIf="(serviceProviderName$ | async) != ''">({{serviceProviderName$ | async}})</small>
  </span>

  <button mat-icon-button [matMenuTriggerFor]="menu" id="menu">
    <mat-icon>menu</mat-icon>
  </button>

  <mat-menu #menu>
    <ng-container *ngIf="!(isLoggedIn$ | async)">
      <button mat-menu-item routerLink="/login" id="tm-login">
        <span>Login</span>
      </button>
    </ng-container>

    <ng-container *ngIf="(isLoggedIn$ | async)">
      <button [disabled]="!hasServiceProvider" mat-menu-item routerLink="/" id="tm-wods">
        <mat-icon>calendar_today</mat-icon>
        <span>{{'TOP_MENU.TERMINE' | translate}}</span>
      </button>

      <button mat-menu-item [disabled]="!hasServiceProvider" *ngIf="hasSchoolParentTalks" routerLink="/school-parent-talk" id="tm-school-parent-talk">
        <mat-icon>escalator_warning</mat-icon>
        <span>{{'TOP_MENU.SCHOOL_PARENT_TALK' | translate}}</span>
      </button>

      <button mat-menu-item [disabled]="!hasServiceProvider" *ngIf="hasSections" routerLink="/section" id="tm-section">
        <mat-icon>diversity_3</mat-icon>
        <span>{{'TOP_MENU.SECTION' | translate}}</span>
      </button>

      <button [disabled]="!hasServiceProvider" mat-menu-item routerLink="/customers" id="tm-customers">
        <mat-icon>people</mat-icon>
        <span>{{'TOP_MENU.MEMBER' | translate}}</span>
      </button>

      <!-- wenn ich admins löschen kann, dann kann der Punkt frei gegeben werden -->
<!--      <button [disabled]="!hasServiceProvider" mat-menu-item routerLink="/admins" id="tm-admins">-->
<!--        <mat-icon>people</mat-icon>-->
<!--        <span>{{'TOP_MENU.ADMINS' | translate}}</span>-->
<!--      </button>-->

      <!-- ZF-998 -->
      <button [disabled]="!hasServiceProvider" mat-menu-item routerLink="/resources" *ngIf="isResourceManagmentOn">
        <mat-icon>meeting_room</mat-icon>
        <span>{{'TOP_MENU.RESOURCES' | translate}}</span>
      </button>

      <button [disabled]="!hasServiceProvider" mat-menu-item routerLink="/profile" id="tm-profile">
        <mat-icon>account_box</mat-icon>
        <span>{{'TOP_MENU.INVITE_USERS' | translate}}</span>
      </button>

      <button mat-menu-item (click)="serviceProviderSelect()" id="tm-provider-selection">
        <mat-icon>storage</mat-icon>
        <span>Anbieter wechseln</span>
      </button>

      <button mat-menu-item [matMenuTriggerFor]="selfservice" id="tm-self-service">
        <mat-icon>settings</mat-icon>
        <span>{{'TOP_MENU.SELF_SERVICE' | translate}}</span>
      </button>

      <button mat-menu-item (click)="logout()" id="tm-logout">
        <mat-icon>exit_to_app</mat-icon>
        <span>Logout</span>
      </button>

    </ng-container>

  </mat-menu>

  <mat-menu #selfservice="matMenu">
    <button mat-menu-item routerLink="/self-service/user"  id="tm-self-service-user">meine Daten</button>
    <button mat-menu-item [disabled]="!hasServiceProvider" routerLink="/self-service/service-provider" id="tm-self-service-service-provider" >Dienstleister</button>
  </mat-menu>
</mat-toolbar>
