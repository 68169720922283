import {Component, OnInit} from '@angular/core';
import {BehaviorSubject} from "rxjs";
import {Api} from "../../services/api";
import {ActivatedRoute} from "@angular/router";
import {Location} from "@angular/common";
import {Store} from "@ngrx/store";
import {CustomerActions} from "../actions/action-types";
import {CustomersState} from "../actions/reducers";
import {currentCustomer} from "../actions/selectors";
import {Customer} from "../../services/customer";

@Component({
  selector: 'app-customer-detail',
  templateUrl: './customer-detail.component.html',
  styleUrls: ['./customer-detail.component.scss']
})
export class CustomerDetailComponent implements OnInit {
  public customer: Customer;
  public customer$: BehaviorSubject<Customer> = new BehaviorSubject<Customer>(undefined);
  public section: string = "data";
  private id: string;

  constructor(
    private api: Api,
    private route: ActivatedRoute,
    private location: Location,
    private store: Store<CustomersState>
  ) {
    this.id = this.route.snapshot.paramMap.get('id');
    this.store.select(currentCustomer).subscribe((customer) => {
      this.customer$.next(customer);
      this.customer = customer;
    })
  }

  ngOnInit(): void {
    this.store.dispatch(CustomerActions.loadCustomer({customerId: this.id}));
  }

  abort() {
    this.location.back();
  }

  activateSection(name: string) {
    this.section = name;
  }

  dataChanged() {
    this.store.dispatch(CustomerActions.loadCustomer({customerId: this.id}));
  }
}
