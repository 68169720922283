<h1 class="header mat-typography" *ngIf="viewMode == 'default'">Teilnehmer</h1>
<h1 class="header mat-typography" *ngIf="viewMode == 'memberSelection'">Teilnehmer hinzufügen</h1>

<div class="actions">
  <ng-container *ngIf="viewMode == 'default'">
    <app-add-anonym-button [timeslot]="appointment"></app-add-anonym-button>
    <button *ngIf="canShowMemberSubscription()" (click)="showCustomers()"
            mat-stroked-button color="primary" >+ Mitgliedsbuchung
    </button>
  </ng-container>
  <button *ngIf="viewMode == 'memberSelection'"
          mat-stroked-button color="primary" (click)="showParticipants()">Fertig
  </button>
</div>


<div class="main" *ngIf="viewMode == 'default' && dataSource.data.length > 0" >
  <mat-form-field class="filter">
    <mat-label>Filter</mat-label>
    <input matInput (keyup)="applyFilter($event.target.value)" placeholder="z.B. Name oder E-Mail">
  </mat-form-field>

  <mat-table [dataSource]="dataSource" matSort matSortActive="subscribedOn" matSortDirection="desc" class="main mat-elevation-z8">
    <ng-container *ngFor="let column of columns" [matColumnDef]="column.columnDef">
      <mat-header-cell *matHeaderCellDef mat-sort-header>{{column.header}}</mat-header-cell>
      <mat-cell *matCellDef="let row"> {{column.cell(row)}}  </mat-cell>
    </ng-container>

    <ng-container matColumnDef="operations">
      <mat-header-cell *matHeaderCellDef></mat-header-cell>
      <mat-cell *matCellDef="let element">
        <button mat-icon-button [matMenuTriggerFor]="itemMenu" [matMenuTriggerData]="{item: element}">
          <mat-icon>more_horiz</mat-icon>
        </button>
      </mat-cell>
    </ng-container>


    <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
  </mat-table>
</div>

<div class="main" *ngIf="viewMode == 'default' && dataSource.data.length == 0">
  <h3>Keine Teilnehmer vorhanden.</h3>
</div>


<mat-menu #itemMenu="matMenu">
  <ng-template matMenuContent let-item="item">
    <button mat-menu-item (click)="edit(item)"><mat-icon>edit</mat-icon><span>Bearbeiten</span></button>
    <button mat-menu-item (click)="unsubscribe(item)"><mat-icon>delete</mat-icon><span>Abmelden</span></button>
  </ng-template>
</mat-menu>

<app-appointment-participant-add *ngIf="viewMode == 'memberSelection'" id="memberSelection" class="main"></app-appointment-participant-add>
