<div class="resources part" [formGroup]="parent">
  <mat-form-field>
    <mat-label>Ressourcen</mat-label>
    <mat-select formControlName="resources" multiple>
      <mat-optgroup [label]="resourceGroupName" *ngFor="let resourceGroupName of getGroupNames()">
        <mat-option *ngFor="let resource of resourceGroups.get(resourceGroupName)"
                    [value]="resource.id">{{resource.name}}</mat-option>
      </mat-optgroup>
    </mat-select>
  </mat-form-field>
  <button mat-stroked-button (click)="newResource()">Resource erstellen</button>
</div>
