import {Injectable} from '@angular/core';
import {MessageLayoutComponent} from "../message-display/message-layout/message-layout.component";
import {MatSnackBar} from "@angular/material/snack-bar";
import {ApiError} from "../services/error";

@Injectable({providedIn: 'root'})
export class MessageService {

  constructor(private snackBar: MatSnackBar) { }

  public  showMessage(message: string) {
    if (message == null || message == "")
      return;
    this.snackBar.openFromComponent(MessageLayoutComponent, {
      duration: 8000, data: {message: message, action: "OK"}
    });
  }

  public showError(error: string | ApiError) {
    if (error instanceof ApiError) {
      this.showMessage(error.getDisplayMessage());
    } else {
      this.showMessage(error)
    }
  }

}
