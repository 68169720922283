export namespace ResourceGroupsActions {
  export class LoadAll {
    static readonly type = '[ResourceGroups] load all ResourceGroups for a serviceProvider';
    constructor() {
    }
  }

  export class Create {
    static readonly type = '[ResourceGroups-List] create new ResourceGroup';

    constructor() {
    }
  }

  export class Edit {
    static readonly type = '[ResourceGroups-List] edit ResourceGroup'
    constructor(public id: string) {}
  }

  export class Save {
    static readonly type = '[ResourceGroups-Edit] save ResourceGroup';
  }

  export class Delete {
    static readonly type = '[ResourceGroup-List] delete ResourceGroup'
    constructor(public id: string) {}
  }

}
