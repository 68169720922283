import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {BaseApi} from "../../services/BaseApi";
import {Resource} from "../model/resource";
import {HttpClient} from "@angular/common/http";
import {Session} from "../../services/session";
import {Store} from "@ngrx/store";
import {AppState} from "../../actions/reducers";
import {catchError, map} from "rxjs/operators";

export interface ResourceUpate {
  customerLabel?: string,
  resourceTypeId?: number
  customerVisible?: boolean,
  name?: string,
  resourceGroupId?: number,
}

@Injectable()
export class ResourcesApi extends BaseApi {
  constructor(protected http: HttpClient, protected session: Session, protected store: Store<AppState>) {
    super(http, session, store);
  }

  public getResources(): Observable<Resource[]> {
    let serviceProvider = this.getServiceProviderIdentifier();
    let url = `/api/v2/tyrael/sp/${serviceProvider}/resource`;
    return this.http
      .get(url, {headers: this.getHeaders()})
      .pipe(
        catchError(err => this.handleNetworkError(err)),
        map(response => {
          return (<any>response).map(Resource.fromJson);
        }),
      )
  }

  getResourceById(id: string): Observable<Resource> {
    let serviceProvider = this.getServiceProviderIdentifier();
    let url = `/api/v2/tyrael/sp/${serviceProvider}/resource/${id}`;
    return this.http
      .get(url, {headers: this.getHeaders()})
      .pipe(
        catchError(err => this.handleNetworkError(err)),
        map(response => Resource.fromJson(response)),
      )
  }

  update(id: string, resource: ResourceUpate ): Observable<any> {
    let serviceProvider = this.getServiceProviderIdentifier();
    let url = `/api/v2/tyrael/sp/${serviceProvider}/resource/${id}`;
    return this.http
      .put(url, resource, {headers: this.getHeaders()})
      .pipe(
        catchError(err => this.handleNetworkError(err)),
        map(response => Resource.fromJson(response))
      )
  }

  create(resource: ResourceUpate): Observable<Resource> {
    let serviceProvider = this.getServiceProviderIdentifier();
    let url = `/api/v2/tyrael/sp/${serviceProvider}/resource`;
    return this.http
      .post(url, resource, {headers: this.getHeaders()})
      .pipe(
        catchError(err => this.handleNetworkError(err)),
        map(response => Resource.fromJson(response))
      )
  }

  delete(id: string) {
    let serviceProvider = this.getServiceProviderIdentifier();
    let url = `/api/v2/tyrael/sp/${serviceProvider}/resource/${id}`;
    return this.http
      .delete(url, {headers: this.getHeaders()})
      .pipe(
        catchError(err => this.handleNetworkError(err)),
      )
  }
}
