import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {AppActions} from "../../actions/action-types";
import {Session} from "../../services/session";
import {MessageService} from "../../shared/message.service";
import {Store} from "@ngrx/store";
import {AppState} from "../../actions/reducers";
import {Api} from "../../services/api";
import {LoginService} from "../login-service";

@Component({
  selector: 'app-token-login',
  templateUrl: './token-login.component.html',
  styleUrls: ['./token-login.component.scss']
})
export class TokenLoginComponent implements OnInit {
  private readonly token: string;
  private readonly serviceProviderId: string;

  constructor(route: ActivatedRoute,
              private router: Router,
              private api: Api,
              private session: Session,
              private messageService: MessageService,
              private store: Store<AppState>,
              private loginService: LoginService) {
    this.token = route.snapshot.paramMap.get("token");
    this.serviceProviderId = route.snapshot.queryParamMap.get("sp")
  }

  ngOnInit(): void {
    this.api.verifyEmailLogin(this.token).subscribe(
      (session_token) => this.loginService.handleLoginWithToken(session_token),
      () => this.onFailure()
    );
  }

  onFailure() {
    this.store.dispatch(AppActions.logout());
    this.messageService.showError("Der Link ist ungültig.");
  }
}
