<h1 class="header mat-typography">{{customer.displayName}}</h1>

<div class="actions"></div>

<div class="main">
  <mat-form-field class="filter">
    <mat-label>Filter</mat-label>
    <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Name" #filter>
  </mat-form-field>

  <mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8">
    <ng-container matColumnDef="name">
      <mat-header-cell *matHeaderCellDef mat-sort-header> Name</mat-header-cell>
      <mat-cell *matCellDef="let element"> {{element.name}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="start">
      <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header> Start</mat-header-cell>
      <mat-cell *matCellDef="let element"> {{element.start | amDateFormat: 'dd, DD.MM.YYYY HH:mm'}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="end">
      <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header> Ende</mat-header-cell>
      <mat-cell *matCellDef="let element"> {{element.end | amDateFormat: 'dd, DD.MM.YYYY HH:mm'}} </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>

    <!-- Row shown when there is no matching data. -->
    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell" colspan="4">keine Buchungen gefunden für {{customer?.displayName}}</td>
    </tr>
  </mat-table>

  <mat-paginator [pageSizeOptions]="[10, 25, 100]"></mat-paginator>
</div>
