import {Component, Inject, OnInit} from '@angular/core';
import {UntypedFormControl} from "@angular/forms";
import {Store} from "@ngrx/store";
import {AppointmentsState} from "../actions/appointments";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {Api} from "../../services/api";
import {MessageService} from "../../shared/message.service";
import {ApiActions} from "../actions/action-types";
import {Wod} from "../../services/wod";
import {Participant} from "../../services/participant";
import {Observable} from "rxjs";

export interface EditAnonymDialogData {
  participant: Participant,
  timeslot: Wod;
}

@Component({
  selector: 'app-edit-participant',
  templateUrl: './edit-participant.component.html',
  styleUrls: ['./edit-participant.component.scss']
})
export class EditParticipantComponent implements OnInit {
  firstname: UntypedFormControl;
  lastname: UntypedFormControl;
  note: UntypedFormControl;
  numberOfPersons: UntypedFormControl;
  cancelButtonText: string

  timeslotId: string;
  freeSeats: number;

  isCancelDisabled: boolean;
  isBookingDisabled: boolean;
  participant: Participant;

  constructor(private store: Store<AppointmentsState>,
              public dialogRef: MatDialogRef<EditAnonymDialogData>,
              @Inject(MAT_DIALOG_DATA) public data: EditAnonymDialogData,
              private api: Api, private messageService: MessageService) {
    this.participant = data.participant;
    this.firstname = new UntypedFormControl(this.participant.firstname);
    this.lastname = new UntypedFormControl(this.participant.lastname);
    this.note = new UntypedFormControl(this.participant.note);
    this.numberOfPersons = new UntypedFormControl(this.participant.numberOfPersons);
    this.timeslotId = data.timeslot.id;

    this.freeSeats = data.timeslot.maxMembers - data.timeslot.numberOfParticipants;
  }

  ngOnInit(): void {
    this.cancelButtonText = 'Abbrechen';
    this.isCancelDisabled = false;
    this.isBookingDisabled = false;
  }

  update() {
    this.booking();

    let update$: Observable<any>;
    if (this.participant.userState == 'NON_USER_GUEST') {
      update$ = this.api.updateAnonymSubscription(this.timeslotId, this.participant.userId, this.firstname.value, this.lastname.value, this.note.value, this.numberOfPersons.value);
    } else {
      update$ = this.api.updateMemberSubscription(this.timeslotId, this.participant.userId, this.note.value, this.numberOfPersons.value)
    }

    update$.toPromise().then(
      () => {
        this.booked();
        this.messageService.showMessage("Die Buchung wurde erfolgreich geändert.");
        this.store.dispatch(ApiActions.subscribeAnonymSuccess({appointmentId: this.timeslotId}));
        this.dialogRef.close();
      },
      () => {
        this.bookingError();
        this.messageService.showError("Beim ändern der Buchung trat ein Fehler auf: ");
      }
    )
  }

  private booking() {
    this.cancelButtonText = 'Abbrechen';
    this.isCancelDisabled = true;
    this.isBookingDisabled = true;
  }

  private booked() {
    this.cancelButtonText = 'Schliessen';
    this.isCancelDisabled = false;
    this.isBookingDisabled = true;
  }

  private bookingError() {
    this.cancelButtonText = 'Abbrechen';
    this.isCancelDisabled = false;
    this.isBookingDisabled = false;
  }
}
