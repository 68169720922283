import {Component, ViewChild} from '@angular/core';
import {AppointmentsState} from "../../actions/appointments";
import {MatTableDataSource} from "@angular/material/table";
import {allCustomers, currentAppointment, currentSubscriptionRequests} from "../../actions/selectors";
import {combineLatest} from "rxjs";
import {map} from "rxjs/operators";
import {Store} from "@ngrx/store";
import {Customer} from "../../../services/customer";
import {AppointmentActions} from "../../actions/action-types";
import {Wod} from "../../../services/wod";
import {MatSort} from "@angular/material/sort";

@Component({
  selector: 'app-appointment-participant-add',
  templateUrl: './appointment-participant-add.component.html',
  styleUrls: ['./appointment-participant-add.component.scss']
})
export class AppointmentParticipantAddComponent {
  displayedColumns: string[] = ['name', 'email', 'prepaidUnits', 'operations'];
  dataSource = new MatTableDataSource<Customer>();
  appointment: Wod;
  @ViewChild(MatSort) set sort (sort: MatSort) {
    this.dataSource.sort = sort;
  };

  constructor(private store: Store<AppointmentsState>) {
    this.store.select(currentAppointment).subscribe(appointment => this.appointment = appointment);

    combineLatest(this.store.select(allCustomers), this.store.select(currentAppointment), this.store.select(currentSubscriptionRequests))
      .pipe(
        map(([customers, appointment, requests]) => {
          return customers.filter(customer => {
            if (appointment.isParticipant(customer))
              return false;

            if (requests.find(r => r.userId == customer.id))
              return false;

            return true
          });
        })
      ).subscribe(customers => {this.dataSource.data = customers})
  }

  selectCustomer(customer: Customer) {
    this.store.dispatch(AppointmentActions.subscribeUser(
      {appointmentId: this.appointment.id, customer: customer}
    ));
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
}
