import {Component, forwardRef} from '@angular/core';
import {ControlValueAccessor, UntypedFormBuilder, UntypedFormGroup, NG_VALUE_ACCESSOR, Validators} from "@angular/forms";

@Component({
  selector: 'app-public-subscribe-condition',
  templateUrl: './public-subscribe-condition.component.html',
  styleUrls: ['./public-subscribe-condition.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PublicSubscribeConditionComponent),
      multi: true
    }
  ]
})
export class PublicSubscribeConditionComponent implements ControlValueAccessor {
  propagateChange;
  formGroup: UntypedFormGroup;
  subscribeCondition: string;

  constructor(private _fb: UntypedFormBuilder) {
    this.formGroup = this._fb.group({
      subscribeCondition: ['', [Validators.required]],
      subscribeConfirmationDays: ['', []],
    })

    this.formGroup.valueChanges
      .subscribe(
        data => {
          this.subscribeCondition = data.subscribeCondition;
          if (this.subscribeCondition == 'CONFIRMATION')
            this.formGroup.get('subscribeConfirmationDays').enable({emitEvent: false});
          else
            this.formGroup.get('subscribeConfirmationDays').disable({emitEvent: false});

          if (!this.propagateChange)
            return;

          this.propagateChange({
            subscribeCondition: this.formGroup.get('subscribeCondition').value,
            subscribeConfirmationDays: this.formGroup.get('subscribeConfirmationDays').value
          })
        }
      );
  }

  writeValue(obj: {subscribeCondition: string, subscribeConfirmationDays: number}): void {
    this.formGroup.get('subscribeCondition').setValue(obj.subscribeCondition);
    this.formGroup.get('subscribeConfirmationDays').setValue(obj.subscribeConfirmationDays);
  }

  /**
   * informiert das äußere Form über Änderungen
   * @param fn
   */
  registerOnChange(fn) {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
  }
}
