<h1 class="header mat-typography">Mitglieder</h1>

<div *ngIf="loading$ | async" class="loading pulsate">Mitglieder werden geladen ...</div>

<div class="actions"  *ngIf="!(loading$ | async)">
  <button (click)="addCustomer()" color="primary" mat-stroked-button>Mitglied anlegen</button>
</div>

<div class="main">
<!--  <ng-container  *ngIf="!(loading$ | async)">-->
    <mat-form-field class="filter">
      <mat-label>Filter</mat-label>
      <input matInput (keyup)="applyFilter($event.target)" placeholder="z.B. Mitarbeiter oder Flatrate" #filter>
    </mat-form-field>

    <mat-form-field class="level1-filter">
      <mat-select [(value)]="level1Filter" (valueChange)="level1FilterChanged()">
        <mat-option value="all">alle Mitglieder</mat-option>
        <mat-option value="contract">mit Vertrag</mat-option>
        <mat-option value="without_contract">ohne Vertrag</mat-option>
        <mat-option value="prepaid">mit Prepaidguthaben</mat-option>
        <mat-option value="instructor">Mitarbeiter</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-table [dataSource]="dataSource" matSort class="list mat-elevation-z8">
      <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Name</mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.name}}  </mat-cell>
      </ng-container>

      <ng-container matColumnDef="email">
        <mat-header-cell *matHeaderCellDef mat-sort-header>E-Mail</mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.email}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="contract">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Vertrag</mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.contract}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="prepaidUnits">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Guthaben</mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.prepaidUnits}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="status">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Status</mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.status}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="operations">
        <mat-header-cell *matHeaderCellDef>Aktionen</mat-header-cell>
        <mat-cell *matCellDef="let element">
            <button mat-stroked-button color="primary" (click)="showCustomer(element.id)">Details</button>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;" [ngClass]="{'instructor': row.isInstructor}"></mat-row>

      <!-- Row shown when there is no matching data. -->
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="4" *ngIf="(loading$|async) == false">keine Mitglieder gefunden.</td>
        <td class="mat-cell" colspan="4" *ngIf="loading$|async">lade Mitglieder</td>
      </tr >
    </mat-table>
<!--  </ng-container>-->

    <mat-paginator [pageSizeOptions]="[10, 50, 100]" [class.hidden]="loading$ | async"></mat-paginator>
</div>
<!--</ng-container>-->
