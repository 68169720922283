import {createSelector} from "@ngrx/store";
import {customersFeatureKey, CustomersState} from "./reducers";
import {CustomerContractViewModel} from "../customer-contract/customer-contract-view-model";

export const customerContractViewModel = createSelector(
  state => state[customersFeatureKey],
  (state: CustomersState) => {
    if (state.selectedCustomer == null)
      return;

    let result = new CustomerContractViewModel();
    result.setCustomer(state.selectedCustomer);
    return result;
  }
)

export const allCustomers = createSelector(
  state => state[customersFeatureKey],
  (state: CustomersState) => {
    return state.availableCustomers;
  }
)

export const currentCustomer = createSelector(
  state => state[customersFeatureKey],
  (state: CustomersState) => {
    return state.selectedCustomer;
  }
)

export const currentBookings = createSelector(
  state => state[customersFeatureKey],
  (state: CustomersState) => {
    return state.currentBookings;
  }
)

export const loadingCustomers = createSelector(
  state => state[customersFeatureKey],
  (state: CustomersState) => {
    return state.loadingCustomers;
  }
)
