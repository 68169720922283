import * as moment from "moment";
import {Moment} from "moment";
import {Covid} from "./covid";

export enum ContractType {
  NO_CONTRACT, CONTINGENT_WEEKLY, CONTINGENT_MONTHLY, FLATRATE
}

export class Customer {
  public created: Moment;
  public email: string;
  public firstname: string;
  public name: string;
  public displayName: string;
  public note: string;
  public id: string;
  public prepaidUnits: number;
  public instructor: boolean;
  public contractType: ContractType = ContractType.NO_CONTRACT;
  /**
   * Einheiten die im Vertrag stehen. Durch den ContractType ergibt sich dann, wie diese zu lesen sind,
   * also ob pro Woche oder pro Monat
   */
  public contractUnits: number = 0;
  public currentUnits: number = 0;
  public contractUntil: Moment;
  public contractFrom: Moment;
  public covid: Covid;

  static fromJson(data: any) {
    let result = new Customer();
    result.id = data.userId;
    result.firstname = data.firstname;
    result.name = data.name;
    result.displayName = data.displayName;
    result.email = data.email;
    result.note = data.note;
    result.created = moment(data.created);
    result.prepaidUnits = data.prepaidUnits;
    result.instructor = data.instructor;
    result.covid = Covid.fromJson(data.covid);

    switch (data.contractType) {
      case "CONTINGENT_WEEKLY":
        result.contractType = ContractType.CONTINGENT_WEEKLY;
        result.contractUnits = data.weeklyUnits;
        result.currentUnits = data.currentWeekUnits;
        result.contractFrom = data.contingentWeeklyFrom ? moment(data.contingentWeeklyFrom) : null;
        result.contractUntil = data.contingentWeeklyUntil ? moment(data.contingentWeeklyUntil) : null;
        break;
      case "CONTINGENT_MONTHLY":
        result.contractType = ContractType.CONTINGENT_MONTHLY;
        result.contractUnits = data.monthlyUnits;
        result.currentUnits = data.currentMonthUnits;
        result.contractFrom = data.contingentMonthlyFrom ? moment(data.contingentMonthlyFrom) : null;
        result.contractUntil = data.contingentMonthlyUntil ? moment(data.contingentMonthlyUntil) : null;
        break;
      case "FLATRATE":
        result.contractType = ContractType.FLATRATE;
        result.contractFrom = data.flatrateFrom ? moment(data.flatrateFrom) : null;
        result.contractUntil = data.flatrateUntil ? moment(data.flatrateUntil) : null;
        break;
    }

    return result;
  }

  isInstructor() {
    return this.instructor;
  }

  get contractDisplayName() {
    switch (this.contractType) {
      case ContractType.NO_CONTRACT:
        return "kein Vertrag";
      case ContractType.FLATRATE:
        return "Flatrate";
      case ContractType.CONTINGENT_MONTHLY:
        return this.contractUnits + " Einheiten pro Monat";
      case ContractType.CONTINGENT_WEEKLY:
        return this.contractUnits + " Einheiten pro Woche";
      default:
        return "unbekannt";
    }
  }
}
