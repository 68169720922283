import { UntypedFormControl } from '@angular/forms';
import * as moment from 'moment';

export class DateValidator {

  static futureDate(control: UntypedFormControl): { [key: string]: any } {
     let d = moment(control.value, 'DD.MM.YYYY HH:mm');

    if (!d.isValid())
      return {"futureDate": true};

    if (d.isBefore(moment()))
      return {"futureDate": true};

    return null;
  }
}
