<form [formGroup]="formGroup" autocomplete="off">
    <mat-form-field class="type">
      <mat-label>Art</mat-label>
      <mat-select formControlName="subscribeCondition" #type>
        <mat-option value="NO_SUBSCRIPTION_ALLOWED">keine Buchung erlaubt</mat-option>
        <mat-option value="OPEN_TO_ALL">freie Veranstaltung</mat-option>
        <mat-option value="CONFIRMATION">Bestätigung</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="days" *ngIf="type.value == 'CONFIRMATION'">
      <mat-label>Tage für Bestätigung</mat-label>
      <input matInput type="number" formControlName="subscribeConfirmationDays">
    </mat-form-field>

    <mat-hint *ngIf="subscribeCondition == 'NO_SUBSCRIPTION_ALLOWED'" class="hint">
      Es sind keine öffentlichen Buchungen erlaubt
    </mat-hint>
    <mat-hint *ngIf="subscribeCondition == 'OPEN_TO_ALL'" class="hint">
      Jeder darf eine Teilnahme an dem Termin buchen.
    </mat-hint>
    <mat-hint *ngIf="subscribeCondition == 'CONFIRMATION'" class="hint">
      Die Buchungen müssen erst von einem Administrator bestätigt werden.
      Das kann z.B. eingesetzt werden, wenn zur Buchung eine Bezahlung bis zu X Tage nach der Anmeldung erfolgen muss.
    </mat-hint>
</form>
