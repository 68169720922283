<ng-container *ngIf="value != null">
  <ng-container *ngIf="value.isAtStart()">zum Start des Termins</ng-container>
  <ng-container *ngIf="value.isAtEnd() && !opening" >zum Ende des Termins</ng-container>
  <ng-container *ngIf="value.isAtEnd() && opening" >uneingeschränkt</ng-container>

  <ng-container *ngIf="value.isRTA()">
    <ng-container *ngIf="value.minutesConverted >= 0">
      {{value.minutesConverted}} {{value.unitDisplay}} vor dem Start
    </ng-container>
    <ng-container *ngIf="value.minutesConverted < 0">
      {{value.minutesConverted * -1}} {{value.unitDisplay}} nach dem Start
    </ng-container>
  </ng-container>

  <ng-container *ngIf="value.isRTBOM()">
    {{value.rtbom.days}} Tage vor Monatsanfang um
    {{value.rtbom.hour.toString().padStart(2, "0")}}:{{value.rtbom.minute.toString().padStart(2, "0")}} Uhr
  </ng-container>

  <ng-container *ngIf="value == null">
    ohne Einschränkung
  </ng-container>
</ng-container>

