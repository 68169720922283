import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {ApiError} from "../services/error";
import {BehaviorSubject, Subscription} from "rxjs";
import {MatSnackBar} from "@angular/material/snack-bar";
import {ServiceProvider} from "../services/service-provider";
import {Session} from "../services/session";
import {Router} from "@angular/router";
import {TranslateService} from "@ngx-translate/core";
import {Api} from "../services/api";
import {EventStore, ServiceProviderChanged} from "../services/event-store";

@Component({
  selector: 'app-service-provider-select',
  templateUrl: './service-provider-select.component.html',
  styleUrls: ['./service-provider-select.component.scss']
})
export class ServiceProviderSelectComponent implements OnInit, OnDestroy, AfterViewInit {
  private _serviceProviders$ = new BehaviorSubject<ServiceProvider[]>([]);
  private _subscription: Subscription;

  constructor(private _api: Api, private _session: Session,
              private _router: Router, private _snackBar: MatSnackBar,
              private translateService: TranslateService,
              private eventStore: EventStore) { }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this._subscription = this._api.getRegisterdServiceProviders()
      .subscribe(
        serviceProviders => {
          this._serviceProviders$.next(serviceProviders)
        },
        () => {
          this.onApiError(new ApiError("error.providers.loading"))
        }
      )
  }


  get serviceProviders$() {
    return this._serviceProviders$.asObservable();
  }

  ngOnDestroy(): void {
    if (this._subscription)
      this._subscription.unsubscribe();
  }

  private onApiError(error: ApiError) {
    this._snackBar.open(error.getDisplayMessage(), "OK", {duration: 5000});
  }


  select(sp: ServiceProvider) {
    this._session.setServiceProvider(sp);
    this.eventStore.trigger(new ServiceProviderChanged(sp))
    this.translateService.use(sp.variant);
    this._snackBar.open(`Anbieter ${sp.name} wurde ausgewählt.`, "OK", {duration: 3000});
    this._router.navigate(["/schedule"]);
  }
}
