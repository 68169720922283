<div class="section">
  <div class="header">Einladungslink</div>
  <div class="body">
    Den folgenden Link können Sie an Ihre Mitglieder / Nutzer schicken, damit sich diese bei Zeitfrei für
    Ihren Verein oder Unternehmen anmelden können.

    <div style="padding-top: 1em" *ngIf="(invitationLink$ | async) as link">
      {{link}}
      <button mat-stroked-button cdkCopyToClipboard="{{link}}" color="primary" style="margin-left: 20px">Copy</button>
    </div>
  </div>
</div>
