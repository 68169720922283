import { v4 as uuid } from 'uuid';

export class AppointmentAttribute {
  public _id: string;
  public id: string;
  public name: string;
  public mandatory: boolean;
  public type: string;
  public values: string[];

  constructor(src?: AppointmentAttribute) {
    this._id = uuid();
    this.values = [];
    this.type = "STRING";

    if (src) {
      this._id = src._id;
      this.id = src.id;
      this.name = src.name;
      this.mandatory = src.mandatory;
      this.type = src.type;
      if (src.values)
        this.values = [...src.values];
    }
  }

  static fromJson(data: any) {
    let result = new AppointmentAttribute();
    result.id = data.id;
    result.name = data.name;
    result.mandatory = data.mandatory;
    result.type = data.type;
    result.values = data.values;

    return result;
  }

  toJson() {
    return {
      id: this.id,
      name: this.name,
      mandatory: this.mandatory,
      type: this.type,
      values: this.values
    }
  }
}
