import {Component, Inject, OnInit} from '@angular/core';
import {UntypedFormControl} from "@angular/forms";
import {ApiActions} from "../actions/action-types";
import {Store} from "@ngrx/store";
import {AppointmentsState} from "../actions/appointments";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {Api} from "../../services/api";
import {MessageService} from "../../shared/message.service";
import {ReplaySubject} from "rxjs";
import {Wod} from "../../services/wod";
import {EventStore, TimeslotModified} from "../../services/event-store";

export interface AddAnonymDialogData {
  timeslot: Wod;
}

@Component({
  selector: 'app-add-anonym-dialog',
  templateUrl: './add-anonym-dialog.component.html',
  styleUrls: ['./add-anonym-dialog.component.scss']
})
export class AddAnonymDialogComponent implements OnInit {
  firstname: UntypedFormControl;
  lastname: UntypedFormControl;
  note: UntypedFormControl;
  numberOfPersons: UntypedFormControl;
  bookingCode$ = new ReplaySubject<string>();
  cancelButtonText: string

  timeslotId: string;
  freeSeats: number;

  isCancelDisabled: boolean;
  isBookingDisabled: boolean;

  constructor(private store: Store<AppointmentsState>,
              public dialogRef: MatDialogRef<AddAnonymDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: AddAnonymDialogData,
              private api: Api, private messageService: MessageService,
              private eventStore: EventStore) {
    this.firstname = new UntypedFormControl();
    this.lastname = new UntypedFormControl();
    this.note = new UntypedFormControl();
    this.numberOfPersons = new UntypedFormControl();
    this.numberOfPersons.setValue(1);
    this.timeslotId = data.timeslot.id;

    this.freeSeats = data.timeslot.maxMembers - data.timeslot.numberOfParticipants;
  }

  ngOnInit(): void {
    this.cancelButtonText = 'Abbrechen';
    this.isCancelDisabled = false;
    this.isBookingDisabled = false;
  }

  add() {
    this.booking();
    this.api.subscribeAnonymToTimeslot(
      this.timeslotId, this.firstname.value, this.lastname.value, this.note.value, this.numberOfPersons.value
    ).subscribe(
      (response) => {
        this.booked();
        this.messageService.showMessage("Die Buchung wurde erfolgreich angelegt.");
        this.bookingCode$.next(response.bookingCode);
        this.eventStore.trigger(new TimeslotModified());
        this.store.dispatch(ApiActions.subscribeAnonymSuccess({appointmentId: this.timeslotId}));
      },
      () => {
        this.bookingError();
        this.messageService.showError("Beim buchen trat ein Fehler auf.");
      }
    )
  }

  private booking() {
    this.cancelButtonText = 'Abbrechen';
    this.isCancelDisabled = true;
    this.isBookingDisabled = true;
  }

  private booked() {
    this.cancelButtonText = 'Schliessen';
    this.isCancelDisabled = false;
    this.isBookingDisabled = true;
  }

  private bookingError() {
    this.cancelButtonText = 'Abbrechen';
    this.isCancelDisabled = false;
    this.isBookingDisabled = false;
  }
}

