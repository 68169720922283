import { Injectable } from "@angular/core";
import {CanActivate, Router} from "@angular/router";
import {Session} from "../services/session";
import {MatSnackBar} from "@angular/material/snack-bar";

@Injectable()

export class ServiceProviderGuard implements CanActivate {
    constructor(private session: Session, private router: Router) { }

    canActivate() {
        if (this.session.hasServiceProdiver())
            return true;

      this.router.navigate(["/service-provider-select"]);
        return false;
    }
}
