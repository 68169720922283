import {Component, OnDestroy, OnInit} from '@angular/core';
import {Actions, ofActionSuccessful, Select, Store} from "@ngxs/store";
import {ResourceTypesState} from "../state/resource-types.state";
import {Observable, Subject} from "rxjs";
import {UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms";
import {ResourceTypesActions} from "../state/resource-types.actions";
import {takeUntil} from "rxjs/operators";
import {MatDialogRef} from "@angular/material/dialog";
import {ResourceType} from "../../model/resource-type";

@Component({
  selector: 'resource-type-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss']
})
export class EditComponent implements OnInit, OnDestroy{
  @Select(ResourceTypesState.currentItem) public item: Observable<ResourceType>;
  @Select(ResourceTypesState.canSave) public canSave$: Observable<boolean>;

  editForm = new UntypedFormGroup({
    name: new UntypedFormControl('', Validators.required),
  });

  private ngUnsubscribe = new Subject();

  constructor(private store: Store, private actions$: Actions, private dialogRef:MatDialogRef<EditComponent>) {}


  ngOnInit() {
    this.actions$
      .pipe(
        ofActionSuccessful(ResourceTypesActions.Save),
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe(() => {this.dialogRef.close()});
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  onSubmit() {
    this.store.dispatch(new ResourceTypesActions.Save());
  }
}
