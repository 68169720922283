import {Component, forwardRef} from '@angular/core';
import {ControlValueAccessor, UntypedFormBuilder, UntypedFormGroup, NG_VALUE_ACCESSOR, Validators} from "@angular/forms";

@Component({
  selector: 'app-period-bom',
  templateUrl: './period-bom.component.html',
  styleUrls: ['./period-bom.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PeriodBomComponent),
      multi: true
    }
  ]
})
export class PeriodBomComponent implements ControlValueAccessor {
  formGroup: UntypedFormGroup;
  propagateChange;


  constructor(fb: UntypedFormBuilder) {
    this.formGroup = fb.group({
      days: ['', [Validators.required, Validators.min(0)]],
      hour: ['', [Validators.required, Validators.min(0), Validators.max(23)]],
      minute: ['', [Validators.required, Validators.min(0), Validators.max(59)]],
    })

    this.formGroup.valueChanges
      .subscribe(data => this.formValueChanged(data));

  }

  private formValueChanged(data: any) {
    if (!this.propagateChange)
      return;

    this.propagateChange({days: data.days, hour: data.hour, minute: data.minute});
  }

  /**
   * method that writes a new value from the form model into the view
   * @param value
   */
  writeValue(value: {days: number, hour: number, minute: number }): void {
    this.formGroup.get('days').setValue(value.days);
    this.formGroup.get('hour').setValue(value.hour);
    this.formGroup.get('minute').setValue(value.minute);

  }

  /**
   * informiert das äußere Form über Änderungen
   * @param fn
   */
  registerOnChange(fn) {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
  }

  setDisabledState(isDisabled: boolean) {
    if (isDisabled) {
      this.formGroup.get('days').disable();
      this.formGroup.get('hour').disable();
      this.formGroup.get('minute').disable();
    } else {
      this.formGroup.get('days').enable();
      this.formGroup.get('hour').enable();
      this.formGroup.get('minute').enable();
    }
  }
}
