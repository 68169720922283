import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {Resource} from "./model/resource";
import {ResourcesApi} from "./services/ResourcesApi";
import {Store} from "@ngxs/store";
import {ResourcesActions} from "./resources/state/resources.actions";
import {ResourcesState} from "./resources/state/resources.state";

@Injectable()
export class ResourceModuleApi {
  constructor(private resourceApi: ResourcesApi, private store: Store) {
  }

  public getResources(): Observable<Resource[]> {
    this.store.dispatch(new ResourcesActions.LoadAll())
    return this.store.select(ResourcesState.items);
  }

  public addResource(name: string) {
    this.store.dispatch(new ResourcesActions.CreateDirect(name));
  }
}
