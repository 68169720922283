import { Component, OnInit } from '@angular/core';
import {AppointmentsState} from "../actions/appointments";
import {AppointmentActions} from "../actions/action-types";
import {Store} from "@ngrx/store";
import {MatDialogRef} from "@angular/material/dialog";
import {currentAppointment} from "../actions/selectors";
import {Wod} from "../../services/wod";

@Component({
  selector: 'app-appointment-cancel',
  templateUrl: './appointment-cancel.component.html',
  styleUrls: ['./appointment-cancel.component.scss']
})
export class AppointmentCancelComponent {
  appointment: Wod;

  constructor(public dialogRef: MatDialogRef<any>, private store: Store<AppointmentsState>) {
    this.store.select(currentAppointment).subscribe(appointment => {
      this.appointment = appointment;
    })
  }

  onNoClick(): void {
    this.dialogRef.close({canceled: false});
  }

  onCancelClick() {
    this.store.dispatch(AppointmentActions.cancelAppointment({appointment: this.appointment}));
    this.dialogRef.close({deleted: true});
  }
}
