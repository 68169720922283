import {Period} from "../appointments/period";
import {Wod} from "./wod";

export class AddWodDto {
  name: string;
  maxMembers: number;
  type: string;
  start: string;
  durationInMinutes: number;
  subscriptionOpening: Period;
  subscriptionClosing: Period;
  unsubscriptionClosing: Period;
  description: string;
  subscriptionCondition: string;
  subscribeConfirmationDays: number;
  publiclyVisible: boolean;
  informationalOnly: boolean;
  promotion: boolean;
  publicSubscribeCondition: string;
  publicSubscribeConfirmationDays: number;
  publicSubscriptionOpening: Period;
  publicSubscriptionClosing: Period;
  publicUnsubscriptionClosing: Period;
  resources: string[];
  accessCheck: string;

  constructor() {}

  public static fromTimeslot(timeslot: Wod): AddWodDto {
    let result = new AddWodDto()
    result.name = timeslot.name;
    result.maxMembers = timeslot.maxMembers;
    result.type = timeslot.type;
    result.start = timeslot.start.format();
    result.durationInMinutes = timeslot.duration_minutes;
    result.subscriptionOpening = timeslot.subscription_opening;
    result.subscriptionClosing = timeslot.subscription_closing;
    result.unsubscriptionClosing = timeslot.unsubscription_closing;
    result.description = timeslot.description;
    result.subscriptionCondition = timeslot.subscribe_condition;
    result.subscribeConfirmationDays = timeslot.subscribe_confirmation_days;
    result.publiclyVisible = timeslot.publiclyVisible;
    result.informationalOnly = timeslot.informationalOnly;
    result.promotion = timeslot.promotion;
    result.publicSubscribeCondition = timeslot.publicSubscription.subscribeCondition;
    result.publicSubscribeConfirmationDays = timeslot.publicSubscription.subscribeConfirmationDays;
    result.publicSubscriptionOpening = timeslot.publicSubscription.subscriptionOpening;
    result.publicSubscriptionClosing = timeslot.publicSubscription.subscriptionClosing;
    result.publicUnsubscriptionClosing = timeslot.publicSubscription.unsubscriptionClosing;
    result.resources = timeslot.resources.map(r => r.id);
    result.accessCheck = timeslot.accessCheck;
    return result;
  }

  asApiData() {
    let publicSubscription = {
      "subscribeCondition": this.publicSubscribeCondition,
      "subscribeConfirmationDays": this.publicSubscribeConfirmationDays,
      "subscriptionOpening": AddWodDto.periodToApi(this.publicSubscriptionOpening),
      "subscriptionClosing": AddWodDto.periodToApi(this.publicSubscriptionClosing),
      "unsubscriptionClosing": AddWodDto.periodToApi(this.publicUnsubscriptionClosing),
    };

    let customerSubscription = {
      "subscribeCondition": this.subscriptionCondition,
      "subscribeConfirmationDays": this.subscribeConfirmationDays,
      "subscriptionOpening": AddWodDto.periodToApi(this.subscriptionOpening),
      "subscriptionClosing": AddWodDto.periodToApi(this.subscriptionClosing),
      "unsubscriptionClosing": AddWodDto.periodToApi(this.unsubscriptionClosing),
    };

    return {
      "name": this.name,
      "maxParticipants": this.maxMembers,
      "type": this.type,
      "start": this.start,
      "durationMinutes": this.durationInMinutes,
      "description": this.description,
      "publiclyVisible": this.publiclyVisible,
      "informationalOnly": this.informationalOnly,
      "promotion": this.promotion,
      "public": publicSubscription,
      "customer": customerSubscription,
      "resources": this.resources,
      "accessCheck": this.accessCheck
    };
  }

  /**
   * wandelt eine Periode in eine entsprechende Datenstruktur für den API-Call um
   * @param period
   */
  private static periodToApi(period: Period) {
    if (!period)
      return {};

    if (period.isRTBOM()) {
      let it = period.rtbom;
      return {
        daysBeforeBeginOfMonth: it?.days,
        atTime: it?.hour.toString().padStart(2, "0") + ":" + it?.minute.toString().padStart(2, "0") + ":00"
      };
    }

    if (period.isRTA()) {
      return {minBefore: period.rta.minutes};
    }

    if (period.isAtEnd()) {
      return {minBefore: null};
    }

    if (period.isAtStart()) {
      return {minBefore: 0};
    }

    return {};
  }
}
