import * as moment from 'moment';
import {Moment} from 'moment';
import {ParticipantAttribute} from './participantAttribute';

export class SubscriptionRequest {
  public confirmationDaysLeft: number;
  public confirmationTargetDate: Moment;
  public name: string;
  public email: string;
  public bookingCode: string;
  public bookingReference: string;
  public requestedOn: Moment;
  public userId: string;
  public userIdentifier: string;
  public attributes: ParticipantAttribute[];

  public static fromJson(data: any): SubscriptionRequest {
    const result = new SubscriptionRequest();
    result.confirmationDaysLeft = data.confirmationDaysLeft;
    result.confirmationTargetDate = moment(data.confirmationTargetDate);
    result.name = data.name;
    result.email = data.userIdentifier;
    result.bookingCode = data.bookingCode;
    result.bookingReference = data.bookingReference;
    result.requestedOn = moment(data.requestedOn);
    result.userId = data.userId;
    result.userIdentifier = data.userIdentifier;
    result.attributes = data.attributes.map(it => ParticipantAttribute.fromJson(it));

    return result;
  }

  public getAttribute(id: string): ParticipantAttribute | undefined {
    return this.attributes.find(it => it.id === id);
  }
}
