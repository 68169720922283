import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

export interface BookPrepaidDialogData {
  units: number;
}

@Component({
  selector: 'app-book-prepaid-dialog',
  templateUrl: './book-prepaid-dialog.component.html',
  styleUrls: ['./book-prepaid-dialog.component.scss']
})
export class BookPrepaidDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<BookPrepaidDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: BookPrepaidDialogData) {}

  onNoClick(): void {
    this.dialogRef.close();
  }
}
