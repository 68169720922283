<div [formGroup]="formGroup">
  <mat-form-field class="form-field">
    <mat-label>Tage vor Monatsanfang</mat-label>
    <input matInput type="number" formControlName="days">
  </mat-form-field>
  <mat-form-field class="form-field">
    <mat-label>Stunde</mat-label>
    <input matInput type="number" formControlName="hour">
  </mat-form-field>
  <mat-form-field class="form-field">
    <mat-label>Minute</mat-label>
    <input matInput type="number" formControlName="minute">
  </mat-form-field>
</div>
