<h2 mat-dialog-title>Buchung bearbeiten</h2>
<mat-dialog-content class="mat-typography">
  <form>
    <div>
      <div>
        freie Plätze: <span [class.alert]="freeSeats <= 0">{{freeSeats}}</span>
      </div>
      <mat-form-field >
        <mat-label>Anzahl Personen</mat-label>
        <input type="number" matInput [formControl]="numberOfPersons">
      </mat-form-field>
    </div>

    <ng-container *ngIf="participant.userState === 'NON_USER_GUEST'">
      <div>
        <mat-form-field >
          <mat-label>Vorname</mat-label>
          <input type="text" matInput [formControl]="firstname" autofocus>
        </mat-form-field>
      </div>
      <div>
        <mat-form-field >
          <mat-label>Nachname</mat-label>
          <input type="text" matInput [formControl]="lastname">
        </mat-form-field>
      </div>
    </ng-container>

    <div>
      <mat-form-field >
        <mat-label>Notiz</mat-label>
        <input type="text" matInput [formControl]="note" placeholder="Email, Telefonnummer o.ä.">
      </mat-form-field>
    </div>
  </form>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-stroked-button mat-dialog-close [disabled]="isCancelDisabled">{{cancelButtonText}}</button>
  <button mat-stroked-button (click)="update()" color="warn" [disabled]="isBookingDisabled">Speichern</button>
</mat-dialog-actions>
