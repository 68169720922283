import {Action, createReducer, on} from "@ngrx/store";
import {Wod} from "../../services/wod";
import * as ApiActions from "../actions/api.actions";
import * as AppApiActions from "../../actions/api.actions";
import * as AppointmentActions from "../actions/appointment.actions";
import {SubscriptionRequest} from "../../services/subscription-request";
import {Customer} from "../../services/customer";
import {AppointmentAttribute} from "../../services/appointmentAttribute";
import {AttributeTemplate} from "../../services/attribute-template";

export interface AppointmentsState {
  selectedId: number;
  currentItem: Wod;
  currentSubscriptionRequests: SubscriptionRequest[];
  currentAttributes: AppointmentAttribute[];
  customers: Customer[];
  attributeSectionIsDirty: boolean;
  dataSectionIsDirty: boolean;
  currentSection: string;
  formData: any; //FormularDaten aus dem Detail Data Bereich
  attributeTemplates: AttributeTemplate[];
}

export const initialState: AppointmentsState = {
  selectedId: 0,
  currentItem: null,
  currentSubscriptionRequests: [],
  currentAttributes: [],
  customers: [],
  attributeSectionIsDirty: false,
  dataSectionIsDirty: false,
  currentSection: "",
  formData: null,
  attributeTemplates: []
};

export const appointmentsFeatureKey = 'appointments';
const appointmentsReducer = createReducer(
  initialState,
  on(ApiActions.customersLoaded, (state, action) => {
    return {...state, customers: action.customers}
  }),
  on(AppointmentActions.loadAppointment, (state) => {
    return {...initialState}
  }),
  on(ApiActions.appointmentLoaded, (state, action) => {
    return {...state, currentItem: action.appointment}
  }),
  on(AppointmentActions.createAppointment, (state, action) => {
    return {...state, currentItem: action.appointment, formData: null}
  }),
  on(ApiActions.subscriptionRequestsLoaded, (state, action) => {
    return {...state, currentSubscriptionRequests: action.items}
  }),
  on(AppointmentActions.updateCurrentAppointment, (state, action) => {
    return {...state, currentItem: action.appointment}
  }),
  on(AppApiActions.saveAppointmentSuccess, (state) => {
    return {...state, dataSectionIsDirty: false}
  }),
  on(AppApiActions.loadAppointmentAttributesSuccess, (state, action) => {
    return {...state, currentAttributes: action.attributes, attributeSectionIsDirty: false}
  }),
  on(AppApiActions.loadAppointmentAttributesError, (state) => {
    return {...state, currentAttributes: [], attributeSectionIsDirty: false}
  }),
  on(AppointmentActions.addAppointmentAttribute, (state, action) => {
    return {...state, currentAttributes: [...state.currentAttributes, action.attribute], attributeSectionIsDirty: true}
  }),
  on(AppointmentActions.updateAppointmentAttribute, (state, action) => {
    let newAttributes = [...state.currentAttributes];
    let idx = newAttributes.findIndex(it => it._id === action.attribute._id);
    newAttributes.splice(idx, 1, action.attribute);

    return {...state, currentAttributes: newAttributes, attributeSectionIsDirty: true}
  }),
  on(AppointmentActions.removeAppointmentAttribute, (state, action) => {
      let newAttributes = state.currentAttributes.filter(it => it._id !== action._id);
      return {...state, currentAttributes: newAttributes, attributeSectionIsDirty: true}
  }),
  on(AppApiActions.updateAppointmentAttributesSuccess, (state) => {
      return {...state, attributeSectionIsDirty: false}
  }),
  on(AppointmentActions.appointmentDataChanged, (state, action) => {
      return {...state, dataSectionIsDirty: true, formData: action.formData}
  }),
  on(AppointmentActions.resetAppointmentData, () => {
      return {...initialState}
  }),
  on(AppApiActions.saveAppointmentSuccess, (state) => {
      return {...state, dataSectionIsDirty: false}
  }),
  on(ApiActions.deleteAppointmentSuccess, (state) => {
      return {...state, dataSectionIsDirty: false, attributeSectionIsDirty: false}
  }),
  on(AppApiActions.loadAttributeTemplatesSuccess, (state, action) => {
      return {...state, attributeTemplates: action.attributeTemplates}
  }),
  on(AppApiActions.loadAttributeTemplatesError, (state) => {
      return {...state, attributeTemplates: []}
  }),
);

export function reducer(state: AppointmentsState | undefined, action: Action) {
  return appointmentsReducer(state, action);
}
