import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AuthGuard} from "../guard/auth.guard";
import {ServiceProviderGuard} from "../guard/service-provider-guard.service";
import {HomeComponent} from "./home/home.component";
import {ListComponent as ResourceListComponent} from "./resources/list/list.component";
import {ListComponent as ResourceTypeComponent} from "./resource-types/list/list.component";


const routes: Routes = [
  { path: "", component: HomeComponent, canActivate: [AuthGuard, ServiceProviderGuard] },
  { path: "resources", component: ResourceListComponent, canActivate: [AuthGuard, ServiceProviderGuard] },
  { path: "resources-types", component: ResourceTypeComponent, canActivate: [AuthGuard, ServiceProviderGuard] },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ResourceManagmentRoutingModule { }
