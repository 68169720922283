import {createSelector} from "@ngrx/store";
import {appointmentsFeatureKey, AppointmentsState} from "./appointments";

export const allCustomers = createSelector(
  state => state[appointmentsFeatureKey],
  (state: AppointmentsState) => {
    return state.customers;
  }
)

export const currentAppointment = createSelector(
  state => state[appointmentsFeatureKey],
  (state: AppointmentsState) => {
    return state.currentItem;
  }
)

export const currentSubscriptionRequests = createSelector(
  state => state[appointmentsFeatureKey],
  (state: AppointmentsState) => {
    return state.currentSubscriptionRequests;
  }
)

export const currentAttributes = createSelector(
  state => state[appointmentsFeatureKey],
  (state: AppointmentsState) => {
    return state.currentAttributes
  }
);

export const attributeTemplates = createSelector(
  state => state[appointmentsFeatureKey],
  (state: AppointmentsState) => {
    return state.attributeTemplates
  }
);

export const dirty = createSelector(
  state => state[appointmentsFeatureKey],
  (state: AppointmentsState) => {
    return {attributeSectionIsDirty: state.attributeSectionIsDirty, dataSectionIsDirty: state.dataSectionIsDirty}
  }
);

export const detailData = createSelector(
  state => state[appointmentsFeatureKey],
  (state: AppointmentsState) => {
    return {appointment: state.currentItem, formData: state.formData}
  }
)
