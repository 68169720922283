import {Injectable} from "@angular/core";
import {BehaviorSubject, Observable, ReplaySubject} from "rxjs";
import {ServiceProvider} from "./service-provider";
import {Preferences} from "./preferences";



@Injectable()
export class Session {
  private isLoggedIn$ = new BehaviorSubject<boolean>(false);
  private static SERVICE_PROVIDER_KEY = "service_provider_identifier";
  private static SERVICE_PROVIDER_NAME = "service_provider_name";
  private static SERVICE_PROVIDER_VARIANT = "service_provider_variant";
  private static PREFERENCES = "preferences";
  private static API_TOKEN_KEY = "api_token";


  private _serviceProviderName$ = new BehaviorSubject<string>("");
  private _serviceProviderIdentifier$ = new BehaviorSubject<string>("");
  public serviceProviderChanged = new ReplaySubject();

  constructor() {
    this.isLoggedIn$.next(this.hasApiToken());
    this._serviceProviderName$.next(this.getServiceProviderName());
  }


  getApiToken(): string {
    let result = localStorage.getItem(Session.API_TOKEN_KEY);
    if (result == null)
      result = "";

    return result;
  }

  login(token: string) {
    localStorage.setItem(Session.API_TOKEN_KEY, token);
    this.isLoggedIn$.next(true);
  }

  logout() {
    localStorage.removeItem(Session.API_TOKEN_KEY);
    localStorage.removeItem(Session.SERVICE_PROVIDER_KEY);
    localStorage.removeItem(Session.SERVICE_PROVIDER_NAME);
    localStorage.removeItem(Session.SERVICE_PROVIDER_VARIANT);
    localStorage.removeItem(Session.PREFERENCES);

    this.isLoggedIn$.next(false);
    this._serviceProviderName$.next("");
  }

  isLoggedIn(): Observable<boolean> {
    return this.isLoggedIn$;
  }

  private hasApiToken(): boolean {
    let token = this.getApiToken();
    return !!token;
  }

  get serviceProviderIdentifier() : string {
    let result = localStorage.getItem(Session.SERVICE_PROVIDER_KEY);
    if (result == null)
      result = "";
    return result;
  }

  get serviceProviderVariant() : string {
    let result = localStorage.getItem(Session.SERVICE_PROVIDER_VARIANT);
    if (result == null)
      result = "";
    return result;
  }

  get serviceProviderIdentifier$() : Observable<string> {
    return this._serviceProviderIdentifier$.asObservable();
  }

  get serviceProviderName$() : Observable<string> {
    return this._serviceProviderName$.asObservable();
  }

  private getServiceProviderName() : string {
    let result = localStorage.getItem(Session.SERVICE_PROVIDER_NAME);
    if (result == null)
      result = "";
    return result;
  }

  setServiceProvider(serviceProivder: ServiceProvider) {
    localStorage.setItem(Session.SERVICE_PROVIDER_KEY, serviceProivder.identifier);
    localStorage.setItem(Session.SERVICE_PROVIDER_NAME, serviceProivder.name);
    localStorage.setItem(Session.SERVICE_PROVIDER_VARIANT, serviceProivder.variant);

    this._serviceProviderName$.next(serviceProivder.name);
    this._serviceProviderIdentifier$.next(serviceProivder.identifier);
    this.serviceProviderChanged.next(serviceProivder.identifier);
  }

  hasServiceProdiver(): boolean {
    const item = localStorage.getItem(Session.SERVICE_PROVIDER_KEY);
    return item != null && item != '';
  }

  get preferences() : Preferences {
    let preferencesJson = JSON.parse(localStorage.getItem(Session.PREFERENCES));
    return Preferences.fromJson(preferencesJson);
  }

  set preferences(preferences: Preferences) {
    localStorage.setItem(Session.PREFERENCES, preferences.toJson())
  }
}
