import {AppointmentsState} from "./actions/appointments";
import {Store} from "@ngrx/store";
import {createAppointment} from "./actions/appointment.actions";
import {Wod} from "../services/wod";
import {TranslateService} from "@ngx-translate/core";
import * as moment from "moment";
import {Moment} from "moment";
import {Injectable} from "@angular/core";
import {Router} from "@angular/router";
import {Api} from "../services/api";
import * as fileSaver from 'file-saver';
import {generateParticipantReportError, generateParticipantReportSuccess} from "../actions/api.actions";
import {Session} from "../services/session";
import {MessageService} from "../shared/message.service";

@Injectable()
export class AppointmentModuleApi {
  constructor(private store: Store<AppointmentsState>, private translate: TranslateService,
              private router: Router,
              private messageService: MessageService,
              private api: Api, private session: Session) {
  }

  createWod(start: Moment, end?: Moment) {
    let appointment = new Wod();

    appointment.initValuesFor(this.session.serviceProviderVariant);
    appointment.name = this.translate.instant('WOD_ADD.DEFAULT_NAME');
    if (start != null)
      appointment.start = moment(start);

    if (end != null) {
      appointment.duration_minutes = end.diff(start, 'minutes');
    }


    this.store.dispatch(createAppointment({appointment: appointment}));
    this.router.navigate(["appointment"]);
  }

  copyWod(appointment: Wod) {
    let clone = appointment.copy();
    clone.id = null;
    this.store.dispatch(createAppointment({appointment: clone}));
    this.router.navigate(["appointment"]);

  }

  /**
   * @param date
   * @param grouping
   * @param sectionId
   */
  generateParticipantReport(date: Moment, grouping: string, sectionId: string): any {
    this.api.generateParticipantReport(date, grouping, sectionId).subscribe(
      response => {
        let blob: any = new Blob([response], {type: 'application/pdf'});
        fileSaver.saveAs(blob, 'report-'+date.format("YYYY-MM-DD")+'.pdf');
        this.store.dispatch(generateParticipantReportSuccess())
      },
      error => this.store.dispatch(generateParticipantReportError({error: error}))
    )
  }

  generateParticipantReportForTimeslot(wod: Wod): any {
    this.api.generateParticipantReportForTimeslot(wod.id).subscribe(
      response => {
        let blob: any = new Blob([response], {type: 'application/pdf'});
        fileSaver.saveAs(blob,
          'report-'+wod.start.format("YYYY-MM-DD")+' ' + wod.start.format("HH-mm") + ' bis '+wod.end.format("HH-mm")+'.pdf');
        this.store.dispatch(generateParticipantReportSuccess())
      },
      error => this.store.dispatch(generateParticipantReportError({error: error}))
    )
  }

  generateOdsParticipantReportForTimeslot(timeslot: Wod): any {
    this.api.generateOdsParticipantReportForTimeslot(timeslot.id).subscribe(
      response => {
        let blob: any = new Blob([response], {type: 'application/vnd.oasis.opendocument.spreadsheet'});
        fileSaver.saveAs(blob,
          'report-'+timeslot.start.format("YYYY-MM-DD")+' ' + timeslot.start.format("HH-mm") + ' bis '+timeslot.end.format("HH-mm")+'.ods');
        this.store.dispatch(generateParticipantReportSuccess())
      },
      error => this.store.dispatch(generateParticipantReportError({error: error}))
    )
  }

  /**
   * @param start
   * @param end
   * @param sectionId
   */
  generateOdsRangeReport(start: Moment, end: Moment, sectionId?: string) {
    this.api.generateOdsRangeReport(start, end, sectionId).subscribe(
      response => {
        let blob: any = new Blob([response], {type: 'application/vnd.oasis.opendocument.spreadsheet'});
        fileSaver.saveAs(blob,`report-${start.format("YYYY-MM-DD")}_${end.format("YYYY-MM-DD")}.ods`);
        this.messageService.showMessage("Der Report wurde erstellt.")
      },
      () => {
        this.messageService.showError("Beim erstellen des Report's trat ein Fehler auf.")
      }
    )
  }


}
