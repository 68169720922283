<form [formGroup]="formGroup" autocomplete="off">
  <mat-form-field class="type" >
    <mat-label>Art</mat-label>
    <mat-select formControlName="subscribeCondition">
      <mat-option value="OPEN_TO_CUSTOMERS">freie Veranstaltung</mat-option>
      <mat-option value="CONTINGENT">Kontingent</mat-option>
      <mat-option value="CONFIRMATION">mit Bestätigung</mat-option>
    </mat-select>
  </mat-form-field>

  <div>
    <mat-hint *ngIf="subscribeCondition == 'CONTINGENT'" class="hint">
      Die Buchung wird vom hinterlegten Kontingent des Nutzers abgebucht und die Anmeldung wird sofort bestätigt.
      Ein bestätigen der Buchung durch den Administrator ist weder notwendig noch möglich.
    </mat-hint>
    <mat-hint *ngIf="subscribeCondition == 'OPEN_TO_CUSTOMERS'" class="hint">
      Es erfolgt <strong>keine</strong> Abbuchung vom Kontingent des Nutzers und die Anmeldung wird sofort bestätigt.
      Das kann, z.B. bei Veranstaltungen die für die Nutzer kostenfrei sind, eingesetzt werden.
    </mat-hint>
    <mat-hint *ngIf="subscribeCondition == 'CONFIRMATION'" class="hint">
      Es erfolgt keine Abbuchung vom Kontingent, sondern die Buchung muss erst von einem Administrator bestätigt werden.
    </mat-hint>
  </div>
</form>
