import {Component, OnInit} from '@angular/core';
import {Api} from "../../services/api";
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {MatSnackBar} from "@angular/material/snack-bar";
import {Router} from "@angular/router";
import {filter, map} from "rxjs/operators";
import {AddCustomerDto} from "../../services/addCustomerDto";

@Component({
  selector: 'app-customer-add',
  templateUrl: './customer-add.component.html',
  styleUrls: ['./customer-add.component.scss']
})
export class CustomerAddComponent implements OnInit {
  formGroup: UntypedFormGroup;
  private apiData: AddCustomerDto;

  constructor(private api: Api, fb: UntypedFormBuilder, private _snackBar: MatSnackBar, private router: Router){
    this.formGroup = fb.group({
      firstname: ['', [Validators.required]],
      name: ['', []],
      email: ['', [Validators.required]],
    });


    this.formGroup.valueChanges
      .pipe(
        filter(() => this.formGroup.valid),
        map(formData => this.formDataToAddCustomerDto(formData))
      )
      .subscribe(
        data => {
          this.apiData = data;
        }
      );
  }

  ngOnInit(): void {
  }

  private formDataToAddCustomerDto(formData: any): AddCustomerDto {
    return new AddCustomerDto(
      formData.firstname,
      formData.name,
      formData.email,
      "", ""
    );
  }
  submit() {
    this.api.addCustomer(this.apiData)
      .subscribe(
        () => {
          this.onSuccess();
        },
        () => {
          this.onFailure();
        }
      );
  }

  private onSuccess() {
    this.router.navigate(['/customers'])
    this._snackBar.open("Der Kunde wurde erfolgreich angelegt.", "OK", {
      duration: 3000,
    });
  }

  private onFailure() {
    this._snackBar.open("Beim Erstellen des Kunden ist ein Fehler aufgetreten.", "OK", {
      duration: 3000,
    });
  }
}
