import {Component, Input} from '@angular/core';
import {Period} from "../../../period";

@Component({
  selector: 'app-period-display',
  templateUrl: './period-display.component.html',
  styleUrls: ['./period-display.component.scss']
})
export class PeriodDisplayComponent {
  @Input("value") value: Period;
  @Input("opening") opening = false;
}
