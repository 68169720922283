<ng-container *ngIf="customer$ | async as customer">
  <div class="navigation">
    <div (click)="abort()">Zurück</div>
    <div (click)="activateSection('data')" >allg. Daten</div>
    <div (click)="activateSection('contract')">Vertrag</div>
    <div (click)="activateSection('bookings')">Buchungen</div>
    <div (click)="activateSection('actions')">Aktionen</div>
    <div (click)="activateSection('log')">Log</div>
  </div>

  <div class="content">
    <app-customer-data *ngIf="section == 'data'" (dataChanged)="dataChanged()"></app-customer-data>
    <app-customer-contract *ngIf="section == 'contract'"></app-customer-contract>
    <app-customer-bookings *ngIf="section == 'bookings'" [customer]="customer"></app-customer-bookings>
    <app-customer-actions *ngIf="section == 'actions'"></app-customer-actions>
    <app-customer-log *ngIf="section == 'log'" [customer]="customer"></app-customer-log>
  </div>
</ng-container>
