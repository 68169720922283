import {Component} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {Router} from "@angular/router";
import {Session} from "../services/session";
import {MatSnackBar} from "@angular/material/snack-bar";
import {filter} from "rxjs/operators";
import {ApiError} from "../services/error";
import {Api} from "../services/api";

@Component({
  selector: 'app-password-reset',
  templateUrl: './password-reset.component.html',
  styleUrls: ['./password-reset.component.scss']
})
export class PasswordResetComponent {
  resetForm: UntypedFormGroup;
  private email: string;

  constructor(fb: UntypedFormBuilder, private _router: Router, private session: Session, private api: Api,
              private _snackBar: MatSnackBar) {
    this.resetForm = fb.group(
      {
        email: ['', [Validators.required]],
      }
    )

    this.resetForm.valueChanges
      .pipe(
        filter(() => this.resetForm.valid),
      )
      .subscribe(value => {
        this.email = value.email;
      })
  }

  submit() {
    this.api.sendPasswordMail(this.email)
      .subscribe(
        () => {
          this.onSuccess();
        },
        (error: ApiError) => {
          this.onFailure(error);
        }
      );
  }

  onSuccess() {
    this._snackBar.open(`Wir haben Dir eine Mail an ${this.email} geschickt.`, "OK", {duration: 8000});
    this._router.navigate(["/login"]);
  }

  onFailure(error: ApiError) {
    this._snackBar.open(error.getDisplayMessage(), "OK", {duration: 8000});
  }
}
