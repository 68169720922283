import {Component, OnInit} from '@angular/core';
import * as Version from "../environments/version";
import {Session} from "./services/session";
import {TranslateService} from "@ngx-translate/core";
import {Api} from "./services/api";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'Zeitfrei Admin';
  version: string;

  constructor(private session: Session, private api: Api, private translation: TranslateService) {
  }

  ngOnInit(): void {
    this.version = Version.version;
    this.loadTranslation();
  }

  private loadTranslation() {
    this.translation.use(this.session.serviceProviderVariant);
  }

}
