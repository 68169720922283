<div class="section">
  <div class="header">Login</div>
  <div class="body">
    <div id="errorMessage">{{errorMessage}}</div>
    <form [formGroup]="loginForm" autocomplete="off" novalidate>
      <div>
        <mat-form-field>
          <mat-label>E-Mail</mat-label>
          <input id="email" matInput formControlName="email" autofocus>
        </mat-form-field>
      </div>
      <div>
        <mat-form-field>
          <mat-label>Passwort</mat-label>
          <input id="password" matInput formControlName="password" type="password">
        </mat-form-field>
      </div>
      <div class="actions">
        <button id="submit" mat-stroked-button color="primary" (click)="submit()" [disabled]="!this.loginForm.valid">
          Login
        </button>
      </div>
    </form>
    <a data-cy="login-by-link"  routerLink="/login-by-link">Login via E-Mail</a>
    <a href="/reset-password">Neues Passwort setzen</a>
  </div>
</div>
