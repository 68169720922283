<h1 class="header mat-typography">Anfragen</h1>

<mat-form-field class="filter">
  <mat-label>Filter</mat-label>
  <input matInput (keyup)="applyFilter($event.target.value)" placeholder="z.B. Name">
</mat-form-field>

<mat-form-field class="attributes">
  <mat-label>Attribute</mat-label>
  <mat-select [formControl]="fcDisplayedColumns" multiple>
    <mat-option *ngFor="let attribute of attributes" [value]="attribute">{{attribute.name}}</mat-option>
  </mat-select>
</mat-form-field>

<div class="list">
  <table>
    <thead>
    <tr>
      <th>Name</th>
      <th>E-Mail</th>
      <th>Buchungsreferenz</th>
      <th>Anfrage von</th>
      <th>Zieldatum</th>
      <th>Tage übrig</th>
      <th *ngFor="let attribute of fcDisplayedColumns.value">{{attribute.name}}</th>
      <th class="actions"> </th>
    </tr>
    </thead>

    <tbody>
    <tr *ngFor="let item of filteredRequests">
      <td>{{item.name}}</td>
      <td>{{item.email}}</td>
      <td>{{item.bookingReference}}</td>
      <td>{{item.requestedOn.format("DD.MM.YYYY HH:mm")}}</td>
      <td>{{item.confirmationTargetDate.format("DD.MM.YYYY")}}</td>
      <td>{{item.confirmationDaysLeft}}</td>
      <td *ngFor="let attribute of fcDisplayedColumns.value">{{item.getAttribute(attribute.id)?.value}}</td>
      <td class="actions">
        <button mat-stroked-button color="primary" (click)="unsubscribe(wod, item)">Ablehnen</button>
        <button mat-stroked-button color="primary" (click)="confirm(wod, item)">Bestätigen</button>
      </td>
    </tr>
    </tbody>
  </table>
</div>
