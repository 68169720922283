export class ResourceType {
  id: string;
  name: string;

  public static fromJson(data: any) {
    let result = new ResourceType();
    result.id = data.id
    result.name = data.name;
    return result;
  }

}
