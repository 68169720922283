import {Component, forwardRef, Input} from '@angular/core';
import {PeriodDialogComponent} from "../period-dialog/period-dialog.component";
import {MatDialog} from "@angular/material/dialog";
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";
import {Period} from "../../../period";

@Component({
  selector: 'app-period-editor',
  templateUrl: './period-editor.component.html',
  styleUrls: ['./period-editor.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => PeriodEditorComponent),
    multi: true
  }]
})
export class PeriodEditorComponent implements ControlValueAccessor {
  private propagateChange;
  public value: Period;
  @Input("rtBomEnabled") rtBomEnabled = false;
  @Input() opening = false;

  constructor(private _dialog: MatDialog) {
  }

  openDialog() {
    const dialogRef = this._dialog.open(PeriodDialogComponent, {
      width: '400px',
      data: {period: this.value, rtBomEnabled: this.rtBomEnabled, opening: this.opening}
    });

    dialogRef.afterClosed().subscribe((result: Period) => {
      if (result) {
        this.value = result;
        this.propagateChange(result);
      }
    });
  }

  /**
   * wenn ein neuer Wert ins Model geschrieben wird und der im View angezeigt werden soll.
   */
  writeValue(obj: Period): void {
    this.value = obj;
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
  }

}
