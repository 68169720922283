import {Component, Input, OnInit} from '@angular/core';
import {Api} from "../../../../services/api";
import {Attachment} from "../../../../services/attachment";
import {MatTableDataSource} from "@angular/material/table";
import {map} from "rxjs/internal/operators";
import {MessageService} from "../../../../shared/message.service";
import {ApiError} from "../../../../services/error";

class ListItem {
  public id: string = "";
  public name: string = "";
  public size: number = 0;

  constructor(attachment: Attachment) {
    this.id = attachment.id;
    this.name = attachment.name;
    this.size = attachment.size;
  }
}

@Component({
  selector: 'app-timeslot-detail-attachment-list',
  templateUrl: './timeslot-detail-attachment-list.component.html',
  styleUrls: ['./timeslot-detail-attachment-list.component.scss']
})
export class TimeslotDetailAttachmentListComponent implements OnInit {
  @Input() timeslotId: string;

  displayedColumns = ['name', 'size', 'operation'];
  dataSource = new MatTableDataSource<ListItem>();

  constructor(private api: Api, private messageService: MessageService) {
  }

  ngOnInit(): void {
    this.loadAttachments();
  }

  public loadAttachments() {
    this.api.getAttachments(this.timeslotId).pipe(
      map(attchments => attchments.map(attachment => new ListItem(attachment)))
    ).subscribe(
      (data) => {
        this.dataSource.data = data
      },
      (error: ApiError) => {
        this.messageService.showMessage(error.getDisplayMessage())
      }
    );
  }

  delete(listItem: ListItem) {
    this.api.deleteAttachment(this.timeslotId, listItem.id).subscribe(
      () => {
        this.messageService.showMessage("Der Anhang " + listItem.name + " wurde erfolgreich gelöscht.");
        this.loadAttachments();
      },
      (error: ApiError) => {
        this.messageService.showError(error)
      }
    );
  }

  download(listItem: ListItem) {
    return this.api.getAttachmentContent(listItem.id)
      .subscribe((response: any) => {
        let dataType = response.type;
        let binaryData = [];
        binaryData.push(response);
        let downloadLink = document.createElement('a');
        downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
        downloadLink.setAttribute('download', listItem.name);
        document.body.appendChild(downloadLink);
        downloadLink.click();
      }, error => {
        console.log('download error:', JSON.stringify(error));
      }, () => {
        console.log('Completed file download.')
      });
  }
}
