import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {MatSnackBar} from "@angular/material/snack-bar";
import {Wod} from "../../../services/wod";
import {AppointmentActions} from "../../actions/action-types";
import {AppointmentsState} from "../../actions/appointments";
import {Store} from "@ngrx/store";
import {MessageLayoutComponent} from "../../../message-display/message-layout/message-layout.component";

export interface AppointmentSeriesDeleteComponentData {
  wod: Wod,
}

@Component({
  selector: 'app-wod-series-delete',
  templateUrl: './appointment-series-delete.component.html',
  styleUrls: ['./appointment-series-delete.component.scss']
})
export class AppointmentSeriesDeleteComponent {
  public selectedType: string = 'single';
  public types: {value: string, label: string}[] = [
    {value: 'single', label: 'Diesen Termin'},
    {value:'all', label:'Diesen Termin und alle folgenden'}];

  constructor(
    public dialogRef: MatDialogRef<AppointmentSeriesDeleteComponent>,
    private _snackBar: MatSnackBar,
    private store: Store<AppointmentsState>,
    @Inject(MAT_DIALOG_DATA) public data: AppointmentSeriesDeleteComponentData) {}

  onCancelClick(): void {
    this.dialogRef.close({deleted: false});
  }

  onDeleteClick() {
    switch (this.selectedType) {
      case 'single':
        this.deleteSingle();
        break;
      case 'all':
        this.deleteAll();
        break;
      default:
        throw new Error('unexpected value ' + this.selectedType);
    }
  }

  private deleteSingle() {
    if (this.data.wod.hasParticipants() || this.data.wod.hasRequestedParticipants()) {
      this._snackBar.openFromComponent(MessageLayoutComponent, {
        duration: 8000, data: {message: "Dieser Termin kann nicht gelöscht werden, da er bereits Teilnehmer enthält.", action: "OK"}
      });

      this.dialogRef.close({deleted: false});
      return;
    }

    this.store.dispatch(AppointmentActions.deleteAppointment({appointment: this.data.wod}))
    this.dialogRef.close({deleted: true});
  }

  private deleteAll() {
    this.store.dispatch(AppointmentActions.deleteAppointmentSerie({appointment: this.data.wod}))
    this.dialogRef.close({deleted: true});
  }
}
