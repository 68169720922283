import {Component} from '@angular/core';
import {MatSnackBar} from "@angular/material/snack-bar";
import {Store} from "@ngrx/store";
import {MessageLayoutComponent} from "./message-layout/message-layout.component";
import {AppState} from "../actions/reducers";
import {AppActions} from "../actions/action-types";
import {message} from "../actions/selectors";

@Component({
  selector: 'app-message-display',
  templateUrl: './message-display.component.html',
  styleUrls: ['./message-display.component.scss']
})
export class MessageDisplayComponent {

  constructor(private store: Store<AppState>, private snackBar: MatSnackBar) {
    this.store.select(message).subscribe(message => this.showMessage(message));
  }

  public showMessage(message: string) {
    if (message == null || message == "")
      return;

    let ref = this.snackBar.openFromComponent(MessageLayoutComponent, {
      duration: 8000, data: {message: message, action: "OK"}
    });
    ref.afterDismissed().subscribe(() => this.store.dispatch(AppActions.messageDisplayed()));
  }
}
