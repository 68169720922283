import {Component} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Wod} from '../../../services/wod';
import {Api} from '../../../services/api';
import {SubscriptionRequest} from '../../../services/subscription-request';
import {AppointmentsState} from '../../actions/appointments';
import {Store} from '@ngrx/store';
import {currentAppointment, currentAttributes, currentSubscriptionRequests} from '../../actions/selectors';
import {AppointmentActions} from '../../actions/action-types';
import {AppointmentAttribute} from '../../../services/appointmentAttribute';
import {UntypedFormControl} from '@angular/forms';

@Component({
  selector: 'app-wod-detail-enquirers',
  templateUrl: './appointment-detail-enquirers.component.html',
  styleUrls: ['./appointment-detail-enquirers.component.scss']
})
export class AppointmentDetailEnquirersComponent {
  wod: Wod;
  fcDisplayedColumns = new UntypedFormControl();
  attributes: AppointmentAttribute[];
  private allRequests: SubscriptionRequest[] = [];
  filteredRequests: SubscriptionRequest[] = [];

  constructor(private api: Api, private snackBar: MatSnackBar, private store: Store<AppointmentsState>) {
    store.select(currentAppointment).subscribe(wod => {
      this.wod = wod;
    });

    store.select(currentAttributes).subscribe((attributes) => {
      this.attributes = attributes;
    });


    store.select(currentSubscriptionRequests)
      .subscribe(
        (data) => {
          this.allRequests = data;
          this.filteredRequests = data;
        }
      );
  }

  public applyFilter(filter: string) {
    const nFilter = filter.trim().toLowerCase();
    this.filteredRequests = this.allRequests.filter(record => {
      if (record.name?.trim().toLowerCase().indexOf(nFilter) !== -1) {
        return true;
      }

      if (record.email?.trim().toLowerCase().indexOf(nFilter) !== -1) {
        return true;
      }

      if (record.bookingReference?.trim().toLowerCase().indexOf(nFilter) !== -1) {
        return true;
      }

      const attributeIds = this.fcDisplayedColumns.value?.map(it => it.id) ?? [];
      const matchedAttributes = record.attributes
        .filter(it => attributeIds.includes(it.id))
        .filter(it => it.value?.trim().toLowerCase().indexOf(nFilter) !== -1 ?? false)
        .length;

      return matchedAttributes > 0;
    });
  }

  confirm(wod: Wod, subscriptionRequest: SubscriptionRequest) {
    this.store.dispatch(AppointmentActions.confirmSubscription({appointmentId: wod.id, subscriptionRequest}));
  }

  unsubscribe(wod: Wod, subscriptionRequest: SubscriptionRequest) {
    this.store.dispatch(AppointmentActions.rejectSubscription({appointmentId: wod.id, subscriptionRequest}));
  }
}
