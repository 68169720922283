import * as moment from "moment";
import {Moment} from "moment";


export class CustomerSubscription {
  public workoutId: string;
  public name: string;
  public durationMinutes: number;
  public start: Moment;
  public end: Moment;
  public unsubscriptionClosingAt: Moment;

  static fromJson(data: any) {
    let result = new CustomerSubscription();
    result.workoutId = data.workoutId;
    result.name = data.name;
    result.durationMinutes = data.duration_minutes;
    result.unsubscriptionClosingAt = data.unsubscription_closing_at;
    result.start = moment(data.start);
    result.end = moment(data.end);

    return result;
  }
}
