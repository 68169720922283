import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {MatSnackBar} from "@angular/material/snack-bar";
import {Wod} from "../../services/wod";
import {AppointmentsState} from "../actions/appointments";
import {Store} from "@ngrx/store";
import {AppointmentActions} from "../actions/action-types";
import {MessageLayoutComponent} from "../../message-display/message-layout/message-layout.component";

export interface AppointmentDeleteDialogData {
  wod: Wod,
}

/**
 * Diese Komponente dient zum löschen eines einzelnen Termin vom Typ FIXED, sprich ohne Wiederholungen
 */
@Component({
  selector: 'app-wod-delete',
  templateUrl: './appointment-delete.component.html',
  styleUrls: ['./appointment-delete.component.scss']
})
export class AppointmentDeleteComponent {
  constructor(
    public dialogRef: MatDialogRef<AppointmentDeleteComponent>,
    private store: Store<AppointmentsState>,
    private snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: AppointmentDeleteDialogData) {}

  onNoClick(): void {
    this.dialogRef.close({deleted: false});
  }

  onDeleteClick() {
    if (this.data.wod.hasParticipants() || this.data.wod.hasRequestedParticipants()) {
      this.snackBar.openFromComponent(MessageLayoutComponent, {
        duration: 8000, data: {message: "Dieser Termin kann nicht gelöscht werden, da er bereits Teilnehmer enthält.", action: "OK"}
      });

      this.dialogRef.close({deleted: false});
      return;
    }

    this.store.dispatch(AppointmentActions.deleteAppointment({appointment: this.data.wod}))
    this.dialogRef.close({deleted: true});
  }
}
