<mat-table [dataSource]="dataSource" matSort class="main mat-elevation-z8">
  <ng-container matColumnDef="name">
    <mat-header-cell *matHeaderCellDef mat-sort-header>Name</mat-header-cell>
    <mat-cell *matCellDef="let element"> {{element.name}}  </mat-cell>
  </ng-container>

  <ng-container matColumnDef="type">
    <mat-header-cell *matHeaderCellDef mat-sort-header>Typ</mat-header-cell>
    <mat-cell *matCellDef="let element"> {{element.type}}</mat-cell>
  </ng-container>

  <ng-container matColumnDef="mandatory">
    <mat-header-cell *matHeaderCellDef mat-sort-header>Pflichfeld</mat-header-cell>
    <mat-cell *matCellDef="let element"> {{element.mandatory}}</mat-cell>
  </ng-container>

  <ng-container matColumnDef="values">
    <mat-header-cell *matHeaderCellDef mat-sort-header>Werte</mat-header-cell>
    <mat-cell *matCellDef="let element"> {{element.values}}</mat-cell>
  </ng-container>

  <ng-container matColumnDef="operations">
    <mat-header-cell *matHeaderCellDef>Aktionen</mat-header-cell>
    <mat-cell *matCellDef="let element">
      <button mat-stroked-button color="primary" (click)="edit(element)" data-test-id="edit"><mat-icon>create</mat-icon></button>
      <button mat-stroked-button (click)="remove(element)"  data-test-id="delete"><mat-icon>delete</mat-icon></button>
    </mat-cell>
  </ng-container>

  <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
  <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
</mat-table>
