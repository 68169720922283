<ng-container *ngIf="appointment$ | async as appointment">
  <ng-container *ngIf="appointment.isFixed()">
    <h1 class="header mat-typography">Attribute</h1>

    <div class="actions">
      <button mat-stroked-button (click)="addAttribute()" id="add_attribute">Attribut hinzufügen</button>
      <button mat-stroked-button (click)="save(appointment.id)" id="save" color="primary" [disabled]="!(dirty$ | async)">Speichern</button>
    </div>

    <app-attribute-list></app-attribute-list>
  </ng-container>

  <ng-container *ngIf="!appointment.isFixed()">
    <div class="main">Attribute werden derzeit nur für Einzeltermine unterstützt. Eine Unterstützung für Serientermine ist in Planung.</div>
  </ng-container>
</ng-container>
