import {Injectable} from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {catchError, map, mergeMap, tap} from "rxjs/operators";
import * as ApiActions from "./api.actions";
import * as AppApiActions from "../../actions/api.actions";
import {EMPTY, of} from "rxjs";
import {Api} from "../../services/api";
import {AppointmentActions} from "./action-types";
import {ApiError} from "../../services/error";
import {EventStore, TimeslotModified} from "../../services/event-store";

@Injectable()
export class AppointmentEffects {
  constructor(
    private actions$: Actions,
    private api: Api,
    private eventStore: EventStore
  ) {
  }

  loadAppointment = createEffect(() => this.actions$.pipe(
    ofType(AppointmentActions.loadAppointment,
      ApiActions.confirmSubscriptionSuccessful,
      ApiActions.rejectSubscriptionSuccessful,
      ApiActions.subscribeUserSuccess,
      ApiActions.unsubscribeParticipantSuccess,
      ApiActions.subscribeAnonymSuccess,
      ApiActions.cancelAppointmentSuccess,
      AppApiActions.saveAppointmentSuccess,
      AppApiActions.updateVideoLinkSuccess),
    mergeMap((action) => {
        return this.api.getWorkout(action.appointmentId)
          .pipe(
            map(appointment => (ApiActions.appointmentLoaded({appointment: appointment}))),
            catchError(error => of(AppApiActions.loadAppointmentError({error: new ApiError(error)})))
          )
      }
    )
    ),
  );

  s5 = createEffect(() => this.actions$.pipe(
    ofType(AppointmentActions.loadAppointment,
      ApiActions.confirmSubscriptionSuccessful,
      ApiActions.rejectSubscriptionSuccessful,
      ApiActions.subscribeUserSuccess,
      ApiActions.unsubscribeParticipantSuccess,
      ApiActions.subscribeAnonymSuccess,
      ApiActions.cancelAppointmentSuccess),
    mergeMap((action) => {
        return this.api.getSubscriptionRequests(action.appointmentId)
          .pipe(
            map(items => (ApiActions.subscriptionRequestsLoaded({items: items}))),
            catchError(() => EMPTY)
          )
      }
    )
    ),
  );

  s6 = createEffect(() => this.actions$.pipe(
    ofType(AppointmentActions.confirmSubscription),
    mergeMap((action) => {
        return this.api.confirmSubscription(action.appointmentId, action.subscriptionRequest.userId)
          .pipe(
            tap(() => this.eventStore.trigger(new TimeslotModified())),
            map(() => (ApiActions.confirmSubscriptionSuccessful({appointmentId: action.appointmentId, subscriptionRequest: action.subscriptionRequest}))),
            catchError(() => EMPTY)
          )
      }
    )
    ),
  );

  s7 = createEffect(() => this.actions$.pipe(
    ofType(AppointmentActions.rejectSubscription),
    mergeMap((action) => {
        return this.api.rejectSubscription(action.appointmentId, action.subscriptionRequest.userId)
          .pipe(
            tap(() => this.eventStore.trigger(new TimeslotModified())),
            map(() => (ApiActions.rejectSubscriptionSuccessful({appointmentId: action.appointmentId, subscriptionRequest: action.subscriptionRequest}))),
            catchError(() => EMPTY)
          )
      }
    )
    ),
  );

  s8 = createEffect(() => this.actions$.pipe(
    ofType(AppointmentActions.unsubscribeParticipant),
    mergeMap((action) => {
        return this.api.unsubscribeMemberFromWod(action.appointmentId, action.participant.userId)
          .pipe(
            tap(() => this.eventStore.trigger(new TimeslotModified())),
            map(() => (ApiActions.unsubscribeParticipantSuccess({appointmentId: action.appointmentId, participant: action.participant}))),
            catchError(() => EMPTY)
          )
      }
    )
    ),
  );

  s9 = createEffect(() => this.actions$.pipe(
    ofType(AppointmentActions.subscribeUser),
    mergeMap((action) => {
        return this.api.subscribeMemberToWod(action.appointmentId, action.customer.id)
          .pipe(
            tap(() => this.eventStore.trigger(new TimeslotModified())),
            map(() => (ApiActions.subscribeUserSuccess({appointmentId: action.appointmentId, customer: action.customer}))),
            catchError(() => EMPTY)
          )
      }
    )
    ),
  );

  s10 = createEffect(() => this.actions$.pipe(
    ofType(AppointmentActions.cancelAppointment),
    mergeMap((action) => {
        return this.api.cancelTimeslot(action.appointment.id)
          .pipe(
            tap(() => this.eventStore.trigger(new TimeslotModified())),
            map(() => (ApiActions.cancelAppointmentSuccess({appointmentId: action.appointment.id}))),
            catchError(() => EMPTY)
          )
      }
    )
    ),
  );

  s11 = createEffect(() => this.actions$.pipe(
    ofType(AppointmentActions.loadCustomers),
    mergeMap(() => {
        return this.api.getCustomers()
          .pipe(
            map(items => (ApiActions.customersLoaded({customers: items}))),
            catchError(() => EMPTY)
          )
      }
    )
    ),
  );

  deleteAppointmentSerie = createEffect(() => this.actions$.pipe(
    ofType(AppointmentActions.deleteAppointmentSerie),
    mergeMap((action) => {
        return this.api.deleteTimeslots(action.appointment.id)
          .pipe(
            tap(() => this.eventStore.trigger(new TimeslotModified())),
            map(() => ApiActions.deleteAppointmentSuccess({appointment: action.appointment})),
            catchError(error => of(ApiActions.deleteAppointmentError({appointment: action.appointment, error: new ApiError(error)})))
          )
      }
    )
    ),
  );

  deleteAppointment = createEffect(() => this.actions$.pipe(
    ofType(AppointmentActions.deleteAppointment),
    mergeMap((action) => {
        return this.api.deleteTimeslot(action.appointment.id)
          .pipe(
            tap(() => this.eventStore.trigger(new TimeslotModified())),
            map(() => ApiActions.deleteAppointmentSuccess({appointment: action.appointment})),
            catchError(error => of(ApiActions.deleteAppointmentError({appointment: action.appointment, error: new ApiError(error)})))
          )
      }
    )
    ),
  );

  loadAppointmentAttributes = createEffect(() => this.actions$.pipe(
    ofType(AppointmentActions.loadAppointmentAttributes),
    mergeMap((action) => {
        return this.api.loadAppointmentAttributes(action.appointmentId)
          .pipe(
            map(items => AppApiActions.loadAppointmentAttributesSuccess({attributes: items})),
            catchError(error => of(AppApiActions.loadAppointmentAttributesError({appointmentId: action.appointmentId, error: new ApiError(error)})))
          )
      }
    )
    ),
  );

  loadAttributeTemplates = createEffect(() => this.actions$.pipe(
    ofType(AppointmentActions.loadAttributeTemplates),
    mergeMap(() => {
        return this.api.getAttributeTemplates()
          .pipe(
            map(items => AppApiActions.loadAttributeTemplatesSuccess({attributeTemplates: items})),
            catchError(error => of(AppApiActions.loadAttributeTemplatesError({error: new ApiError(error)})))
          )
      }
    )
    ),
  );

  updateAppointmentAttributes = createEffect(() => this.actions$.pipe(
    ofType(AppointmentActions.updateAppointmentAttributes),
    mergeMap((action) => {
        return this.api.updateAppointmentAttributes(action.appointmentId, action.attributes)
          .pipe(
            map(() => AppApiActions.updateAppointmentAttributesSuccess()),
            catchError(error => of(AppApiActions.updateAppointmentAttributesError({error: new ApiError(error)})))
          )
      }
    )
    ),
  );

  saveFixedAppointment = createEffect(() => this.actions$.pipe(
    ofType(AppointmentActions.saveFixedAppointment),
    mergeMap((action) => {
        return this.api.updateTimeslot(action.id, action.dto)
          .pipe(
            tap(() => this.eventStore.trigger(new TimeslotModified())),
            map(() => AppApiActions.saveAppointmentSuccess({appointmentId: action.id})),
            catchError(error => of(AppApiActions.saveAppointmentError({error: new ApiError(error)})))
          )
      }
    )
    ),
  );

  saveSeriesAppointment = createEffect(() => this.actions$.pipe(
    ofType(AppointmentActions.saveSeriesAppointment),
    mergeMap((action) => {
        return this.api.updateTimeslots(action.id, action.dto)
          .pipe(
            tap(() => this.eventStore.trigger(new TimeslotModified())),
            map(() => AppApiActions.saveAppointmentSuccess({appointmentId: action.id})),
            catchError(error => of(AppApiActions.saveAppointmentError({error: new ApiError(error)})))
          )
      }
    )
    ),
  );

  updateVideoLink = createEffect(() => this.actions$.pipe(
    ofType(AppointmentActions.updateVideoLink),
    mergeMap((action) => {
        return this.api.updateVideoLink(action.videoLink, action.appointment)
          .pipe(
            map(() => AppApiActions.updateVideoLinkSuccess({appointmentId: action.appointment.id})),
            catchError(error => of(AppApiActions.updateVideoLinkError({error: new ApiError(error)})))
          )
      }
    )
    ),
  );

}
