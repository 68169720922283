<h2 mat-dialog-title>Typ bearbeiten</h2>

<mat-dialog-content>
  <form [formGroup]="editForm" ngxsForm="resourceTypes.editForm" (ngSubmit)="onSubmit()">
    <mat-form-field>
      <mat-label>Name</mat-label>
      <input matInput type="text" formControlName="name" placeholder="Name des Typs" cdkFocusInitial required/>
    </mat-form-field>

  </form>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Abbrechen</button>
  <button mat-raised-button color="primary" (click)="onSubmit()" [disabled]="!(canSave$ | async)">Speichern</button>
</mat-dialog-actions>
