import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import * as moment from "moment";
import {Wod} from "../../../services/wod";
import {DateValidator} from "../../../validators/DateValidator";
import {detailData} from "../../actions/selectors";
import {AppointmentsState} from "../../actions/appointments";
import {Store} from "@ngrx/store";
import {AppointmentUpdateFixedComponent} from "../../appointment-update-fixed/appointment-update-fixed.component";
import {AppointmentUpdateSerieComponent} from "../../appointment-update-serie/appointment-update-serie.component";
import {MatDialog} from "@angular/material/dialog";
import {Api} from "../../../services/api";
import {AppointmentDeleteComponent} from "../../appointment-delete/appointment-delete.component";
import {AppointmentSeriesDeleteComponent} from "../../appointment-delete/appointment-series-delete/appointment-series-delete.component";
import {Location} from "@angular/common";
import {AppointmentCancelComponent} from "../../appointment-cancel/appointment-cancel.component";
import {Observable, Subscription} from "rxjs";
import {AppointmentActions} from "../../actions/action-types";
import {debounceTime, filter, map} from "rxjs/operators";
import {AppointmentModuleApi} from "../../appointment-module-api.service";
import * as AppApiActions from "../../../actions/api.actions";
import {ApiError} from "../../../services/error";
import {Router} from "@angular/router";
import {MatSnackBar} from "@angular/material/snack-bar";
import {ResourceModuleApi} from "../../../resource-managment/resource-module-api";
import {Resource} from "../../../resource-managment/model/resource";
import {MatTabGroup} from "@angular/material/tabs";
import {AddWodDto} from "../../../services/addWodDto";
import {EventStore, TimeslotModified} from "../../../services/event-store";

// const SubscriptionTimeValidator: ValidatorFn = (fg: UntypedFormGroup) => {
//   const opening = fg.get('subscriptionOpeningInMinutes').value;
//   const closing = fg.get('subscriptionClosingInMinutes').value;
//
//   if (opening == "" || closing == "")
//     return null;
//
//   if (parseInt(opening) >= parseInt(closing)) {
//     return null;
//   }
//
//   return {subscriptionTime: true};
// };

@Component({
  selector: 'app-wod-detail-data',
  templateUrl: './appointment-detail-data.component.html',
  styleUrls: ['./appointment-detail-data.component.scss']
})
export class AppointmentDetailDataComponent implements OnInit, AfterViewInit, OnDestroy {
  wod: Wod;

  formGroup: UntypedFormGroup;
  startMask = [/\d/, /\d/, '.', /\d/, /\d/, '.', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, ':', /\d/, /\d/]
  private sub1: Subscription;
  private sub2: Subscription;
  private sub3: Subscription;
  public resources$: Observable<Map<string, Resource[]>>;
  @ViewChild('timelineTabs') timelineTabs: MatTabGroup;
  public weekday: string = "Montag";

  constructor(private fb: UntypedFormBuilder,
              private store: Store<AppointmentsState>,
              public dialog: MatDialog,
              private location: Location,
              private appointmentModuleApi: AppointmentModuleApi,
              private api: Api,
              private router: Router,
              private matSnackbar: MatSnackBar,
              private resourceModuleApi: ResourceModuleApi,
              private eventStore: EventStore
  ) {
    this.formGroup = fb.group({
        name: ['', [Validators.required]],
        max_members: ['', [Validators.required, Validators.min(1)]],
        accessCheck: ['NONE', [Validators.required]],
        type: ['', [Validators.required]],
        start: ['', [Validators.required, DateValidator.futureDate]],
        durationInMinutes: ['', [Validators.required, Validators.min(1)]],

        subscribeCondition: ['', [Validators.required]],
        subscription_opening: [''],
        subscription_closing: [''],
        unsubscription_closing: [''],

        publicSubscribeCondition: ['', [Validators.required]],
        publicSubscriptionOpening: [''],
        publicSubscriptionClosing: [''],
        publicUnsubscriptionClosing: [''],

        description: ['', []],
        publiclyVisible: ['', []],
        promotion: ['', []],
        informationalOnly: ['', []],

        resources: ['',],
      },
      // {validator: SubscriptionTimeValidator} //TODO wenn korrigiert dann wieder aktivieren
    );


    this.sub2 = store.select(detailData)
      .pipe(filter(it => it.appointment != null || it.formData != null))
      .subscribe((data) => {
        this.wod = data.appointment;

        if (data.formData != null) {
          this.initFormWithFormData(data.formData);
          return;
        }

        if (data.appointment != null) {
          this.initFormWithAppointment(data.appointment);
          return;
        }
      })
  }

  ngOnInit(): void {
    this.loadResources();
  }

  ngAfterViewInit(): void {

    this.sub3 = this.store.select(detailData)
      .pipe(filter(it => it.appointment != null || it.formData != null))
      .subscribe((data) => {
        this.wod = data.appointment;
        if (this.wod.publiclyVisible) {
          this.timelineTabs.selectedIndex = 1;
        }
        this.sub3?.unsubscribe();
      })
  }

  private loadResources() {
    this.resources$ = this.resourceModuleApi.getResources().pipe(
      map(resources => this.groupBy(resources, resource => resource.resourceGroup?.name)),
    );
  }

  private groupBy(list, keyGetter) {
    const map = new Map();
    list.forEach((item) => {
      let key = keyGetter(item);
      if (key == undefined)
        key = 'ohne Gruppe';
      const collection = map.get(key);
      if (!collection) {
        map.set(key, [item]);
      } else {
        collection.push(item);
      }
    });

    return new Map([...map.entries()].sort());
  }

  ngOnDestroy(): void {
    this.sub1?.unsubscribe();
    this.sub2?.unsubscribe();
    this.sub3?.unsubscribe();
  }

  private initFormWithAppointment(wod: Wod) {
    console.log("init with appointment: ", wod);
    this.sub1?.unsubscribe();

    this.weekday = this.wod.weekday;
    this.formGroup.get("start").setValue(moment(wod.start).format('DD.MM.YYYY HH:mm'), {emitEvent: false});
    this.formGroup.get("name").setValue(wod.name, {emitEvent: false});
    this.formGroup.get("max_members").setValue(wod.maxMembers, {emitEvent: false});
    this.formGroup.get("accessCheck").setValue(wod.accessCheck, {emitEvent: false});
    this.formGroup.get("durationInMinutes").setValue(wod.duration_minutes, {emitEvent: false});
    this.formGroup.get('type').setValue(wod.type, {emitEvent: false});
    if (this.wod.hasId())
      this.formGroup.get('type').disable({emitEvent: false});
    this.formGroup.get('description').setValue(wod.description, {emitEvent: false});
    this.formGroup.get("publiclyVisible").setValue(wod.publiclyVisible, {emitEvent: false});
    this.formGroup.get("promotion").setValue(wod.promotion, {emitEvent: false});
    this.formGroup.get("informationalOnly").setValue(wod.informationalOnly, {emitEvent: false});

    //Buchungsbedingungen für Mitglieder
    this.formGroup.get("subscribeCondition").setValue({
      subscribeCondition: wod.subscribe_condition,
      subscribeConfirmationDays: wod.subscribe_confirmation_days
    }, {emitEvent: false});
    this.formGroup.get("subscription_opening").setValue(wod.subscription_opening, {emitEvent: false});
    this.formGroup.get("subscription_closing").setValue(wod.subscription_closing, {emitEvent: false});
    this.formGroup.get("unsubscription_closing").setValue(wod.unsubscription_closing, {emitEvent: false});

    //Buchungsbedingungen für öffetnliche
    this.formGroup.get("publicSubscribeCondition").setValue({
      subscribeCondition: wod.publicSubscription.subscribeCondition,
      subscribeConfirmationDays: wod.publicSubscription.subscribeConfirmationDays
    }, {emitEvent: false});
    this.formGroup.get("publicSubscriptionOpening").setValue(wod.publicSubscription.subscriptionOpening, {emitEvent: false});
    this.formGroup.get("publicSubscriptionClosing").setValue(wod.publicSubscription.subscriptionClosing, {emitEvent: false});
    this.formGroup.get("publicUnsubscriptionClosing").setValue(wod.publicSubscription.unsubscriptionClosing, {emitEvent: false});

    this.formGroup.get("resources").setValue(wod.resources.map(it => it.id), {emitEvent: false});


    this.subscribeFormularChanges();
  }

  private initFormWithFormData(data: any) {
    // console.log(data);
    this.sub1?.unsubscribe();
    Object.keys(this.formGroup.controls).forEach((it: string) =>
      this.formGroup.get(it).setValue(data[it], {emitEvent: false})
    );
    this.subscribeFormularChanges();
  }

  private subscribeFormularChanges() {
    if (!this.sub1 || this.sub1.closed) {
      this.sub1 = this.formGroup.valueChanges.pipe(debounceTime(500)).subscribe(() => {
          this.store.dispatch(AppointmentActions.appointmentDataChanged({formData: {...this.formGroup.value, type: this.formGroup.get('type').value}}));
        }
      )
    }
  }

  save(): void {
    if (!this.validatePrivatePublicConditions()) {
      return;
    }

    //create
    if (!this.wod.hasId()) {
      this.create();
      return;
    }

    //update
    if (this.wod.type == "FIXED")
      this._updateFixed();
    else
      this._updateSerie();
  }

  private create() {
    this.api.addTimeslot(this.buildAddWodDto()).subscribe(
      (id) => {
        this.eventStore.trigger(new TimeslotModified());
        this.router.navigate(["/appointment", id]);
      },
      error => {
        this.store.dispatch(AppApiActions.saveNewAppointmentError({error: new ApiError(error)}));
      }
    )
  }

  private _updateFixed() {
    this.dialog.open(AppointmentUpdateFixedComponent, {width: '300px'}).afterClosed().subscribe(
      (result) => {
        if (!result.update)
          return;

        this.store.dispatch(AppointmentActions.saveFixedAppointment({id: this.wod.id, dto: this.buildAddWodDto()}));
      })
  }

  private _updateSerie() {
    this.dialog.open(AppointmentUpdateSerieComponent, {width: '350px'}).afterClosed().subscribe(
      (result) => {
        if (!result.update)
          return;

        switch (result.mode) {
          case 'single':
            this.store.dispatch(AppointmentActions.saveFixedAppointment({id: this.wod.id, dto: this.buildAddWodDto()}));
            break;
          case 'all':
            this.store.dispatch(AppointmentActions.saveSeriesAppointment({id: this.wod.id, dto: this.buildAddWodDto()}));
            break;
          default:
        }
      });
  }

  private buildAddWodDto(): AddWodDto {
    let start = moment(this.formGroup.value.start, 'DD.MM.YYYY HH:mm')

    let result = new AddWodDto();
    result.name = this.formGroup.value.name;
    result.maxMembers = this.formGroup.value.max_members;
    result.type = (this.wod.hasId()) ? this.wod.type : this.formGroup.value.type;
    result.start = start.format();
    result.durationInMinutes = this.formGroup.value.durationInMinutes;
    result.subscriptionOpening = this.formGroup.value.subscription_opening;
    result.subscriptionClosing = this.formGroup.value.subscription_closing;
    result.unsubscriptionClosing = this.formGroup.value.unsubscription_closing;
    result.description = this.formGroup.value.description;
    result.subscriptionCondition = this.formGroup.value.subscribeCondition.subscribeCondition;
    result.subscribeConfirmationDays = this.formGroup.value.subscribeCondition.subscribeConfirmationDays;
    result.publiclyVisible = this.formGroup.value.publiclyVisible;
    result.promotion = this.formGroup.value.promotion;
    result.informationalOnly = this.formGroup.value.informationalOnly;
    result.publicSubscribeCondition = this.formGroup.value.publicSubscribeCondition.subscribeCondition;
    result.publicSubscribeConfirmationDays = this.formGroup.value.publicSubscribeCondition.subscribeConfirmationDays;
    result.publicSubscriptionOpening = this.formGroup.value.publicSubscriptionOpening;
    result.publicSubscriptionClosing = this.formGroup.value.publicSubscriptionClosing;
    result.publicUnsubscriptionClosing = this.formGroup.value.publicUnsubscriptionClosing;
    result.resources = this.formGroup.value.resources;
    result.accessCheck = this.formGroup.value.accessCheck;
    return result;
  }

  cancel() {
    this.dialog.open(AppointmentCancelComponent, {width: '250px'});
  }

  copy() {
    this.appointmentModuleApi.copyWod(this.wod);
  }

  delete(): void {
    if (this.wod.type == "FIXED")
      this.deleteFixed();
    else
      this.deleteSerie();
  }

  private deleteFixed() {
    let deleteDialogRef = this.dialog.open(AppointmentDeleteComponent, {
      width: '250px',
      data: {wod: this.wod}
    });

    deleteDialogRef.afterClosed().subscribe(result => {
      if (result.deleted == true)
        this.location.back();
    });
  }

  private deleteSerie() {
    let deleteDialogRef = this.dialog.open(AppointmentSeriesDeleteComponent, {
      width: '350px',
      data: {wod: this.wod}
    });

    deleteDialogRef.afterClosed().subscribe(result => {
      if (result.deleted == true)
        this.location.back();
    });
  }

  /**
   * pürft die Buchungsbedingungen zwischen privaten und öffentlichen Buchungen
   * @see https://youtrack.patrix.biz/issue/ZF-957
   * @private
   */
  private validatePrivatePublicConditions(): boolean {
    let privateSubCondition = this.formGroup.value.subscribeCondition.subscribeCondition;
    let publicSubCondition = this.formGroup.value.publicSubscribeCondition.subscribeCondition;
    if (publicSubCondition == 'OPEN_TO_ALL' && privateSubCondition != 'OPEN_TO_CUSTOMERS') {
      this.matSnackbar.open(
        'Wenn ein Termin öffentlich eine "freie Veranstaltung" ist, muss dieser auch für die Mitglieder eine "freie Veranstaltung" sein.',
        "OK"
      );
      return false;
    }

    return true;
  }

  createResource(resourceName: string) {
    this.resourceModuleApi.addResource(resourceName);
  }

  pdfExport(timeslot: Wod) {
    this.appointmentModuleApi.generateParticipantReportForTimeslot(timeslot);
  }

  odsExport(timeslot: Wod) {
    this.appointmentModuleApi.generateOdsParticipantReportForTimeslot(timeslot);
  }
}
