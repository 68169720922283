<div class="section">
  <div class="header">Mitglied anlegen</div>
  <div class="body">
    <form [formGroup]="formGroup" autocomplete="off">
      <div>
        <mat-form-field class="full-width">
          <mat-label>Vorname</mat-label>
          <input id="cl-firstname" matInput formControlName="firstname" autofocus>
        </mat-form-field>
      </div>
      <div>
        <mat-form-field class="full-width">
          <mat-label>Nachname</mat-label>
          <input id="cl-name" matInput formControlName="name">
        </mat-form-field>
      </div>
      <div>
        <mat-form-field class="full-width">
          <mat-label>E-Mail</mat-label>
          <input id="cl-email" matInput formControlName="email">
        </mat-form-field>
      </div>
      <button id="save" mat-stroked-button color="primary" (click)="submit()" [disabled]="!this.formGroup.valid">Mitglied anlegen
      </button>
    </form>
  </div>
</div>
