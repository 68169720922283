import {Component, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {AppointmentsState} from "../../../actions/appointments";
import {Store} from "@ngrx/store";
import {loadAttributeTemplates} from "../../../actions/appointment.actions";
import {attributeTemplates} from "../../../actions/selectors";
import {Observable, Subscription} from "rxjs";
import {AttributeTemplate} from "../../../../services/attribute-template";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {MatTableDataSource} from "@angular/material/table";
import {MatPaginator} from "@angular/material/paginator";
import {MatSort} from "@angular/material/sort";

@Component({
  selector: 'app-template-search',
  templateUrl: './template-search.component.html',
  styleUrls: ['./template-search.component.scss']
})
export class TemplateSearchComponent implements OnInit, OnDestroy {
  templates$: Observable<AttributeTemplate[]>;
  displayedColumns: string[] = ['name', 'type', 'values'];
  dataSource = new MatTableDataSource<AttributeTemplate>();

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  private subscriptions: Subscription[] = [];

  constructor(private store: Store<AppointmentsState>, @Inject(MAT_DIALOG_DATA) public data: any, private dialogRef: MatDialogRef<TemplateSearchComponent>,) {
    this.subscriptions.push(
      this.store.select(attributeTemplates)
        .subscribe(
          (data) => {
            this.dataSource.data = data;
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;
          }
        )
    )
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(it => it.unsubscribe());
  }


  ngOnInit(): void {
    this.store.dispatch(loadAttributeTemplates());
  }

  use(template: AttributeTemplate) {
    this.dialogRef.close(template);
  }

  close() {
    this.dialogRef.close();
  }

  typeLabel(type: string) {
    switch (type) {
      case 'STRING':
        return 'Text';
      case 'DATE':
        return 'Datum';
      case 'BOOLEAN':
        return 'Checkbox';
      case 'ENUM':
        return 'Liste';
    }

    return "";
  }
}
