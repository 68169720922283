import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NgxsModule} from "@ngxs/store";
import {ResourceManagmentRoutingModule} from "./resource-managment-routing.module";
import {AppMaterialModule} from "../material.module";
import {NgxsFormPluginModule} from "@ngxs/form-plugin";
import {ReactiveFormsModule} from "@angular/forms";
import {HomeComponent} from './home/home.component';

import {ResourcesState} from "./resources/state/resources.state";
import {ListComponent} from './resources/list/list.component';
import {EditComponent} from './resources/edit/edit.component';
import {ResourcesApi} from "./services/ResourcesApi";

import {ResourceTypesState} from "./resource-types/state/resource-types.state";
import {ListComponent as TypesListComponent} from './resource-types/list/list.component';
import {EditComponent as TypesEditComponent} from './resource-types/edit/edit.component';
import {ResourceTypesApi} from "./services/ResourceTypesApi";

import {ResourceGroupsState} from "./resource-groups/state/resource-groups.state";
import {ListComponent as GroupsListComponent} from './resource-groups/list/list.component';
import {EditComponent as GroupsEditComponent} from './resource-groups/edit/edit.component';
import {ResourceGroupsApi} from "./services/ResourceGroupsApi";
import {ResourceModuleApi} from "./resource-module-api";


@NgModule({
  declarations: [
    ListComponent, EditComponent,
    TypesListComponent, TypesEditComponent,
    GroupsListComponent, GroupsEditComponent,
    HomeComponent],
  imports: [
    CommonModule,
    ResourceManagmentRoutingModule,
    AppMaterialModule,
    NgxsModule.forFeature([ResourcesState, ResourceTypesState, ResourceGroupsState]),
    NgxsFormPluginModule,
    ReactiveFormsModule,
  ],
  providers: [
    ResourcesApi,
    ResourceTypesApi,
    ResourceGroupsApi,
    ResourceModuleApi,
  ]
})
export class ResourceManagmentModule {

}
