import {Component, ViewChild} from '@angular/core';
import {MatSnackBar} from "@angular/material/snack-bar";
import {Wod} from "../../../services/wod";
import {Participant} from "../../../services/participant";
import {Api} from "../../../services/api";
import {AppointmentsState} from "../../actions/appointments";
import {Store} from "@ngrx/store";
import {currentAppointment} from "../../actions/selectors";
import {AppointmentActions} from "../../actions/action-types";
import {MatTableDataSource} from "@angular/material/table";
import {MatSort} from "@angular/material/sort";
import {filter} from "rxjs/operators";
import {ParticipantAttribute} from "../../../services/participantAttribute";
import {EditParticipantComponent, EditAnonymDialogData} from "../../edit-participant/edit-participant.component";
import {MatDialog} from "@angular/material/dialog";

@Component({
  selector: 'app-wod-detail-participants',
  templateUrl: './appointment-detail-participants.component.html',
  styleUrls: ['./appointment-detail-participants.component.scss']
})
export class AppointmentDetailParticipantsComponent {
  dataSource = new MatTableDataSource<Participant>();
  columns = [];
  displayedColumns = [];

  public appointment: Wod;
  public viewMode: string = 'default';

  @ViewChild(MatSort) set sort(sort: MatSort) {
    this.dataSource.sort = sort;
  };

  constructor(private api: Api, private _snackBar: MatSnackBar, private store: Store<AppointmentsState>,  private dialog: MatDialog) {
    this.store.select(currentAppointment)
      .pipe(filter(it => it != null))
      .subscribe(
        appointment => {

          this.appointment = appointment;
          this.dataSource.data = appointment.participants;
          if (appointment.participants.length > 0) {
            this.defineColumns(appointment.participants[0].attributes);
          } else {
            this.defineColumns([]);
          }
        });
  }

  showParticipants() {
    this.viewMode = 'default';
    this.dataSource.filter = '';
  }

  showCustomers() {
    this.viewMode = 'memberSelection';
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  canShowMemberSubscription(): boolean {
    return true;
  }

  private defineColumns(attributes: ParticipantAttribute[]) {
    this.columns = [
      {
        columnDef: 'name',
        header: 'Name',
        cell: (element: Participant) => `${element.name}`
      },
      {
        columnDef: 'email',
        header: 'E-Mail',
        cell: (element: Participant) => `${element.email}`
      },
      {
        columnDef: 'subscribedOn',
        header: 'gebucht am',
        cell: (element: Participant) => `${element.subscribedOn.format("D.M.YYYY HH:mm")}`
      },
      {
        columnDef: 'bookingReference',
        header: 'Buchungsreferenz',
        cell: (element: Participant) => `${element.bookingReference ?? ''}`
      },
      {
        columnDef: 'bookingCode',
        header: 'Buchungscode',
        cell: (element: Participant) => `${element.bookingCode}`
      },
      {
        columnDef: 'numberOfPersons',
        header: 'Personen',
        cell: (element: Participant) => `${element.numberOfPersons}`
      },
      {
        columnDef: 'note',
        header: 'Notiz',
        cell: (element: Participant) => `${element.note}`
      },
    ];

    attributes.forEach(a => {
      this.columns.push({
        columnDef: `${a.id}`,
        header: a.name,
        cell: (element: Participant) => element.attributes.filter(it => a.id === it.id).pop()?.value
      })
    });

    this.displayedColumns = this.columns.map(c => c.columnDef);
    this.displayedColumns.push("operations");

  }

  unsubscribe(participant: Participant) {
    this.store.dispatch(AppointmentActions.unsubscribeParticipant({appointmentId: this.appointment.id, participant: participant}));
  }

  edit(participant: Participant) {
    let data: EditAnonymDialogData = {participant: participant, timeslot: this.appointment};
    this.dialog.open(EditParticipantComponent, {data: data, disableClose: true});
  }
}
