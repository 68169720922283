import {Injectable} from '@angular/core';
import {Action, Selector, State, StateContext} from '@ngxs/store';
import {ResourcesActions} from "./resources.actions";
import {tap} from "rxjs/operators";
import {Resource} from "../../model/resource";
import {ResourcesApi, ResourceUpate} from "../../services/ResourcesApi";
import {MessageService} from "../../../shared/message.service";
import {Observable} from "rxjs";
import {ResetForm} from "@ngxs/form-plugin";
import {ResourceType} from "../../model/resource-type";
import {ResourceTypesApi} from "../../services/ResourceTypesApi";
import {ResourceGroupsApi} from "../../services/ResourceGroupsApi";
import {ResourceGroup} from "../../model/resource-group";

export interface ResourcesStateModel {
  items: Resource[],
  current_item: Resource,
  editForm: {
    model: {
      name: string
      customerLabel: string
      customerVisible: boolean
      resourceTypeId: string
      resourceGroupId: string
    },
    dirty: boolean,
    status: string,
    errors: any
  }
  resourceTypes: ResourceType[],
  resourceGroups: ResourceGroup[]
}

@State<ResourcesStateModel>({
  name: 'resources',
  defaults: {
    items: [],
    current_item: null,
    editForm: {
      model: {
        name: '',
        customerLabel: '',
        customerVisible: true,
        resourceTypeId: '',
        resourceGroupId: '',
      },
      dirty: false,
      status: '',
      errors: undefined
    },
    resourceTypes: [],
    resourceGroups: [],
  },
})
@Injectable()
export class ResourcesState {
  constructor(private resourcesApi: ResourcesApi,
              private resourceTypesApi: ResourceTypesApi,
              private resoruceGroupsApi: ResourceGroupsApi,
              private messageService: MessageService) {
  }

  @Selector()
  static items(state: ResourcesStateModel): Resource[] {
    return state.items;
  }

  @Selector()
  static resourceTypes(state: ResourcesStateModel): ResourceType[] {
    return state.resourceTypes;
  }

  @Selector()
  static resourceGroups(state: ResourcesStateModel): ResourceGroup[] {
    return state.resourceGroups;
  }

  @Selector()
  static canSave(state: ResourcesStateModel): boolean {
    return state.editForm.dirty && (state.editForm.status == 'VALID');
  }

  @Selector()
  static currentItem(state: ResourcesStateModel): Resource {
    return state.current_item;
  }

  @Action(ResourcesActions.LoadAll)
  loadAll(ctx: StateContext<ResourcesStateModel>) {
    return this.resourcesApi.getResources().pipe(
      tap(
        it => {
          ctx.patchState({items: it})
        },
        error => {
          ctx.patchState({items: []});
          this.messageService.showMessage(error.getDisplayMessage())
        }
      )
    )
  }

  @Action(ResourcesActions.Create)
  create(ctx: StateContext<ResourcesStateModel>) {
    let newItem = new Resource();
    newItem.customerVisible = true;
    ctx.dispatch(new ResetForm({path: "resources.editForm", value: {customerVisible: true}}))
    ctx.patchState({current_item: newItem})
  }

  @Action(ResourcesActions.Edit)
  edit(ctx: StateContext<ResourcesStateModel>, action: ResourcesActions.Edit) {
    return this.resourcesApi.getResourceById(action.id).pipe(
      tap(
        it => {
          ctx.dispatch(new ResetForm({
            path: "resources.editForm", value: {
              name: it.name,
              customerLabel: it.customerLabel,
              customerVisible: it.customerVisible,
              resourceTypeId: it.resourceType?.id + '',
              resourceGroupId: it.resourceGroup?.id + '',
            }
          }));
          ctx.patchState({current_item: it})
        },
        error => {
          ctx.patchState({current_item: null});
          this.messageService.showMessage(error.getDisplayMessage())
        }
      )
    )
  }

  @Action(ResourcesActions.Save)
  save(ctx: StateContext<ResourcesStateModel>) {
    let obs: Observable<any>;
    let apiModel = this.formModelToApiModel(ctx.getState().editForm.model);
    let resourceId = ctx.getState().current_item.id;

    if (resourceId)
      obs = this.resourcesApi.update(resourceId, apiModel);
    else
      obs = this.resourcesApi.create(apiModel);

    return obs.pipe(
      tap(
        () => {
          ctx.dispatch(new ResourcesActions.LoadAll());
          ctx.dispatch(new ResetForm({path: "resources.editForm"}))
          ctx.patchState({current_item: null});
          this.messageService.showMessage("Die Ressource wurde erfolgreich gespeichert.");
        },
        error => {
          this.messageService.showMessage(error.getDisplayMessage())
        }
      )
    )
  }

  @Action(ResourcesActions.CreateDirect)
  createDirect(ctx: StateContext<ResourcesStateModel>, action: ResourcesActions.CreateDirect) {
    let obs: Observable<any>;
    let apiModel = {name: action.name};
    obs = this.resourcesApi.create(apiModel);

    return obs.pipe(
      tap(
        () => {
          this.messageService.showMessage("Die Ressource wurde erfolgreich gespeichert.");
          ctx.dispatch(new ResourcesActions.LoadAll());
        },
        error => {
          this.messageService.showMessage(error.getDisplayMessage())
        }
      )
    )
  }

  @Action(ResourcesActions.Delete)
  delete(ctx: StateContext<ResourcesStateModel>, action: ResourcesActions.Delete): Observable<any> {
    return this.resourcesApi.delete(action.id).pipe(
      tap(
        () => {
          ctx.dispatch(new ResourcesActions.LoadAll());
          ctx.dispatch(new ResetForm({path: "resources.editForm"}))
          ctx.patchState({current_item: null});
          this.messageService.showMessage("Die Ressource wurde erfolgreich gelöscht.");
        },
        error => {
          this.messageService.showMessage(error.getDisplayMessage())
        }
      )
    )
  }

  private formModelToApiModel(model: any): ResourceUpate {
    let result: ResourceUpate = {
      name: model.name,
      customerLabel: model.customerLabel,
      customerVisible: model.customerVisible,
    }

    if (model.resourceTypeId)
      result.resourceTypeId = model.resourceTypeId;

    if (model.resourceGroupId)
      result.resourceGroupId = model.resourceGroupId

    return result;
  }

  @Action(ResourcesActions.LoadResourceTypes)
  loadResourceTypes(ctx: StateContext<ResourcesStateModel>) {
    return this.resourceTypesApi.getAll().pipe(
      tap(
        it => {
          ctx.patchState({resourceTypes: it})
        },
        error => {
          ctx.patchState({items: []});
          this.messageService.showMessage(error.getDisplayMessage())
        }
      )
    )
  }

  @Action(ResourcesActions.LoadResourceGroups)
  loadResourceGroups(ctx: StateContext<ResourcesStateModel>) {
    return this.resoruceGroupsApi.getAll().pipe(
      tap(
        it => {
          ctx.patchState({resourceGroups: it})
        },
        error => {
          ctx.patchState({items: []});
          this.messageService.showMessage(error.getDisplayMessage())
        }
      )
    )
  }
}
