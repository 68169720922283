import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {CustomersRoutingModule} from './customers-routing.module';
import {CustomerListComponent} from "./customer-list/customer-list.component";
import {CustomerDetailComponent} from "./customer-detail/customer-detail.component";
import {CustomerAddComponent} from "./customer-add/customer-add.component";
import {CustomerContractComponent} from "./customer-contract/customer-contract.component";
import {CustomerBookingsComponent} from "./customer-bookings/customer-bookings.component";
import {AppMaterialModule} from "../material.module";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MomentModule} from "ngx-moment";
import {TextMaskModule} from "angular2-text-mask";
import {StoreModule} from "@ngrx/store";
import * as fromCustomers from "./actions/reducers";
import {EffectsModule} from "@ngrx/effects";
import {CustomerEffects} from "./actions/effects";
import {NgxChartsModule} from "@swimlane/ngx-charts";
import {CustomerDataComponent} from './customer-data/customer-data.component';
import {BookPrepaidDialogComponent} from './book-prepaid-dialog/book-prepaid-dialog.component';
import {CustomerActionsComponent} from './customer-actions/customer-actions.component';
import {ChangeNameDialogComponent} from "./change-name-dialog/change-name-dialog.component";
import {ChangeCovidStateComponent} from './change-covid-state/change-covid-state.component';
import {TranslateModule} from "@ngx-translate/core";
import {CustomerLogComponent} from './customer-log/customer-log.component';
import {SkeletonElementsModule} from "skeleton-elements/angular";


@NgModule({
  declarations: [
    CustomerListComponent,
    CustomerAddComponent,
    CustomerDetailComponent,
    CustomerContractComponent,
    CustomerBookingsComponent,
    CustomerDataComponent,
    BookPrepaidDialogComponent,
    CustomerActionsComponent,
    ChangeNameDialogComponent,
    ChangeCovidStateComponent,
    CustomerLogComponent
  ],
  imports: [
    CommonModule,
    CustomersRoutingModule,
    AppMaterialModule,
    ReactiveFormsModule,
    FormsModule,
    MomentModule,
    StoreModule.forFeature(fromCustomers.customersFeatureKey, fromCustomers.reducers),
    EffectsModule.forFeature([CustomerEffects]),
    TextMaskModule,
    NgxChartsModule,
    TranslateModule,
    SkeletonElementsModule,
  ]
})
export class CustomersModule {
}
