<div mat-dialog-content>
  <p>Wiederkehrenden Termin speichern</p>
  <mat-radio-group
    [(ngModel)]="selectedType">
    <mat-radio-button *ngFor="let type of types" [value]="type.value">
      {{type.label}}
    </mat-radio-button>
  </mat-radio-group>
</div>
<div mat-dialog-actions>
  <button mat-stroked-button (click)="onCancelClick()">Abbrechen</button>
  <button mat-stroked-button color="primary" (click)="onUpdateClick()" cdkFocusInitial>Speichern</button>
</div>

