export class AddCustomerDto {
    constructor(
        private firstname: string,
        private name: string,
        private email: string,
        private password: string,
        private note: string) {
    }

    asApiData() {
        return {
            "email": this.email,
            "firstname": this.firstname,
            "name": this.name,
            "note": this.note,
            "password": this.password
        };
    }
}
