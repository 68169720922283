import {ContractType, Customer} from "../../services/customer";
import * as moment from "moment";
import {Moment} from "moment";
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";

export class CustomerContractViewModel {
  public customer: Customer = null;
  formGroup: UntypedFormGroup;

  constructor() {
  }

  setCustomer(customer: Customer) {
    this.customer = customer;
    this.fillContractForm();
  }

  showContract() {
    this.fillContractForm();
  }

  private fillContractForm() {
    if (this.formGroup && this.customer) {
      this.formGroup.patchValue({
        units: this.customer.contractUnits,
        period: this.getContractPeriod(),
        from: this.customer.contractFrom ? this.customer.contractFrom : moment(),
        until: this.customer.contractUntil ? moment(this.customer.contractUntil).format('DD.MM.YYYY') : ""
      });
    }
  }

  private getContractPeriod() {
    switch (this.customer.contractType) {
      case ContractType.CONTINGENT_WEEKLY:
        return "WEEKLY";
      case ContractType.CONTINGENT_MONTHLY:
        return "MONTHLY";
      case ContractType.FLATRATE:
        return "FLATRATE";
      case ContractType.NO_CONTRACT:
      default:
        return "NO_CONTRACT";
    }
  }

  getContractFormValues(): { units: number, period: string, from: Moment, until: Moment } {
    return {
      units: this.formGroup.value.units,
      period: this.formGroup.value.period,
      from: moment(this.formGroup.value.from),
      until: this.formGroup.value.until ? moment(this.formGroup.value.until, 'DD.MM.YYYY') : null
    };
  }

  setFormBuilder(fb: UntypedFormBuilder) {
    this.formGroup = fb.group({
      units: ['', [Validators.required]],
      period: ['', [Validators.required]],
      from: ['', [Validators.required]],
      until: [''],
    });

    this.formGroup.get('period').valueChanges.subscribe(
      x => {
        let units = this.formGroup.get("units");
        (x == 'NO_CONTRACT' || x == 'FLATRATE') ? units.disable() : units.enable();

        let from = this.formGroup.get("from");
        (x == 'NO_CONTRACT') ? from.disable() : from.enable();

        let until = this.formGroup.get("until");
        (x == 'NO_CONTRACT') ? until.disable() : until.enable();
      }
    )

    this.fillContractForm();
  }
}
