import {Injectable} from "@angular/core";
import {Api} from "../services/api";
import {Session} from "../services/session";
import {Router} from "@angular/router";
import {TranslateService} from "@ngx-translate/core";
import {Store} from "@ngrx/store";
import {AppState} from "../actions/reducers";
import {AppActions} from "../actions/action-types";
import {ServiceProvider} from "../services/service-provider";
import {MessageService} from "../shared/message.service";

@Injectable()
export class LoginService {
  constructor(private api: Api, private session: Session,
              private router: Router, private translate: TranslateService,
              private store: Store<AppState>, private messageService: MessageService) {}

  public handleLoginWithToken(token: string) {
    this.session.login(token);

    this.api.getManagedServiceProviders()
      .subscribe(
        response => {
          switch (response.length) {
            case 0:
              this.onInvalidServiceProviderLoad();
              break;
            case 1:
              this.onSuccessfulMandantSelection(response[0]);
              break;
            default:
              this.router.navigate(["/service-provider-select"]);
          }
        },
        () => {
          this.onInvalidServiceProviderLoad();
        }
      )
  }

  private onInvalidServiceProviderLoad() {
    this.store.dispatch(AppActions.logout())
    this.messageService.showMessage("Beim Laden der Mandanten trat ein Fehler auf.");
  }

  private onSuccessfulMandantSelection(serviceProvider: ServiceProvider) {
    this.session.setServiceProvider(serviceProvider);
    this.translate.use(this.session.serviceProviderVariant);
    this.router.navigate(['/appointments'])
  }
}
