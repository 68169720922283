import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AuthGuard} from "../guard/auth.guard";
import {ServiceProviderGuard} from "../guard/service-provider-guard.service";
import {AppointmentDetailComponent} from "./appointment-detail/appointment-detail.component";


const routes: Routes = [
  { path: "", component: AppointmentDetailComponent, canActivate: [AuthGuard, ServiceProviderGuard] },
  { path: "", component: AppointmentDetailComponent, canActivate: [AuthGuard, ServiceProviderGuard] },
  { path: ":serieId/:id", component: AppointmentDetailComponent, canActivate: [AuthGuard, ServiceProviderGuard] },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AppointmentsRoutingModule { }
