import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MatTableDataSource} from "@angular/material/table";
import {MatSort} from "@angular/material/sort";
import {MatPaginator} from "@angular/material/paginator";
import {Select, Store} from "@ngxs/store";
import {ResourcesActions} from "../state/resources.actions";
import {Session} from "../../../services/session";
import {ResourcesState} from "../state/resources.state";
import {Observable, Subscription} from "rxjs";
import {Resource} from "../../model/resource";
import {map} from "rxjs/operators";
import {MatDialog} from "@angular/material/dialog";
import {EditComponent} from "../edit/edit.component";
import {ConfirmDialogComponent, ConfirmDialogModel} from "../../../shared/confirm-dialog/confirm-dialog.component";

class ListItem {
  id: string;
  name: string;
  group: string;
  type: string;
  label: string;
  visible: boolean;

  constructor(resource: Resource) {
    this.id = resource.id;
    this.name = resource.name;
    this.type = resource.resourceType?.name;
    this.group = resource.resourceGroup?.name;
    this.label = resource.customerLabel;
    this.visible = resource.customerVisible;
  }
}

@Component({
  selector: 'resources-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class ListComponent implements OnInit, OnDestroy {
  displayedColumns = ['name', 'label', 'type', 'group', 'customer_visible', 'operations'];
  dataSource = new MatTableDataSource<ListItem>();

  private readonly serviceProvider: string;
  @Select(ResourcesState.items) public items$: Observable<Resource[]>;

  @ViewChild(MatSort) sort;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  private subscriptions: Subscription[] = [];

  constructor(private store: Store, private session: Session, private dialog: MatDialog) {
    this.serviceProvider = this.session.serviceProviderIdentifier;

    this.subscriptions.push(
      this.items$.pipe(
        map((items) => items?.map(item => new ListItem(item)))
      ).subscribe(
        (data) => {
          this.dataSource.data = data;
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
        }
      )
    )
  }

  ngOnInit(): void {
    this.store.dispatch(new ResourcesActions.LoadAll());
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(it => it.unsubscribe());
  }


  addResource() {
    this.store.dispatch(new ResourcesActions.Create());
    this.dialog.open(EditComponent, {width: '350px'});
  }

  edit(resource: Resource) {
    this.store.dispatch(new ResourcesActions.Edit(resource.id));
    this.dialog.open(EditComponent, {width: '350px'});
  }

  delete(resource: Resource) {
    const dialogData = new ConfirmDialogModel('Ressource löschen', 'Willst Du die Resource "' + resource.name + '" löschen?');
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "400px",
      data: dialogData
    });

    this.subscriptions.push(dialogRef.afterClosed().subscribe(dialogResult => {
        if (dialogResult == false)
          return;

        this.store.dispatch(new ResourcesActions.Delete(resource.id));
      })
    );
  }
}
