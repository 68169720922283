import {ZfModule} from "./zf-module";

export class ServiceProvider {
  public id: string;
  public name: string;
  public identifier: string;
  public subtitle: string;
  public website: string;
  public mail: string;
  /**
   * Variante - dient vor allem für das richtige Wording
   * @see https://gitlab.patrix.biz/zeitfrei/terminus-server/-/wikis/Dienstleister-Varianten
   */
  public variant: string;
  public address: Address;
  public phone: Phone;
  public geopos: Geopos;
  public logo: Image;
  public publiclyAvailable: boolean;
  public subscriptionAvailable: boolean;

  public static fromJson(data: any): ServiceProvider {
    let result = new ServiceProvider();

    result.name = data.name;
    result.identifier = data.identifier;
    result.publiclyAvailable = data.publiclyAvailable;
    result.subscriptionAvailable = data.subscriptionAvailable;
    result.variant = data.variant;

    return result;
  }

  public fromJsonDetails(data: any): ServiceProvider {
    this.name = data.name;
    this.subtitle = data.subtitle;
    this.website = data.website;
    this.mail = data.mail;
    this.variant = data.variant;

    if (data.address)
      this.address = Address.fromJson(data.address);
    if (data.phone)
      this.phone = Phone.fromJson(data.phone);
    if (data.geopos)
      this.geopos = Geopos.fromJson(data.geopos);
    if (data.logo)
      this.logo = Image.fromJson(data.logo);

    return this;
  }

  toApiV2Json() {
    return {
      "identifier": this.identifier,
      "name": this.name,
      "publiclyAvailable": this.publiclyAvailable,
      "subscriptionAvailable": this.subscriptionAvailable
    }
  }

  toApiV2JsonDetails() {
    return {
      "name": this.name,
      // "address": {
      //   "country": "string",
      //   "countryReadable": "string",
      //   "line1": "string",
      //   "line2": "string",
      //   "town": "string",
      //   "zip": "string"
      // },
      // "geopos": {
      //   "geoPos": {
      //     "lat": 0,
      //     "lon": 0,
      //     "state": "VALID",
      //     "valid": true
      //   },
      //   "lat": 0,
      //   "lon": 0
      // },
      "mail": this.mail,
      // "nameLong": "string",
      // "phone": {
      //   "country": "string",
      //   "number": "string",
      //   "prefix": "string"
      // },
      "subtitle": this.subtitle,
      "variant": this.variant,
      "website": this.website
    }
  }

  public static hasModule(name: string, modules: ZfModule[]) {
    return modules
      .filter(m => m.module.toLowerCase() == name.toLowerCase() && m.active)
      .length > 0;
  }
}

export class Address {
  public line1: string;
  public zip: string;
  public town: string;
  public country: string;
  public countryReadable: string;

  public static fromJson(data: any): Address {
    let result = new Address();
    result.line1 = data.line1;
    result.zip = data.zip;
    result.town = data.town;
    result.country = data.country;
    result.countryReadable = data.country_readable;
    return result;
  }
}

export class Phone {
  public country: string;
  public normalized: string;
  public readable: string;

  public static fromJson(data: any): Phone {
    let result = new Phone();
    result.country = data?.country;
    result.normalized = data?.normalized;
    result.readable = data?.readable;
    return result;
  }
}

export class Geopos {
  public lat: number;
  public lon: number;

  public static fromJson(data: any): Geopos {
    let result = new Geopos();
    result.lat = data?.lat;
    result.lon = data?.lon;
    return result;
  }
}

export class Image {
  public width: number;
  public height: number;
  public id: string;
  public uri: string;
  public mimeType: string

  public static fromJson(data: any): Image {
    let result = new Image();
    result.width = data?.width;
    result.height = data?.height;
    result.id = data?.id;
    result.mimeType = data?.mime_type;
    result.uri = data?.uri;

    return result;
  }
}

