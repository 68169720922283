<form [formGroup]="form">
  <mat-form-field>
    <mat-label>Name</mat-label>
    <input matInput id="name" [formControl]="name"/>
    <mat-icon matSuffix (click)="search()">search</mat-icon>
  </mat-form-field>
  <mat-form-field>
    <mat-label>Typ</mat-label>
    <mat-select id="type" [formControl]="type">
      <mat-option value="STRING">Text</mat-option>
      <mat-option value="DATE">Datum</mat-option>
      <mat-option value="BOOLEAN">Ja / Nein</mat-option>
      <mat-option value="ENUM">Aufzählung</mat-option>
      <mat-option value="AGREEMENT">Zustimmung (z.B. Datenschutz)</mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field>
    <mat-label>Pflichtfeld</mat-label>
    <mat-select id="mandatory" [formControl]="mandatory">
      <mat-option value="yes">Ja</mat-option>
      <mat-option value="no">Nein</mat-option>
    </mat-select>
  </mat-form-field>
  <mat-chip-list #chipList aria-label="Werte für Liste"
                 cdkDropList
                 cdkDropListOrientation="horizontal"
                 (cdkDropListDropped)="dropValue($event)" [disabled]="valuesDisabled">
    <mat-chip *ngFor="let value of values" [selectable]="selectable" cdkDrag
              [removable]="removable" (removed)="removeValue(value)">
      {{value}}
      <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
    </mat-chip>
    <input placeholder="neuer Wert"
           [matChipInputFor]="chipList"
           [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
           [matChipInputAddOnBlur]="addOnBlur"
           (matChipInputTokenEnd)="addValue($event)">
  </mat-chip-list>

  <button (click)="submit()" mat-stroked-button color="primary" id="submit">Übernehmen</button>
</form>
