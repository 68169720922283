<div class="section">
  <div class="header">Email ändern - Schritt 2 / 2</div>
  <div class="body">
    <div class="actions">
      <button id="submit" mat-stroked-button color="primary" (click)="send()">
        Änderung der E-Mail-Adresse bestätigen
      </button>
    </div>
    <div class="description">
      Nach dem erfolgreichen aktualisieren deiner E-Mail-Adresse loggen wir
      dich automatisch aus.
    </div>
    <a href="/login">zum Login</a>
  </div>
</div>
