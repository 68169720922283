import {ResourceType} from "./resource-type";
import {ResourceGroup} from "./resource-group";

export class Resource {
  name: string;
  customerLabel: string;
  resourceType: ResourceType;
  customerVisible: boolean;
  resourceGroup: ResourceGroup;

  public constructor(public id?: string) {
  }

  public static fromJson(data: any): Resource {
    let result = new Resource();
    result.id = data.id
    result.name = data.name;
    result.customerLabel = data.customerLabel;
    result.customerVisible = !!data.customerVisible;
    if (data.resourceType)
      result.resourceType = ResourceType.fromJson(data.resourceType);
    if (data.resourceGroup)
      result.resourceGroup = ResourceGroup.fromJson(data.resourceGroup);
    return result;
  }
}

