<div class="navigation">
  <div (click)="activateSection('resources')" [class.selected]="section=='resources'">Ressourcen</div>
  <div (click)="activateSection('resourcetypes')" [class.selected]="section=='resourcetypes'">Typen</div>
  <div (click)="activateSection('resourcegroups')" [class.selected]="section=='resourcegroups'">Gruppen</div>
</div>

<div class="content">
  <resources-list *ngIf="section == 'resources'"></resources-list>
  <resource-types-list *ngIf="section == 'resourcetypes'"></resource-types-list>
  <resource-groups-list *ngIf="section == 'resourcegroups'"></resource-groups-list>
</div>



