import {Injectable} from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {catchError, map, mergeMap, tap} from "rxjs/operators";
import {EMPTY} from "rxjs";
import {Api} from "../services/api";
import {ApiActions, AppActions} from "./action-types";
import {Session} from "../services/session";
import {Router} from "@angular/router";

@Injectable()
export class AppEffects {
  constructor(
    private actions$: Actions,
    private api: Api,
    private session: Session,
    private router: Router,
  ) {
  }

  appLogout = createEffect(() => this.actions$.pipe(
    ofType(AppActions.logout),
    mergeMap(() =>
      this.api.logout()
        .pipe(
          map(() => ApiActions.logoutSuccess()),
          catchError(() => EMPTY)
        )
    )),
  );

  apiSessionErrors = createEffect(() => this.actions$.pipe(
    ofType(
      ApiActions.invalidSession,
      ApiActions.unauthorizedOperation,
      ApiActions.serviceProviderNotFound
    ),
    tap(() => {
      this.session.logout();
      this.router.navigate(["/login"]);
    })
  ), {dispatch: false});

  sessionLogout = createEffect(() => this.actions$.pipe(
    ofType(ApiActions.logoutSuccess),
    tap(() => this.session.logout())
  ), {dispatch: false});
}
