import {Component} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {ActivatedRoute, Router} from "@angular/router";
import {Session} from "../services/session";
import {MatSnackBar} from "@angular/material/snack-bar";
import {filter, map} from "rxjs/operators";
import {ApiError} from "../services/error";
import {Api} from "../services/api";
import {AppState} from "../actions/reducers";
import {Store} from "@ngrx/store";
import {AppActions} from "../actions/action-types";

@Component({
  selector: 'app-password-change',
  templateUrl: './password-change.component.html',
  styleUrls: ['./password-change.component.scss']
})
export class PasswordChangeComponent {
  form: UntypedFormGroup;
  token: string;

  private formData: { password: string };
  constructor(fb: UntypedFormBuilder, private _router: Router, private session: Session, private api: Api,
              private _snackBar: MatSnackBar, private _route: ActivatedRoute, private store: Store<AppState>) {
    this.form = fb.group(
      {
        password: ['', [Validators.required]]
      }
    )

    this.form.valueChanges
      .pipe(
        filter(() => this.form.valid && this.token != ''),
        map(value => {
          return {password: value.password}
        }),
      )
      .subscribe(
        formData => {
          this.formData = formData;
        }
      );

    this._route.paramMap.subscribe(params => {
      this.token = params.get('token');
    });
  }

  submit() {
    this.api.changePasswordWithToken(this.formData.password, this.token)
      .subscribe(
        () => {
          this.onSuccess();
        },
        (error: ApiError) => {
          this.onFailure(error);
        }
      );
  }

  onSuccess() {
    this._snackBar.open("Dein Passwort wurde erfolgreich geändert.", "OK", {duration: 8000});
    this._router.navigate(["/login"]);
  }

  onFailure(error: ApiError) {
    this.store.dispatch(AppActions.logout());
    this._snackBar.open(error.getDisplayMessage(), "OK", {duration: 8000});
  }
}
