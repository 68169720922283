<h2 mat-dialog-title>Resource bearbeiten</h2>

<mat-dialog-content>
  <form [formGroup]="editForm" ngxsForm="resources.editForm" (ngSubmit)="onSubmit()">
    <mat-form-field>
      <mat-label>Name</mat-label>
      <input matInput type="text" formControlName="name" placeholder="Name der Resource" cdkFocusInitial required/>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Anzeigenname für Kunden</mat-label>
      <input matInput type="text" formControlName="customerLabel" placeholder=""/>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Typ</mat-label>
      <mat-select formControlName="resourceTypeId">
        <mat-option *ngFor="let resourceType of (resourceTypes | async)" [value]="resourceType.id+''">{{resourceType.name}}</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Gruppe</mat-label>
      <mat-select formControlName="resourceGroupId">
        <mat-option *ngFor="let resourceGroup of (resourceGroups | async)" [value]="resourceGroup.id+''">{{resourceGroup.name}}</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-slide-toggle formControlName="customerVisible">für Kunden sichtbar</mat-slide-toggle>
  </form>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Abbrechen</button>
  <button mat-raised-button color="primary" (click)="onSubmit()" [disabled]="!(canSave$ | async)">Speichern</button>
</mat-dialog-actions>
