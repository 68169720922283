import {Component, OnInit} from '@angular/core';
import {UntypedFormBuilder} from "@angular/forms";
import {Observable} from "rxjs";
import {Api} from "../../services/api";
import {ActivatedRoute} from "@angular/router";
import {MAT_DATE_FORMATS} from "@angular/material/core";
import {select, Store} from "@ngrx/store";
import {CustomerContractViewModel} from "./customer-contract-view-model";
import {currentCustomer, customerContractViewModel} from "../actions/selectors";
import * as ContractActions from "../actions/customer-contract-page.actions";
import {CustomersState} from "../actions/reducers";
import {Customer} from "../../services/customer";


export const MY_DATE_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'DD.MM.YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'DD.MM.YYYY',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-customer-contract',
  templateUrl: './customer-contract.component.html',
  styleUrls: ['./customer-contract.component.scss'],
  providers: [
    {provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS},
  ],
})
export class CustomerContractComponent implements OnInit {
  public viewModel$: Observable<CustomerContractViewModel>;
  private _vm: CustomerContractViewModel;
  untilMask = [/\d/, /\d/, '.', /\d/, /\d/, '.', /\d/, /\d/, /\d/, /\d/]
  customer$: Observable<Customer>;


  constructor(private api: Api, route: ActivatedRoute, private fb: UntypedFormBuilder, private store: Store<CustomersState>) {
    this.viewModel$ = store.select(customerContractViewModel);
    this.customer$ = store.select(currentCustomer);
  }

  ngOnInit(): void {
    this.store.pipe(select(customerContractViewModel)).subscribe(
      vm => {
        this._vm = vm;
        this._vm.setFormBuilder(this.fb)
      }
    );
  }

  submitContract() {
    let formValues = this._vm.getContractFormValues();
    let customerId = this._vm.customer.id;
    switch (formValues.period) {
      case "WEEKLY":
        this.store.dispatch(ContractActions.bookWeekly({
          customerId: customerId,
          units: formValues.units,
          from: formValues.from,
          until: formValues.until
        }));
        break;
      case "MONTHLY":
        this.store.dispatch(ContractActions.bookMonthly({
          customerId: customerId,
          units: formValues.units,
          from: formValues.from,
          until: formValues.until
        }));
        break;
      case "FLATRATE":
        this.store.dispatch(ContractActions.bookFlatrate({
          customerId: customerId,
          from: formValues.from,
          until: formValues.until
        }));
        break;
      case "NO_CONTRACT":
        this.store.dispatch(ContractActions.removeContract({customerId: customerId}));
        break;
    }

    this._vm.showContract();
  }
}
