<h2 mat-dialog-title>Gastbuchung</h2>
<mat-dialog-content class="mat-typography">
  <form>
    <div>
      <div>
        freie Plätze: <span [class.alert]="freeSeats <= 0">{{freeSeats}}</span>
      </div>
      <mat-form-field >
        <mat-label>Anzahl Personen</mat-label>
        <input type="number" matInput [formControl]="numberOfPersons">
      </mat-form-field>
    </div>

    <div>
      <mat-form-field >
        <mat-label>Vorname</mat-label>
        <input type="text" matInput [formControl]="firstname" autofocus>
      </mat-form-field>
    </div>
    <div>
      <mat-form-field >
        <mat-label>Nachname</mat-label>
        <input type="text" matInput [formControl]="lastname">
      </mat-form-field>
    </div>
    <div>
      <mat-form-field >
        <mat-label>Notiz</mat-label>
        <input type="text" matInput [formControl]="note" placeholder="Email, Telefonnummer o.ä.">
      </mat-form-field>
    </div>
  </form>

  <div *ngIf="bookingCode$ | async as bookingCode">
      Buchungscode: <span class="bookingCode">{{bookingCode}}</span>
  </div>

</mat-dialog-content>
<mat-dialog-actions>
    <button mat-stroked-button mat-dialog-close [disabled]="isCancelDisabled">{{cancelButtonText}}</button>
    <button mat-stroked-button (click)="add()" color="warn" [disabled]="isBookingDisabled">Buchen</button>
</mat-dialog-actions>
