import { createAction, props } from '@ngrx/store';


export const loadCustomer = createAction('[Customer Detail] load Customer', props<{customerId: string}>());

export const setInstructor = createAction('[Customer Data] set instructor state', props<{customerId: string}>());
export const unsetInstructor = createAction('[Customer Data] unset instructor state', props<{customerId: string}>());
export const bookPrepaidUnits = createAction('[Customer Data] book prepaid units', props<{customerId: string, units: number}>());
export const deleteCustomer = createAction('[Customer Actions] delete customer', props<{customerId: string}>());
export const sendResetPasswordMail = createAction('[Customer Actions] send reset password mail to customer', props<{customerId: string}>());
