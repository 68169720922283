import {Component, OnInit, ViewChild} from '@angular/core';
import {Store} from "@ngrx/store";
import {AppointmentsState} from "../../../actions/appointments";
import {MatTableDataSource} from "@angular/material/table";
import {MatSort} from "@angular/material/sort";
import {MatPaginator} from "@angular/material/paginator";
import {currentAttributes} from "../../../actions/selectors";
import {map} from "rxjs/operators";
import {AppointmentAttribute} from "../../../../services/appointmentAttribute";
import {removeAppointmentAttribute} from "../../../actions/appointment.actions";
import {ConfirmDialogComponent, ConfirmDialogModel} from "../../../../shared/confirm-dialog/confirm-dialog.component";
import {MatDialog} from "@angular/material/dialog";
import {AttributeAddComponent} from "../attribute-add/attribute-add.component";
import {AppointmentActions} from "../../../actions/action-types";


class ListItem {
  _id: string;
  id: string;
  name: string;
  mandatory: string;
  type: string;
  values: string[];
  attribute: AppointmentAttribute;

  constructor(attribute: AppointmentAttribute) {
    this._id = attribute._id;
    this.id = attribute.id;
    this.name = attribute.name;
    this.mandatory = attribute.mandatory ? 'Ja' : 'Nein';
    this.values = attribute.values;
    this.type = ListItem.typeToText(attribute.type);
    this.attribute = attribute;
  }

  private static typeToText(type: string) : string{
    switch (type.trim().toLowerCase()) {
      case 'boolean':
        return 'Checkbox';
      case 'string':
        return 'Text';
      case 'date':
        return 'Datum';
      case 'enum':
        return 'Liste';
      case 'agreement':
        return 'Zustimmung';
      default:
        return 'unbekannt ('+type+')';
    }
  }

}

@Component({
  selector: 'app-attribute-list',
  templateUrl: './attribute-list.component.html',
  styleUrls: ['./attribute-list.component.scss']
})
export class AttributeListComponent implements OnInit{
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  displayedColumns: string[] = ['name', 'type', 'mandatory', 'values', 'operations'];
  dataSource = new MatTableDataSource();

  constructor(private store: Store<AppointmentsState>, private dialog: MatDialog) {
    this.store.select(currentAttributes)
      .pipe (
        map(it => it.map(i => new ListItem(new AppointmentAttribute(i)))),
      )
      .subscribe(data => {
        this.dataSource.data = data;
      });
  }
  ngOnInit() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  applyFilter(value: any) {
    this.dataSource.filter = value;
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  edit(element: ListItem) {
    const dialogRef = this.dialog.open(AttributeAddComponent, {data: {attribute: element.attribute}});
    dialogRef.afterClosed().subscribe(result => {
      if (result !== undefined)
        this.store.dispatch(AppointmentActions.updateAppointmentAttribute({attribute: result}))
    });
  }

  remove(element: ListItem) {
    const dialogData = new ConfirmDialogModel("Attribut löschen", "Willst Du das Attribut "+element.name+" löschen?");
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "400px",
      data: dialogData
    });

    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult == false)
        return;

      this.store.dispatch(removeAppointmentAttribute({_id: element._id}));
    });
  }
}
