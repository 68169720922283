export class AttributeTemplate {
  public name: string;
  public type: string;
  public values: string[];
  public frequency: string;

  static fromJson(data: any) {
    let result = new AttributeTemplate();
    result.name = data.name;
    result.type = data.type;
    result.values = data.values;
    result.frequency = data.frequency;

    return result;
  }
}
