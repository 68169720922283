export class ResourceGroup {
  public id: string;
  public name: string;

  public static fromJson(data: any) {
    let result = new ResourceGroup();
    result.id = data.id
    result.name = data.name;
    return result;
  }

}
