import {Moment} from "moment";
import * as moment from 'moment';
import {ParticipantAttribute} from "./participantAttribute";

export class Participant {
  public email: string;
  public name: string;
  public firstname: string;
  public lastname: string;
  public subscribedOn: Moment;
  public userId: string;
  public note: string;
  public numberOfPersons: number;
  public bookingCode: string;
  public bookingReference: string;
  public attributes: ParticipantAttribute[];
  /**
   * NON_USER_GUEST: kein Nutzer, Gastbuchung durch Admin
   * GUEST_USER: Nutzer ohne Kundenbeziehung
   * CUSTOMER: Nutzer mit Kundenbeziehung
   */
  public userState: 'GUEST_USER' | 'NON_USER_GUEST' | 'CUSTOMER';

  public static fromJson(data: any): Participant {
    let result = new Participant();
    result.email = data.email??'';
    result.name = data.name;
    result.firstname = data.firstname;
    result.lastname = data.lastname;
    result.subscribedOn = moment(data.subscribedOn);
    result.userId = data.userId;
    result.note = data.note;
    result.numberOfPersons = data.numberOfPersons;
    result.bookingCode = data.bookingCode;
    result.bookingReference = data.bookingReference;
    result.attributes = data.attributes?.map(a => ParticipantAttribute.fromJson(a));
    result.userState = data.userState;

    return result;
  }

}
