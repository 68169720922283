<h1 mat-dialog-title>Kundendaten</h1>
<div mat-dialog-content>
  <div>
    <mat-form-field>
      <mat-label>Vorname</mat-label>
      <input matInput [(ngModel)]="data.firstname">
    </mat-form-field>
  </div>
  <div>
    <mat-form-field>
      <mat-label>Nachname</mat-label>
      <input matInput [(ngModel)]="data.name">
    </mat-form-field>
  </div>
  <div>
    <mat-form-field>
      <mat-label>Anmerkung</mat-label>
      <textarea matInput rows="5" [(ngModel)]="data.note"></textarea>
    </mat-form-field>
  </div>
</div>
<div mat-dialog-actions>
  <button mat-stroked-button (click)="onNoClick()">Abbrechen</button>
  <button mat-stroked-button color="primary" [mat-dialog-close]="data" cdkFocusInitial>Speichern</button>
</div>
