<h1 class="header mat-typography">allgemeine Daten</h1>

<div class="actions">
  <ng-container *ngIf="wod.hasId()">
    <app-add-anonym-button [timeslot]="wod"></app-add-anonym-button>
    <button [matMenuTriggerFor]="exportMenu" mat-stroked-button >Export</button>
    <mat-menu #exportMenu="matMenu">
      <button mat-menu-item (click)="pdfExport(wod)">PDF</button>
      <button mat-menu-item (click)="odsExport(wod)">Tabelle</button>
    </mat-menu>

    <button (click)="copy()" mat-stroked-button>Kopieren</button>
    <button mat-stroked-button (click)="cancel()" [disabled]="!wod.canCancel()">Absagen</button>
    <button mat-stroked-button (click)="delete()" [disabled]="!wod.canDelete()">Löschen</button>
    <button (click)="save()" mat-stroked-button color="primary" [disabled]="wod.canceled">Speichern</button>
  </ng-container>
  <ng-container *ngIf="!wod.hasId()">
    <button (click)="save()" mat-stroked-button color="primary" [disabled]="wod.canceled">Termin erstellen</button>
  </ng-container>
</div>

<form [formGroup]="formGroup" autocomplete="off" class="main">
  <mat-form-field id="name">
    <mat-label>Name</mat-label>
    <input matInput formControlName="name" autofocus>
  </mat-form-field>

  <mat-form-field id="max_members">
    <mat-label>Teilnehmer</mat-label>
    <input matInput type="number" formControlName="max_members">
  </mat-form-field>

  <mat-form-field id="accessCheck">
    <mat-label>Zugangsbeschränkung</mat-label>
    <mat-select formControlName="accessCheck">
      <mat-option value="NONE">Ohne Einschränkung</mat-option>
      <mat-option value="COVID_3G">3G (geimpft, genesen, getestet)</mat-option>
      <mat-option value="COVID_2G">2G</mat-option>
      <mat-option value="COVID_2G_PLUS">2G-Plus</mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field id="type">
    <mat-label>Art</mat-label>
    <mat-select formControlName="type">
      <mat-option value="FIXED">einmalig</mat-option>
      <mat-option value="WEEKLY">wöchentlich</mat-option>
      <mat-option value="TWO_WEEKLY">14 tägig</mat-option>
      <mat-option value="FIRST_DAY_IN_MONTH">1. {{weekday}} im Monat</mat-option>
      <mat-option value="SECOND_DAY_IN_MONTH">2. {{weekday}} im Monat</mat-option>
      <mat-option value="THIRD_DAY_IN_MONTH">3. {{weekday}} im Monat</mat-option>
      <mat-option value="FOURTH_DAY_IN_MONTH">4. {{weekday}} im Monat</mat-option>
      <mat-option value="LAST_DAY_IN_MONTH">letzter {{weekday}} im Monat</mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field id="start">
    <mat-label>Start</mat-label>
    <input matInput formControlName="start" [textMask]="{mask: startMask, keepCharPositions: true}">
  </mat-form-field>

  <app-period-rta formControlName="durationInMinutes" label="Dauer"></app-period-rta>

  <mat-form-field id="description">
    <mat-label>Beschreibung</mat-label>
    <textarea matInput formControlName="description" rows="6"></textarea>
  </mat-form-field>

  <app-ts-detail-resources [resourceGroups]="resources$ | async" [parent]="formGroup" (createResource)="createResource($event)"></app-ts-detail-resources>

  <div class="subscriptionTimelines part">
    <mat-checkbox formControlName="informationalOnly" #informationalOnly >Veranstaltung ohne Buchungsmöglichkeit <span style="font-style: italic">(z.B. als Information)</span></mat-checkbox><br />
    <mat-checkbox formControlName="publiclyVisible" #publiclyVisible>Öffentlich sichtbar</mat-checkbox><br />
    <mat-checkbox formControlName="promotion">Promotion <span style="font-style: italic">(wird in der App extra angezeigt)</span></mat-checkbox>

    <mat-tab-group *ngIf="!informationalOnly.checked" #timelineTabs animationDuration="0" style="margin-bottom: 12px">
      <mat-tab label="Mitglieder">
        <div class="grid-2">
          <div>Anmeldung öffnet:</div>
          <app-period-editor formControlName="subscription_opening" [rtBomEnabled]="true" [opening]="true"></app-period-editor>

          <div>Anmeldung schließt:</div>
          <app-period-editor formControlName="subscription_closing"></app-period-editor>

          <div>Abmeldung bis:</div>
          <app-period-editor formControlName="unsubscription_closing"></app-period-editor>
        </div>
        <app-subscribe-condition formControlName="subscribeCondition"></app-subscribe-condition>

      </mat-tab>

      <mat-tab label="öffentlich" *ngIf="publiclyVisible.checked">
        <div id="public-subscription" class="grid-2">
          <div>Anmeldung öffnet:</div>
          <app-period-editor formControlName="publicSubscriptionOpening" [rtBomEnabled]="true" [opening] = "true"></app-period-editor>

          <div>Anmeldung schließt:</div>
          <app-period-editor formControlName="publicSubscriptionClosing"></app-period-editor>

          <div>Abmeldung bis:</div>
          <app-period-editor formControlName="publicUnsubscriptionClosing"></app-period-editor>
        </div>
        <app-public-subscribe-condition formControlName="publicSubscribeCondition"></app-public-subscribe-condition>
      </mat-tab>
    </mat-tab-group>

  </div>

</form>
