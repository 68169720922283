export class Period {
  public rtbom: { days: number, hour: number, minute: number } = undefined; //relativ to begin of month
  public rta: { minutes: number } = undefined; //relativ zum start des Termin
  public atEnd: boolean;
  public atStart: boolean;

  public static buildAsAtEnd() {
    let result = new Period();
    result.atEnd = true;
    result.atStart = false;
    result.rtbom = undefined;
    result.rta = undefined;
    return result;
  }

  public static buildAsAtStart() {
    let result = new Period();
    result.atEnd = false;
    result.atStart = true;
    result.rtbom = undefined;
    result.rta = undefined;
    return result;
  }

  public static buildAsRta(minutes: number) {
    if (minutes == 0)
      return Period.buildAsAtStart();

    let result = new Period();
    result.rta = {minutes: minutes};
    result.rtbom = undefined;
    result.atEnd = false;
    result.atStart = false;
    return result;
  }

  public static buildAsRtBom(days: number, hour: number, minute: number) {
    let result = new Period();
    if (hour == undefined || hour < 0 || hour > 23)
      hour = 0;
    if (minute == undefined || minute < 0 || minute > 59)
      minute = 0;
    if (days == undefined || days < 0)
      days = 0;
    result.rtbom = {days: days, hour: hour, minute: minute};
    result.rta = undefined;
    result.atEnd = false;
    result.atStart = false;
    return result;
  }

  isAtEnd() {
    return (this.atEnd == true)
  }

  isAtStart() {
    return (this.atStart == true)
  }

  isRTA() {
    return (this.rta != undefined)
  }

  isRTBOM() {
    return (this.rtbom != undefined)
  }

  /**
   * beste Unit wenn es sich um einen RTA- Wert handelt (day, hour, minute)
   */
  public get unitDisplay() {
    if (this.rta == undefined) {
      return "";
    }

    if (this.rta.minutes == 0) {
      return 'Minuten';
    }

    if ((this.rta.minutes % (60 * 24) == 0)) {
      return (Math.abs(this.rta.minutes / (60 * 24)) == 1) ? 'Tag' : 'Tage';
    }

    if ((this.rta.minutes % (60) == 0)) {
      return (Math.abs(this.rta.minutes / 60) == 1) ? 'Stunde' : 'Stunden';
    }

    return (Math.abs(this.rta.minutes) == 1) ? 'Minute' : 'Minuten';
  }

  public get minutesConverted() {
    if (this.rta == undefined) {
      return -1;
    }

    if (this.rta.minutes == 0) {
      return 0;
    }

    if ((this.rta.minutes % (60 * 24) == 0)) {
      return (this.rta.minutes / (60 * 24))
    }

    if ((this.rta.minutes % (60) == 0)) {
      return this.rta.minutes / 60;
    }

    return this.rta.minutes;
  }
}
