import {Component, forwardRef, Input} from '@angular/core';
import {ControlValueAccessor, UntypedFormBuilder, UntypedFormGroup, NG_VALUE_ACCESSOR, Validators} from "@angular/forms";

@Component({
  selector: 'app-period-rta',
  templateUrl: './period-rta.component.html',
  styleUrls: ['./period-rta.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PeriodRtaComponent),
      multi: true
    }
  ]
})
export class PeriodRtaComponent implements ControlValueAccessor {
  @Input("label") label: string;
  units = [
    {value: 'minute', viewValue: 'Minute(n)'},
    {value: 'hour', viewValue: 'Stunde(n)'},
    {value: 'day', viewValue: 'Tag(e)'}
  ];

  formGroup: UntypedFormGroup;
  propagateChange;

  constructor(private _fb: UntypedFormBuilder) {
    this.formGroup = this._fb.group({
      value: ['', [Validators.required, Validators.min(1)]],
      unit: ['', [Validators.required]],
    })

    this.formGroup.valueChanges
      .subscribe(
        () => {
          if (!this.propagateChange)
            return;

          let value = this.formGroup.get('value').value;
          let unit = this.formGroup.get('unit').value;

          switch (unit) {
            case 'minute':
              this.propagateChange(value);
              break;
            case 'hour':
              this.propagateChange(value * 60);
              break;
            case 'day':
              this.propagateChange(value * 60 * 24);
              break;
          }
        }
      );
  }

  /**
   * method that writes a new value from the form model into the view
   * @param value
   */
  writeValue(value: any): void {

    let valueFF = this.formGroup.get('value');
    let unit = this.formGroup.get('unit');

    if (value == 0) {
      unit.setValue('hour');
      valueFF.setValue(0);
      return;
    }

    if ((value % (60*24) == 0)) {
      unit.setValue('day');
      valueFF.setValue(value / (60*24));
      return;
    }

    if ((value % (60) == 0)) {
      unit.setValue('hour');
      valueFF.setValue(value / 60);
      return;
    }

    unit.setValue('minute');
    valueFF.setValue(value);
  }

  /**
   * informiert das äußere Form über Änderungen
   * @param fn
   */
  registerOnChange(fn) {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
  }

  setDisabledState(isDisabled: boolean) {
    if (isDisabled) {
      this.formGroup.get('value').disable();
      this.formGroup.get('unit').disable();
    } else {
      this.formGroup.get('value').enable();
      this.formGroup.get('unit').enable();
    }
  }

}
