<h1 class="header mat-typography">{{(customer$ | async).displayName}}</h1>

<div class="actions">
  <button mat-stroked-button color="primary" (click)="submitContract()">Speichern</button>
</div>

<div class="main" *ngIf="(customer$ | async) as customer">
  <ng-container *ngIf="(viewModel$ | async) as vm">
    <form [formGroup]="vm.formGroup" autocomplete="off">
      <mat-form-field id="period">
        <mat-label>Vertragsart</mat-label>
        <mat-select formControlName="period" #period autofocus>
          <mat-option value="NO_CONTRACT">kein Vertrag</mat-option>
          <mat-option value="WEEKLY">pro Woche</mat-option>
          <mat-option value="MONTHLY">pro Monat</mat-option>
          <mat-option value="FLATRATE">Flatrate</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field id="name">
        <mat-label>Einheiten</mat-label>
        <input matInput type="number" formControlName="units">
      </mat-form-field>

      <mat-form-field id="until">
        <mat-label>bis</mat-label>
        <input matInput formControlName="until" [textMask]="{mask: untilMask, keepCharPositions: true}">
      </mat-form-field>
    </form>
  </ng-container>
</div>
