import {Component, OnDestroy, OnInit} from '@angular/core';
import {Actions, ofActionSuccessful, Select, Store} from "@ngxs/store";
import {ResourcesState} from "../state/resources.state";
import {Resource} from "../../model/resource";
import {Observable, Subject} from "rxjs";
import {UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms";
import {ResourcesActions} from "../state/resources.actions";
import {takeUntil} from "rxjs/operators";
import {MatDialogRef} from "@angular/material/dialog";
import {ResourceType} from "../../model/resource-type";
import {ResourceGroup} from "../../model/resource-group";


@Component({
  selector: 'resource-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss']
})
export class EditComponent implements OnInit, OnDestroy{
  @Select(ResourcesState.currentItem) public item: Observable<Resource>;
  @Select(ResourcesState.resourceTypes) public resourceTypes: Observable<ResourceType[]>;
  @Select(ResourcesState.resourceGroups) public resourceGroups: Observable<ResourceGroup[]>;
  @Select(ResourcesState.canSave) public canSave$: Observable<boolean>;

  editForm = new UntypedFormGroup({
    name: new UntypedFormControl('', Validators.required),
    customerLabel: new UntypedFormControl(),
    customerVisible: new UntypedFormControl(),
    resourceTypeId: new UntypedFormControl(''),
    resourceGroupId: new UntypedFormControl(),
  });

  private ngUnsubscribe = new Subject();

  constructor(private store: Store, private actions$: Actions, private dialogRef:MatDialogRef<EditComponent>) {
    this.store.dispatch(new ResourcesActions.LoadResourceTypes());
    this.store.dispatch(new ResourcesActions.LoadResourceGroups());
  }


  ngOnInit() {
    this.actions$
      .pipe(
        ofActionSuccessful(ResourcesActions.Save),
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe(() => {this.dialogRef.close()});
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  onSubmit() {
    this.store.dispatch(new ResourcesActions.Save());
  }
}
