<h1 mat-dialog-title>Covid Status ändern</h1>

<div mat-dialog-content>
  <form [formGroup]="fg">
    <div>
      <mat-form-field >
        <mat-label>Status</mat-label>
        <mat-select formControlName="state">
          <mat-option value="">unbekannt</mat-option>
          <mat-option value="VACCINATED_3RD">3. Impfung</mat-option>
          <mat-option value="VACCINATED_2ND">2. Imfpung</mat-option>
          <mat-option value="RECOVERED">genesen</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div>
      <mat-form-field >
        <mat-label>Datum</mat-label>
        <input matInput [matDatepicker]="picker" formControlName="date" [max]="maxDate" >
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>
    </div>

    <div>
        <mat-checkbox formControlName="allowed">
          Der Betroffene hat durch mündliche Zusage seine Einwilligung erklärt,
          dass wir seine Angabe zum COVID-19 3-G-Status (Geimpft, Genesen , Getestet)
          zum Zweck der Überprüfung der gesetzlich vorgeschriebenen Kontrolle von
          Impf-, Genesungs- oder Testnachweisen erheben, verarbeiten und speichern dürfen,
          um zukünftige Kontrollen zu minimieren. Der Betroffene wurde darüber aufgeklärt,
          dass er seine Einwilligung jederzeit durch Erklärung gegenüber dem Dienstleister widerrufen kann.
        </mat-checkbox>
    </div>
  </form>
</div>

<div mat-dialog-actions>
  <button mat-stroked-button (click)="onNoClick()">Abbrechen</button>
  <button mat-stroked-button color="primary" (click)="update()" [disabled]="fg.invalid" cdkFocusInitial>Speichern</button>
</div>
