import {Component, OnDestroy, OnInit} from '@angular/core';
import {Observable, Subscription} from "rxjs";
import {Wod} from "../../services/wod";
import {Api} from "../../services/api";
import {ActivatedRoute, Router} from "@angular/router";
import {Location} from '@angular/common';
import {Store} from "@ngrx/store";
import {AppointmentsState} from "../actions/appointments";
import {AppointmentActions} from "../actions/action-types";
import {currentAppointment, currentAttributes, dirty} from "../actions/selectors";
import {ConfirmDialogComponent, ConfirmDialogModel} from "../../shared/confirm-dialog/confirm-dialog.component";
import {MatDialog} from "@angular/material/dialog";
import {map} from "rxjs/operators";
import {Session} from "../../services/session";

@Component({
  selector: 'app-wod-detail',
  templateUrl: './appointment-detail.component.html',
  styleUrls: ['./appointment-detail.component.scss']
})
export class AppointmentDetailComponent implements OnInit, OnDestroy {
  wod$: Observable<Wod>;
  section: string = "data";
  private readonly id: string;
  private sub2: Subscription;
  attributeSectionIsDirty: boolean;
  dataSectionIsDirty: boolean;
  attributeCount$: Observable<number>;

  constructor(
    private api: Api,
    private route: ActivatedRoute,
    private location: Location,
    private store: Store<AppointmentsState>,
    private dialog: MatDialog,
    private router: Router,
    private session: Session
  ) {
    this.id = this.route.snapshot.paramMap.get('id');
    this.wod$ = this.store.select(currentAppointment);
    this.attributeCount$ = this.store.select(currentAttributes).pipe(map(it => it.length));
    this.sub2 = this.store.select(dirty).subscribe(it => {
      this.attributeSectionIsDirty = it.attributeSectionIsDirty;
      this.dataSectionIsDirty = it.dataSectionIsDirty;
    })
  }

  ngOnInit(): void {
    if (this.id != null) {
      this.store.dispatch(AppointmentActions.loadAppointment({appointmentId: this.id}));
      this.store.dispatch(AppointmentActions.loadAppointmentAttributes({appointmentId: this.id}));
    }

    this.store.dispatch(AppointmentActions.loadCustomers());
  }

  ngOnDestroy(): void {
    this.sub2?.unsubscribe();
  }

  abort() {
    this.router.navigate(["/"]);
  }

  activateSection(section: string) {
    if (this.section.toLowerCase() == 'data' && this.dataSectionIsDirty) {
       this.askForChangeToSection(section);
      return;
    }

    if (this.section.toLowerCase() == 'attributes' && this.attributeSectionIsDirty) {
      this.askForChangeToSection(section);
      return;
    }

    this.section = section;
  }

  private askForChangeToSection(section: string) {
    const dialogData = new ConfirmDialogModel("Hinweis", "Du hast noch ungesicherte Änderungen. Willst Du trotzdem wechseln?");
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "400px",
      data: dialogData
    });

    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult == false)
        return;

      this.section = section
    });
  }

  canShowEnquirersLink(wod: Wod) {
    if (!wod.hasId())
      return false;

    if (wod.subscribe_condition=='CONFIRMATION')
      return true;

    if (wod.publiclyVisible && wod.publicSubscription?.subscribeCondition=='CONFIRMATION')
      return true;
  }

  canShowWaitingList(wod: Wod) {
    if (!wod.id)
      return false;

    if (this.session.serviceProviderVariant == 'museum')
      return false;

    return true;
  }
}
