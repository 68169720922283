<h3 mat-dialog-title>
  {{title}}
</h3>

<div mat-dialog-content>
  <input matInput type="text" [formControl]="control" [placeholder]="placeholder" />
</div>

<div mat-dialog-actions align="end">
  <button mat-button (click)="onDismiss()">Abbrechen</button>
  <button mat-raised-button color="primary" (click)="onConfirm()">Ok</button>
</div>
