import {ActivatedRouteSnapshot, DetachedRouteHandle, RouteReuseStrategy} from '@angular/router';

export class AppRouteReuseStrategy implements RouteReuseStrategy {
  private savedHandles = new Map<string, DetachedRouteHandle>();

  retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle | null {
    return this.savedHandles.get(this.getRouteKey(route));
  }

  shouldAttach(route: ActivatedRouteSnapshot): boolean {
    return this.savedHandles.has(this.getRouteKey(route));
  }

  shouldDetach(route: ActivatedRouteSnapshot): boolean {
    return route.data.reuseComponent;
  }

  store(route: ActivatedRouteSnapshot, handle: DetachedRouteHandle | null): void {
    const key = this.getRouteKey(route);
    this.savedHandles.set(key, handle);
  }

  // Routes are stored as an array of route configs, so we can find any with url property and join them to create the URL for the rotue
  private getRouteKey(route: ActivatedRouteSnapshot): string {
    return route.pathFromRoot.filter(u => u.url).map(u => u.url).join('/');
  }

  shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
    return (future.routeConfig === curr.routeConfig) || future.data.reuseComponent;
  }
}
