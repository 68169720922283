<table>
  <tr *ngFor="let template of templates$ | async">
    <td>{{template.name}}</td>
    <td>{{template.type}}</td>
    <td>{{template.values}}</td>
    <td><button mat-stroked-button (click)="use(template)">Übernehmen</button></td>
  </tr>
</table>

<mat-table [dataSource]="dataSource" matSort class="main mat-elevation-z8">
  <ng-container matColumnDef="name">
    <mat-header-cell *matHeaderCellDef mat-sort-header>Name</mat-header-cell>
    <mat-cell *matCellDef="let element"> {{element.name}}  </mat-cell>
  </ng-container>

  <ng-container matColumnDef="type">
    <mat-header-cell *matHeaderCellDef mat-sort-header>Typ</mat-header-cell>
    <mat-cell *matCellDef="let element"> {{typeLabel(element.type)}}  </mat-cell>
  </ng-container>

  <ng-container matColumnDef="values">
    <mat-header-cell *matHeaderCellDef mat-sort-header>Werte für Liste</mat-header-cell>
    <mat-cell *matCellDef="let element"> {{element.values}}  </mat-cell>
  </ng-container>

  <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
  <mat-row *matRowDef="let row; columns: displayedColumns;" (click)="use(row)"></mat-row>
</mat-table>

<mat-paginator [pageSizeOptions]="[10, 50, 100]"></mat-paginator>


<button (click)="close()" mat-stroked-button color="primary" id="close">Schliessen</button>

