import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MatTableDataSource} from "@angular/material/table";
import {MatSort} from "@angular/material/sort";
import {MatPaginator} from "@angular/material/paginator";
import {Select, Store} from "@ngxs/store";
import {ResourceTypesActions} from "../state/resource-types.actions";
import {Session} from "../../../services/session";
import {ResourceTypesState} from "../state/resource-types.state";
import {Observable, Subscription} from "rxjs";
import {map} from "rxjs/operators";
import {MatDialog} from "@angular/material/dialog";
import {EditComponent} from "../edit/edit.component";
import {ConfirmDialogComponent, ConfirmDialogModel} from "../../../shared/confirm-dialog/confirm-dialog.component";
import {ResourceType} from "../../model/resource-type";

class ListItem {
  id: string;
  name: string;

  constructor(resourceType: ResourceType) {
    this.id = resourceType.id;
    this.name = resourceType.name;
  }
}

@Component({
  selector: 'resource-types-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class ListComponent implements OnInit, OnDestroy {
  displayedColumns = ['name', 'operations'];
  dataSource = new MatTableDataSource<ListItem>();

  private readonly serviceProvider: string;
  @Select(ResourceTypesState.items) public items$: Observable<ResourceType[]>;

  @ViewChild(MatSort) sort;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  private subscriptions: Subscription[] = [];

  constructor(private store: Store, private session: Session, private dialog: MatDialog) {
    this.serviceProvider = this.session.serviceProviderIdentifier;

    this.subscriptions.push(
      this.items$.pipe(
        map((items) => items?.map(item => new ListItem(item)))
      ).subscribe(
        (data) => {
          this.dataSource.data = data;
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
        }
      )
    )
  }

  ngOnInit(): void {
    this.store.dispatch(new ResourceTypesActions.LoadAll());
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(it => it.unsubscribe());
  }


  addResource() {
    this.store.dispatch(new ResourceTypesActions.Create());
    this.dialog.open(EditComponent, {width: '350px'});
  }

  edit(resourceType: ResourceType) {
    this.store.dispatch(new ResourceTypesActions.Edit(resourceType.id));
    this.dialog.open(EditComponent, {width: '350px'});
  }

  delete(resourceType: ResourceType) {
    const dialogData = new ConfirmDialogModel('Typ löschen', 'Willst Du den Typ "' + resourceType.name + '" löschen?');
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "400px",
      data: dialogData
    });

    this.subscriptions.push(dialogRef.afterClosed().subscribe(dialogResult => {
        if (dialogResult == false)
          return;

        this.store.dispatch(new ResourceTypesActions.Delete(resourceType.id));
      })
    );
  }
}
