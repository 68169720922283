<app-top-menu id="header"></app-top-menu>
<app-hintbox id="hintbox"></app-hintbox>

<app-message-display></app-message-display>

<div id="content">
  <router-outlet></router-outlet>
</div>

<div id="footer">
  <span data-id="version">{{version}}</span>
  <a href="mailto:support@zeitfrei.app" data-id="support">support@zeitfrei.app</a>

  <div data-id="legal">
    <a href="/assets/legal/datenschutz.pdf" target="_blank" data-id="datenschutz">Datenschutz</a> |
    <a href="/impressum" data-id="impressum">Impressum</a>
  </div>
</div>
