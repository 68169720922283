import {Injectable} from "@angular/core";
import {HttpClient, HttpHeaders, HttpParams, HttpResponse} from "@angular/common/http";
import {Observable} from "rxjs";
import {catchError, map, mergeMap} from "rxjs/operators";
import {Customer} from "./customer";
import * as moment from "moment";
import {Moment} from "moment";
import {ServiceProvider} from "./service-provider";
import {CustomerSubscription} from "./customerSubscription";
import {Base64} from "./base64";
import {Wod} from "./wod";
import {SubscriptionRequest} from "./subscription-request";
import {AppointmentAttribute} from "./appointmentAttribute";
import {AttributeTemplate} from "./attribute-template";
import {VideoLink} from "./video-link";
import {BaseApi} from "./BaseApi";
import {Session} from "./session";
import {Store} from "@ngrx/store";
import {AppState} from "../actions/reducers";
import {Attachment} from "./attachment";
import {AddWodDto} from "./addWodDto";
import {AddCustomerDto} from "./addCustomerDto";
import {Appointment} from "./appointment";
import {AppointmentApi} from "./appointment-api";
import {ZfModule} from "./zf-module";
import {CustomerContractLog} from "./customer-contract-log";

@Injectable()
export class Api extends BaseApi {
  constructor(protected http: HttpClient, protected session: Session, protected store: Store<AppState>, private appointmentApi: AppointmentApi) {
    super(http, session, store);
  }

  /**
   * log in für einen Nutzer
   * @param username
   * @param password
   * @return Observable mit dem Session Token
   */
  login(username: string, password: string): Observable<string> {
    let encodedStr = Base64.encode(username + ":" + password);
    let headers = new HttpHeaders();
    headers = headers
      .append("Authorization", "Basic " + encodedStr)
      .append("X-Requested-With", "XMLHttpRequest");

    return this.http
      .get<HttpResponse<any>>("/api/wodstock-v1/login?required_role=SP", {headers: headers, observe: "response"})
      .pipe(
        map(response => response.headers.get("X-Auth-Token")),
        catchError(err => this.handleNetworkError(err))
      )
  }

  /**
   * sendet an die E-Mailadresse einen Link zu direkten einloggen
   *
   * @param email
   */
  sendLoginLinkWithEmail(email: string): Observable<any> {
    let url = "/api/v2/email-login/request?portal=admin";
    return this.http
      .post(url, {email: email})
      .pipe(
        catchError(err => this.handleNetworkError(err))
      )
  }

  /**
   * @param token
   * @return token  session token
   */
  verifyEmailLogin(token: string): Observable<string> {
    let url = "/api/v2/email-login/verify";

    let body = {token: token}

    return this.http
      .post<HttpResponse<void>>(url, body, {headers: this.getHeaders(), observe: "response"})
      .pipe(
        catchError(err => this.handleNetworkError(err)),
        map((response: HttpResponse<any>) => response.headers.get("X-Auth-Token"))
      )
  }

  logout(): Observable<HttpResponse<any>> {
    return this.http
      .get<HttpResponse<any>>("/api/logout", {headers: this.getHeaders()})
      .pipe(
        catchError(err => this.handleNetworkError(err))
      )
  }

  /**
   * ermittelt die Dienstleister für die der eingeloggte Nutzer Bearbeiterrechte hat
   */
  getRegisterdServiceProviders(): Observable<ServiceProvider[]> {
    let url = "/api/wodstock-v1/sp-admin/managed-service-providers";
    return this.http
      .get<any[]>(url, {
        headers: this.getHeaders(),
        params: new HttpParams().append("logoWidth", "400")
      })
      .pipe(
        catchError(err => this.handleNetworkError(err)),
        map(response => {
          return response.map(ServiceProvider.fromJson)
        }),
      )
  }

  /**
   * ermittelt die Module für den aktuell ausgewählten Dienstleister
   */
  getModules(): Observable<ZfModule[]> {
    let spId = this.getServiceProviderIdentifier();
    let url = `/api/v2/sp/${spId}/zfmod`;
    return this.http
      .get<ZfModule[]>(url, {headers: this.getHeaders()})
      .pipe(
        catchError(err => this.handleNetworkError(err)),
      )
  }

  /**
   * sendet dem Nutzer eine Mail in der ein Link ist, mit der er sein Passwort zurücksetzen kann
   * @param email
   */
  sendPasswordMail(email: string) {
    let url = "/api/wodstock-v1/password/send-password-mail-admin";
    return this.http
      .post(url, {user_identifier: email})
      .pipe(
        catchError(err => this.handleNetworkError(err))
      )
  }

  /**
   * setzt für den Nutzer ein neues Passwort
   * @param password
   * @param token
   */
  changePasswordWithToken(password: string, token: string) {
    let url = "/api/wodstock-v1/password/change-with-token";
    return this.http
      .put(url, {token: token, new: password})
      .pipe(
        catchError(err => this.handleNetworkError(err))
      )
  }

  getInvitationLink(): Observable<string> {
    let serviceProvider = this.getServiceProviderIdentifier();
    let url = `/api/wodstock-v1/sp-admin/${serviceProvider}/invitation-link`;

    return this.http
      .get<{ url: string }>(url, {headers: this.getHeaders()})
      .pipe(
        map(response => response.url),
        catchError(err => this.handleNetworkError(err)),
      )
  }

  /**
   * lädt die für den aktuellen Nutzer bearbeitbaren ServiceProvider
   */
  getManagedServiceProviders(): Observable<ServiceProvider[]> {
    let url = `/api/wodstock-v1/sp-admin/managed-service-providers`;
    return this.http
      .get<any[]>(url, {headers: this.getHeaders()})
      .pipe(
        map(response => {
          return response.map(ServiceProvider.fromJson)
        }),
        catchError(err => this.handleNetworkError(err)),
      )
  }

  public getTimeslots(from: Moment, to: Moment, resourceId?: string | undefined, sectionId?: string): Observable<Wod[]> {
    let serviceProvider = this.getServiceProviderIdentifier();
    let url = `/api/v2/tyrael/sp/${serviceProvider}/timeslots`;

    let params = new HttpParams();
    params = params.set('from', from.format("YYYY-MM-DD"));
    params = params.set('to', to.format("YYYY-MM-DD"));
    if (resourceId && resourceId != '') {
      params = params.set('resource', resourceId);
    }
    if (sectionId && sectionId != '') {
      params = params.set('section', sectionId);
    }

    return this.http
      .get(url, {headers: this.getHeaders(), params})
      .pipe(
        catchError(err => this.handleNetworkError(err)),
        map(response => {
          return (<any>response).map(Wod.fromJson);
        }),
      )
  }

  public getWorkouts(from: Moment, to: Moment,): Observable<Wod[]> {
    let df = "YYYY-MM-DD";
    let serviceProvider = this.getServiceProviderIdentifier();
    let url = `/api/v2/tyrael/sp/${serviceProvider}/timeslots?from=` + from.format(df) + "&to=" + to.format(df);
    return this.http
      .get(url, {headers: this.getHeaders()})
      .pipe(
        catchError(err => this.handleNetworkError(err)),
        map(response => {
          return (<any>response).map(Wod.fromJson);
        }),
      )
  }

  public getWorkout(id: string): Observable<Wod> {
    let serviceProvider = this.getServiceProviderIdentifier();
    let url = `/api/v2/tyrael/sp/${serviceProvider}/timeslots/${id}`;
    return this.http
      .get(url, {headers: this.getHeaders()})
      .pipe(
        catchError(err => this.handleNetworkError(err)),
        map(response => Wod.fromJson(response))
      )
  }

  loadServiceProvider(identifier: string): Observable<ServiceProvider> {
    let url = `/api/v2/tyrael/sp/${identifier}`;
    console.log("load service provider");
    return this.http
      .get(url, {headers: this.getHeaders()})
      .pipe(
        mergeMap(response => this.loadServiceProviderDetails(ServiceProvider.fromJson(response))),
        catchError(err => this.handleNetworkError(err)),
      )
  }

  updateServiceProvider(serviceProvider: ServiceProvider) {
    let url = `/api/v2/tyrael/sp/${serviceProvider.identifier}`;
    return this.http
      .put(url, serviceProvider.toApiV2Json(), {headers: this.getHeaders()})
      .pipe(
        catchError(err => this.handleNetworkError(err)),
      )
  }

  updateCovid(customerId: string, state: string, date: Moment) {
    let serviceProvider = this.getServiceProviderIdentifier();
    let url = `/api/v2/tyrael/sp/${serviceProvider}/customers/${customerId}/covid`;
    let body = {
      state: state == '' ? null : state,
      date: date == null ? null : date.format("YYYY-MM-DD")
    };

    return this.http
      .put(url, body, {headers: this.getHeaders()})
      .pipe(
        catchError(err => this.handleNetworkError(err)),
      )
  }

  /**
   * aktualsiert den Kunden
   *
   * @param personaId
   * @param customerData
   */
  updateCustomer(personaId: string, customerData: {firstname: string, name: string, note: string}) {
    let serviceProvider = this.getServiceProviderIdentifier();
    let url = `/api/v2/tyrael/sp/${serviceProvider}/customers/${personaId}`;

    let body = {
      "firstname": customerData.firstname,
      "name": customerData.name,
      "note": customerData.note
    };

    return this.http
      .put(url, body, {headers: this.getHeaders()})
      .pipe(
        catchError(err => this.handleNetworkError(err))
      )
  }

  loadServiceProviderDetails(serviceProvider: ServiceProvider): Observable<ServiceProvider> {
    console.log("load service provider details");
    let url = `/api/v2/tyrael/sp/${serviceProvider.identifier}/details`;
    return this.http
      .get(url, {headers: this.getHeaders()})
      .pipe(
        map(response => serviceProvider.fromJsonDetails(response)),
        catchError(err => this.handleNetworkError(err)),
      )
  }

  updateServiceProviderDetails(payload: { identifier: string, name: string, subtitle: string, mail: string, variant: string }) {
    let url = `/api/v2/tyrael/sp/${payload.identifier}/details`;
    let body = {name: payload.name, subtitle: payload.subtitle, mail: payload.mail, variant: payload.variant};
    return this.http
      .put(url, body, {headers: this.getHeaders()})
      .pipe(
        catchError(err => this.handleNetworkError(err)),
      )
  }

  public loadAppointmentAttributes(id: string): Observable<AppointmentAttribute[]> {
    let serviceProvider = this.getServiceProviderIdentifier();
    let url = `/api/wodstock-v1/sp-admin/${serviceProvider}/workout-instances/` + id + "/attributes";
    return this.http
      .get<any[]>(url, {headers: this.getHeaders()})
      .pipe(
        catchError(err => this.handleNetworkError(err)),
        map(response => response.map(AppointmentAttribute.fromJson)),
      )
  }

  public updateAppointmentAttributes(appointmentId: string, attributes: AppointmentAttribute[]): Observable<Object> {
    let serviceProvider = this.getServiceProviderIdentifier();
    let url = `/api/wodstock-v1/sp-admin/${serviceProvider}/workout-instances/` + appointmentId + "/attributes";
    let body = attributes.map(it => it.toJson());
    // let body = '[{"id":0,"mandatory":true,"name":"Spitzname","type":"STRING","values":[]}]';
    return this.http
      .put(url, body, {headers: this.getHeaders()})
      .pipe(
        catchError(err => this.handleNetworkError(err)),
      )
  }


  /**
   * Fügt einen Timeslot hinzu.
   * Ob es sich um eine Serie handelt, wird anhand der Eigenschaft type im AddWodDto entschieden.
   * @see AddWodDto
   * @param data
   * @return Observable<string> mit Instanz-id;
   */
  addTimeslot(data: AddWodDto): Observable<string> {
    let serviceProvider = this.getServiceProviderIdentifier();
    let params = new HttpParams().set('returnInstanceId', 'true');
    let url = `/api/v2/tyrael/sp/${serviceProvider}/timeslots`
    let body = data.asApiData();
    return this.http
      .post(url, body, {headers: this.getHeaders(), observe: 'response', params: params})
      .pipe(
        catchError(err => this.handleNetworkError(err)),
        map(response => response.headers?.get('location')?.split('/')?.pop())
      )
  }



  /**
   * löscht nur einen Timeslot, egal ob es sich um einen FIXED oder einen Slot innerhalb
   * einer Serie handelt
   * @see deleteTimeslots
   * @param id
   */
  deleteTimeslot(id: string): Observable<Object> {
    return this._deleteTimeslot(id, true);
  }

  /**
   * löscht die Instanz und alle folgenden Slots dieser Serie
   * @param id
   */
  deleteTimeslots(id: string): Observable<Object> {
    return this._deleteTimeslot(id, false);
  }

  /**
   * @param id
   * @param changeOnlyThis nur diesen Slot löschen oder auch alle zukünftigen in der Serie
   * @private
   */
  private _deleteTimeslot(id: string, changeOnlyThis: boolean): Observable<Object> {
    let serviceProvider = this.getServiceProviderIdentifier();
    let url = `/api/wodstock-v1/sp-admin/${serviceProvider}/workout-instances/${id}`
    return this.http
      .delete(url, {
        headers: this.getHeaders(),
        params: new HttpParams().append("changeOnlyThis", String(changeOnlyThis))
      })
      .pipe(
        catchError(err => this.handleNetworkError(err))
      )
  }

  cancelTimeslot(id: string) {
    let serviceProvider = this.getServiceProviderIdentifier();
    let url = `/api/wodstock-v1/sp-admin/${serviceProvider}/workout-instances/${id}/cancel`
    return this.http
      .post(url, {}, {headers: this.getHeaders()})
      .pipe(
        catchError(err => this.handleNetworkError(err))
      )
  }

  updateAppointment(appointment: Appointment) : Observable<Appointment> {
    return this.appointmentApi.updateAppointment(appointment);
  }

  deleteAppointment(appointment: Appointment) {
    return this.appointmentApi.deleteAppointment(appointment);
  }

  /**
   * aktualisiert einen einzelnen Slot, auch wenn dieser Bestandteil einer Serie ist
   * um eine Serie zu aktualisieren, siehe updateTimeslots
   *
   * @see updateTimeslots
   * @param id
   * @param data
   */
  updateTimeslot(id: string, data: AddWodDto): Observable<Object> {
    return this._updateSlot(id, data, true);
  }

  /**
   * aktualisiert den Slot und alle folgenden in der Serie.
   * um nur einen Slot zu aktualisieren siehe updateTimeslot
   *
   * @see updateTimeslot
   * @param id
   * @param data
   */
  updateTimeslots(id: string, data: AddWodDto): Observable<Object> {
    return this._updateSlot(id, data, false);
  }

  /**
   * @param id die Id des Slot der aktualisiert werden soll
   * @param dto das Dto mit den Daten
   * @param changeOnlyThis true -> diesen Slot aktualisieren, false: diesen und alle folgenden Slot der Serie aktualisieren
   */
  _updateSlot(id: string, dto: AddWodDto, changeOnlyThis: boolean): Observable<Object> {
    let serviceProvider = this.getServiceProviderIdentifier();
    let url = `/api/v2/tyrael/sp/${serviceProvider}/timeslots/${id}`
    return this.http
      .put(url, dto.asApiData(), {
        headers: this.getHeaders(),
        params: new HttpParams().append("changeOnlyThis", String(changeOnlyThis))
      })
      .pipe(
        catchError(err => this.handleNetworkError(err))
      )
  }

  getCustomers(): Observable<Customer[]> {
    let serviceProvider = this.getServiceProviderIdentifier();
    let url = `/api/v2/tyrael/sp/${serviceProvider}/customers`;
    return this.http
      .get(url, {headers: this.getHeaders()})
      .pipe(
        catchError(err => this.handleNetworkError(err)),
        map(response => {
          return (<any>response).map(Customer.fromJson);
        }),
      )
  }

  getCustomer(id: string): Observable<Customer> {
    let serviceProvider = this.getServiceProviderIdentifier();
    let url = `/api/v2/tyrael/sp/${serviceProvider}/customers/${id}`;
    return this.http
      .get(url, {headers: this.getHeaders()})
      .pipe(
        catchError(err => this.handleNetworkError(err)),
        map(response => Customer.fromJson(response)),
      )
  }

  getBookingsForCustomer(id: string): Observable<CustomerSubscription[]> {
    let serviceProvider = this.getServiceProviderIdentifier();
    let url = `/api/wodstock-v1/sp-admin/${serviceProvider}/customers/${id}/subscriptions`;
    return this.http
      .get<any[]>(url, {headers: this.getHeaders()})
      .pipe(
        catchError(err => this.handleNetworkError(err)),
        map(response => response.map(CustomerSubscription.fromJson)),
      )
  }
  getContractLog(personaId: string) : Observable<CustomerContractLog[]>{
    let serviceProvider = this.getServiceProviderIdentifier();
    let url = `/api/v2/tyrael/sp/${serviceProvider}/customers/${personaId}/contract/log`;
    return this.http
      .get<CustomerContractLog[]>(url, {headers: this.getHeaders()})
      .pipe(
        catchError(err => this.handleNetworkError(err))
      )
  }


  deleteCustomer(personaId: string): Observable<any> {
    let serviceProvider = this.getServiceProviderIdentifier();
    let url = `/api/v2/tyrael/sp/${serviceProvider}/customers/${personaId}`;
    return this.http
      .delete(url, {headers: this.getHeaders()})
      .pipe(
        catchError(err => this.handleNetworkError(err)),
      )
  }

  /**
   * setzt für den Nutzer einen Monats-Vertrag (z.B. 12 mal pro Monat)
   * @param userId
   * @param unitsPerMonth Anzahl Einheiten pro Monat
   * @param contractFrom Ab wann soll der Vertrag laufen
   * @param contractUntil Wie lange soll der Vertrag laufen
   */
  bookMonthlyContingent(userId: string, unitsPerMonth: number, contractFrom: Moment, contractUntil: Moment): Observable<any> {
    let serviceProvider = this.getServiceProviderIdentifier();
    let url = `/api/wodstock-v1/sp-admin/${serviceProvider}/customers/${userId}/contingent-monthly`;
    let body = {
      "contract_from": contractFrom?.format("YYYY-MM-DD"),
      "contract_until": contractUntil?.format("YYYY-MM-DD"),
      "monthly_units": unitsPerMonth
    };
    return this.http
      .put(url, body, {headers: this.getHeaders()})
      .pipe(
        catchError(err => this.handleNetworkError(err))
      )
  }

  /**
   * setzt für den Nutzer einen Wochen-Vertrag (z.B. 3 mal pro Woche)
   * @param userId
   * @param unitsPerWeek Anzahl Einheiten pro Woche
   * @param contractFrom Ab wann soll der Vertrag laufen
   * @param contractUntil Wie lange soll der Vertrag laufen
   */
  bookWeeklyContingent(userId: string, unitsPerWeek: number, contractFrom: Moment, contractUntil: Moment): Observable<any> {
    let serviceProvider = this.getServiceProviderIdentifier();
    let url = `/api/wodstock-v1/sp-admin/${serviceProvider}/customers/${userId}/contingent-weekly`;
    let body = {
      "contract_from": contractFrom?.format("YYYY-MM-DD"),
      "contract_until": contractUntil?.format("YYYY-MM-DD"),
      "weekly_units": unitsPerWeek
    };
    return this.http
      .put(url, body, {headers: this.getHeaders()})
      .pipe(
        catchError(err => this.handleNetworkError(err))
      )
  }

  /**
   * setzt für den Nutzer einen Flatrate-Vertrag
   * @param userId
   * @param contractFrom Ab wann soll der Vertrag laufen
   * @param contractUntil Wie lange soll der Vertrag laufen
   */
  bookFlatrate(userId: string, contractFrom: Moment, contractUntil: Moment): Observable<any> {
    let serviceProvider = this.getServiceProviderIdentifier();
    let url = `/api/wodstock-v1/sp-admin/${serviceProvider}/customers/${userId}/flatrate`;
    let body = {
      "flatrate_from": contractFrom?.format("YYYY-MM-DD"),
      "flatrate_until": contractUntil?.format("YYYY-MM-DD"),
    };
    return this.http
      .put(url, body, {headers: this.getHeaders()})
      .pipe(
        catchError(err => this.handleNetworkError(err))
      )
  }

  /**
   * löscht den Vertrag für einen Nutzer
   * @param userId
   */
  removeContract(userId: string): Observable<any> {
    let serviceProvider = this.getServiceProviderIdentifier();
    let url = `/api/wodstock-v1/sp-admin/${serviceProvider}/customers/${userId}/contract`;
    return this.http
      .delete(url, {headers: this.getHeaders()})
      .pipe(
        catchError(err => this.handleNetworkError(err))
      )
  }

  /**
   * erstellt einen neuen Kunden
   * @param data
   */
  addCustomer(data: AddCustomerDto) {
    let serviceProvider = this.getServiceProviderIdentifier();
    let url = `/api/v2/tyrael/sp/${serviceProvider}/customers`
    let body = data.asApiData();
    return this.http
      .post(url, body, {headers: this.getHeaders()})
      .pipe(
        catchError(err => this.handleNetworkError(err))
      )
  }

  /**
   * bucht Prepaid-Units für den Kunden (z.B. 10er Karte, Freieinheiten, etc...)
   * @param userId
   * @param amount Anzahl der Einheiten für den Nutzer - kann auch ein Wert < 0 sein
   */
  bookPrepaidUnits(userId: string, amount: number): Observable<any> {
    let serviceProvider = this.getServiceProviderIdentifier();
    let url = `/api/wodstock-v1/sp-admin/${serviceProvider}/customers/${userId}/charge`;
    let body = {
      "charge_units": amount
    };
    return this.http
      .put(url, body, {headers: this.getHeaders()})
      .pipe(
        catchError(err => this.handleNetworkError(err))
      )
  }

  subscribeMemberToWod(wodId: string, memberId: string): Observable<any> {
    let serviceProvider = this.getServiceProviderIdentifier();
    let url = `/api/wodstock-v1/sp-admin/${serviceProvider}/workout-instances/${wodId}/subscribe/${memberId}`;
    return this.http
      .post(url, {}, {headers: this.getHeaders()})
      .pipe(
        catchError(err => this.handleNetworkError(err))
      )
  }

  subscribeAnonymToTimeslot(timeslotId: string, firstname: string, lastname: string, note: string, numberOfPersons: number): Observable<any> {
    let serviceProvider = this.getServiceProviderIdentifier();
    let url = `/api/wodstock-v1/sp-admin/${serviceProvider}/workout-instances/${timeslotId}/subscribe`;
    let body = {
      firstname: firstname,
      lastname: lastname,
      note: note,
      numberOfPersons: numberOfPersons
    }
    return this.http
      .post(url, body, {headers: this.getHeaders()})
      .pipe(
        catchError(err => this.handleNetworkError(err))
      )
  }

  updateAnonymSubscription(timeslotId: string, personaId: string, firstname: string, lastname: string, note: string, numberOfPersons: number): Observable<any> {
    let url = `/api/v2/tyrael/timeslot/${timeslotId}/participants/${personaId}`;
    let body = {
      firstname: firstname,
      lastname: lastname,
      note: note,
      numberOfPersons: numberOfPersons
    }

    return this.http
      .put(url, body, {headers: this.getHeaders()})
      .pipe(
        catchError(err => this.handleNetworkError(err))
      )
  }

  updateMemberSubscription(timeslotId: string, personaId: string, note: string, numberOfPersons: number): Observable<any> {
    let url = `/api/v2/tyrael/timeslot/${timeslotId}/participants/${personaId}`;
    let body = {
      note: note,
      numberOfPersons: numberOfPersons
    }

    return this.http
      .put(url, body, {headers: this.getHeaders()})
      .pipe(
        catchError(err => this.handleNetworkError(err))
      )
  }


  unsubscribeMemberFromWod(wodId: string, memberId: string): Observable<any> {
    let serviceProvider = this.getServiceProviderIdentifier();
    let url = `/api/wodstock-v1/sp-admin/${serviceProvider}/workout-instances/${wodId}/unsubscribe/${memberId}`;
    return this.http
      .post(url, {}, {headers: this.getHeaders()})
      .pipe(
        catchError(err => this.handleNetworkError(err))
      )
  }

  rejectSubscription(wodId: string, memberId: string): Observable<any> {
    let serviceProvider = this.getServiceProviderIdentifier();
    let url = `/api/wodstock-v1/sp-admin/${serviceProvider}/workout-instances/${wodId}/reject-subscription/${memberId}`;
    return this.http
      .put(url, {}, {headers: this.getHeaders()})
      .pipe(
        catchError(err => this.handleNetworkError(err))
      )
  }

  /**
   * bestätigt die Anmeldung eines Nutzers zu einem Termin
   * @param wodId
   * @param memberId
   */
  confirmSubscription(wodId: string, memberId: string) {
    let serviceProvider = this.getServiceProviderIdentifier();
    let url = `/api/wodstock-v1/sp-admin/${serviceProvider}/workout-instances/${wodId}/confirm-subscription/${memberId}`;
    return this.http
      .put(url, {}, {headers: this.getHeaders()})
      .pipe(
        catchError(err => this.handleNetworkError(err))
      )
  }

  /**
   * versendet eine Mail mit einem Token an den Nutzer, so dass dieser sein Password ändern kann.
   */
  sendPasswordChangeMail(memberId: string): Observable<void | Object> {
    let serviceProvider = this.getServiceProviderIdentifier();
    let url = `/api/wodstock-v1/sp-admin/${serviceProvider}/customers/${memberId}/send-password-mail`
    return this.http
      .post(url, '', {headers: this.getHeaders()})
      .pipe(
        catchError(err => this.handleNetworkError(err))
      )
  }

  /**
   * macht einen Kunden für den gerade ausgewählten Dienstleister zum Mitarbeiter
   * @param personaId
   */
  markCustomerAsInstructor(personaId: string) {
    let serviceProvider = this.getServiceProviderIdentifier();
    let url = `/api/v2/tyrael/sp/${serviceProvider}/customers/${personaId}`
    return this.http
      .put(url, '{"instructor":"true"}', {headers: this.getHeaders()})
      .pipe(
        catchError(err => this.handleNetworkError(err))
      )
  }

  /**
   * entfernt den Mitarbeiterstatus für einen Kunden und den gerade ausgewählten Dienstleister
   * @param personaId
   */
  removeInstructorStatus(personaId: string) {
    let serviceProvider = this.getServiceProviderIdentifier();
    let url = `/api/v2/tyrael/sp/${serviceProvider}/customers/${personaId}`
    return this.http
      .put(url, '{"instructor":"false"}', {headers: this.getHeaders()})
      .pipe(
        catchError(err => this.handleNetworkError(err))
      )
  }

  /**
   * ermittelt die Buchungsanfragen die für einen Termin noch bestätigt werden müssen
   * @param appointmentId
   */
  public getSubscriptionRequests(appointmentId: string): Observable<SubscriptionRequest[]> {
    let serviceProvider = this.getServiceProviderIdentifier();
    let url = `/api/wodstock-v1/sp-admin/${serviceProvider}/workout-instances/${appointmentId}/subscription-requests`;
    return this.http
      .get(url, {headers: this.getHeaders()})
      .pipe(
        catchError(err => this.handleNetworkError(err)),
        map(response => {
          return (<any>response).map(SubscriptionRequest.fromJson);
        }),
      )
  }

  /**
   * initiert den Prozeß zum ändern der E-Mailadresse
   * @param newEmail
   */
  changeMail(newEmail: string) {
    let url = "/api/wodstock-v1/user/email";
    return this.http
      .put(url, {newEmail: newEmail, admin: true}, {headers: this.getHeaders()})
      .pipe(
        catchError(err => this.handleNetworkError(err))
      )
  }

  /**
   * bestätigt die Änderung der E-Mail-Adresse mit einem Token
   * @param token
   */
  confirmEmailChange(token: string) {
    let url = "/api/wodstock-v1/user/email-confirm-with-token";
    let headers = this.getHeaders();
    return this.http
      .post(url, {token: token}, {headers: headers})
      .pipe(
        catchError(err => this.handleNetworkError(err))
      )
  }

  /**
   * lädt die automatisch ermittelten Vorlagen für die Attribute eines Termin
   */
  getAttributeTemplates(): Observable<AttributeTemplate[]> {
    let serviceProvider = this.getServiceProviderIdentifier();
    let url = `/api/wodstock-v1/sp-admin/${serviceProvider}/ts-attribute-templates`;
    return this.http
      .get(url, {headers: this.getHeaders()})
      .pipe(
        catchError(err => this.handleNetworkError(err)),
        map(response => {
          return (<any>response).map(AttributeTemplate.fromJson);
        }),
      )
  }

  updateVideoLink(videoLink: VideoLink, appointment: Wod) {
    const url = `/api/v2/tyrael/timeslot/${appointment.id}/videolink`;
    return this.http
      .put(url, videoLink.toJson(), {headers: this.getHeaders()})
      .pipe(
        catchError(err => this.handleNetworkError(err)),
      )
  }

  /**
   * ermittelt die Anhänge für einen Timeslot
   */
  getAttachments(timeslotId: string): Observable<Attachment[]> {
    const url = `/api/v2/tyrael/timeslot/${timeslotId}/attachments`;

    return this.http
      .get<Attachment[]>(url, {headers: this.getHeaders()})
      .pipe(
        catchError(err => this.handleNetworkError(err)),
        // map(response => {return response.map(Attachment.fromJson)}),
      )
  }

  addAttachment(file: File, timeslotId: string) {
    const url = `/api/v2/tyrael/timeslot/${timeslotId}/attachments`;

    const data = new FormData();
    data.append("file", file, file.name);
    data.append("name", file.name);

    return this.http
      .post(url, data, {headers: this.getMultipartFormdataHeaders()})
      .pipe(
        catchError(err => this.handleNetworkError(err)),
      )
  }

  /**
   * lädt den Binär-Inhalt des Anhang, nicht die Metadaten
   * @param attachmentId
   */
  getAttachmentContent(attachmentId: string) {
    const url = `/api/v2/timeslot-attachments/${attachmentId}`;

    return this.http
      .get(url, {headers: this.getHeaders(), responseType: 'blob'})
      .pipe(
        catchError(err => this.handleNetworkError(err)),
      )
  }

  deleteAttachment(timeslotId: string, attachmentId: string) {
    const url = `/api/v2/tyrael/timeslot/${timeslotId}/attachments/${attachmentId}`;

    return this.http
      .delete(url, {headers: this.getHeaders()})
      .pipe(
        catchError(err => this.handleNetworkError(err)),
      )
  }

  /**
   * @param date
   * @param grouping DAY | NAME | RESOURCE
   * @param sectionId
   */
  generateParticipantReport(date: Moment, grouping: string, sectionId?: string): any {
    let serviceProvider = this.getServiceProviderIdentifier();
    let dateString = date.format("YYYY-MM-DD");

    let params = new HttpParams();
    params = params.set('grouping', grouping);
    params = params.set('from', dateString);
    params = params.set('to', dateString);
    params = params.set('waitingList', true);
    if (sectionId) {
      params = params.set('section', sectionId);
    }

    let url = `/api/v2/tyrael/sp/${serviceProvider}/participants-report`;
    return this.http
      .get(url, {headers: this.getHeaders(), responseType: "blob", params: params})
      .pipe(
        catchError(err => this.handleNetworkError(err)),
      )
  }

  /**
   * erzeugt einen PDF-Teilnehmerbericht mit Warteliste für einen bestimmten Timeslot (ehemal WOD)
   * @param timeslotId
   */
  generateParticipantReportForTimeslot(timeslotId: string): any {
    let url = `/api/v2/tyrael/timeslot/${timeslotId}/participants-report?&waitingList=true&style=DEFAULT`;
    return this.http
      .get(url, {headers: this.getHeaders(), responseType: "blob"})
      .pipe(
        catchError(err => this.handleNetworkError(err)),
      )
  }

  /**
   * erzeugt einen ODS-Teilnehmerbericht mit Warteliste für einen bestimmten Timeslot (ehemal WOD)
   * @param timeslotId
   */
  generateOdsParticipantReportForTimeslot(timeslotId: string): any {
    let url = `/api/v2/tyrael/timeslot/${timeslotId}/participants-sheet/ods`;
    return this.http
      .get(url, {headers: this.getHeaders(), responseType: "blob"})
      .pipe(
        catchError(err => this.handleNetworkError(err)),
      )
  }

  /**
   * erzeugt einen ODS-Teilnehmerbericht für einen Zeitraum
   * @param start
   * @param end
   * @param sectionId
   */
  generateOdsRangeReport(start: moment.Moment, end: moment.Moment, sectionId?: string) {
    let serviceProvider = this.getServiceProviderIdentifier();
    let startAsString = start.format('YYYY-MM-DD');
    let endAsString = end.format('YYYY-MM-DD');

    let params = new HttpParams();
    params = params.set('grouping', 'NONE');
    params = params.set('from', startAsString);
    params = params.set('to', endAsString);
    if (sectionId) {
      params = params.set('section', sectionId);
    }

    let url = `/api/v2/tyrael/sp/${serviceProvider}/booking-sheet/ods`;
    return this.http
      .get(url, {headers: this.getHeaders(), params, responseType: "blob"})
      .pipe(
        catchError(err => this.handleNetworkError(err)),
      )

  }

  addAppointment(appointment: Appointment): Observable<Appointment> {
    return this.appointmentApi.addAppointment(appointment);
  }

  /**
   * liste der direkten Buchungen
   * @param from
   * @param to
   */
  getAppointments(from: Moment, to: Moment): Observable<Appointment[]> {
    return this.appointmentApi.getAppointments(from, to);
  }

}
