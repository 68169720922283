import * as moment from 'moment';

export class ParticipantAttribute {
  public id: string;
  public name: string;
  public type: string;
  private _value: string;

  public static fromJson(data: any): ParticipantAttribute {
    const result = new ParticipantAttribute();
    result.id = data.id;
    result.name = data.name;
    result.type = data.type;
    result._value = data.value;
    result.type = data.type;
    return result;
  }

  get value(): string {
    if (this.type === 'DATE') {
      const x = moment(this._value);
      if (x.isValid()) {
        return x.format('DD.MM.YYYY');
      }
    }

    if (this.type === 'BOOLEAN') {
      return (this._value === 'true') ? 'Ja' : 'Nein';
    }

    if (this.type === 'AGREEMENT') {
      return (this._value === 'true') ? 'Ja' : 'Nein';
    }

    return this._value;
  }
}
