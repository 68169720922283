import { createAction, props } from '@ngrx/store';
import {Wod} from "../../services/wod";
import {SubscriptionRequest} from "../../services/subscription-request";
import {Participant} from "../../services/participant";
import {Customer} from "../../services/customer";
import {AppointmentAttribute} from "../../services/appointmentAttribute";
import {VideoLink} from "../../services/video-link";
import {AddWodDto} from "../../services/addWodDto";

export const createAppointment = createAction('[AppointmentModuleApi] generate appointment', props<{appointment: Wod}>());

export const loadCustomers = createAction('[AppointmentDetailComponent] load all customers');
export const loadAppointment = createAction('[AppointmentDetailComponent] load appointment', props<{appointmentId: string}>());
export const loadAppointmentAttributes = createAction('[AppointmentDetailComponent] load appointment attributes', props<{appointmentId: string}>());
export const updateCurrentAppointment = createAction('[AppointmentDetailComponent] update appointment', props<{appointment: Wod}>());

export const confirmSubscription = createAction('[AppointmentDetailEnquirersComponent] confirm subscription request', props<{appointmentId: string, subscriptionRequest: SubscriptionRequest}>());
export const rejectSubscription = createAction('[AppointmentDetailEnquirersComponent] refuse subscription request', props<{appointmentId: string, subscriptionRequest: SubscriptionRequest}>());

export const unsubscribeParticipant = createAction('[AppointmentDetailParticipantsComponent] unsubscribe participant from Appointment', props<{appointmentId: string, participant: Participant}>());
export const subscribeUser = createAction('[AppointmentDetailParticipantsComponent] subscribe user to Appointment', props<{appointmentId: string, customer: Customer}>());

export const cancelAppointment = createAction('[AppointmentCancelComponent] cancel appointment', props<{appointment: Wod}>());

export const deleteAppointment = createAction('[AppointmentDeleteComponent] delete appointment', props<{appointment: Wod}>());

export const deleteAppointmentSerie = createAction('[AppointmentSerieDeleteComponent] delete appointment serie', props<{appointment: Wod}>());

export const updateAppointmentAttributes = createAction('[AppointmentDetailAttributeComponent] update appointment attributes', props<{appointmentId: string, attributes: AppointmentAttribute[]}>());
export const addAppointmentAttribute = createAction('[AppointmentDetailAttributeComponent] add appointment attribute', props<{attribute: AppointmentAttribute}>());
export const updateAppointmentAttribute = createAction('[AppointmentDetailAttributeComponent] update appointment attribute', props<{attribute: AppointmentAttribute}>());
export const removeAppointmentAttribute = createAction('[AppointmentDetailAttributeComponent] remove appointment attribute', props<{_id: string}>());

export const appointmentDataChanged = createAction('[AppointmentDetailDataComponent] values in appointment detail data changed', props<{formData: any}>());
export const resetAppointmentData = createAction('[AppointmentDetailDataComponent] reset appointment detail data');
export const loadAttributeTemplates = createAction('[AppointmentDetailDataComponent] load attribute templates');

export const saveFixedAppointment = createAction('[AppointmentUpdateFixedComponent] save fixed appointment data', props<{id: string, dto: AddWodDto}>());
export const saveSeriesAppointment = createAction('[AppointmentUpdateSerieComponent] save serie appointment data', props<{id: string, dto: AddWodDto}>());

export const updateVideoLink = createAction('[AppointmentDetailVideoLink] update video link', props<{videoLink: VideoLink, appointment: Wod}>());

