import {Component, Input, ViewChild} from '@angular/core';
import {Api} from "../../services/api";
import {MatSort} from "@angular/material/sort";
import {MatTableDataSource} from "@angular/material/table";
import {CustomersState} from "../actions/reducers";
import {Store} from "@ngrx/store";
import {currentBookings} from "../actions/selectors";
import {CustomerSubscription} from "../../services/customerSubscription";
import {Customer} from "../../services/customer";
import {map} from "rxjs/operators";
import {Moment} from "moment";
import {MatPaginator} from "@angular/material/paginator";

class ListItem {
  name: string;
  start: Moment;
  end: Moment;

  constructor(subscription: CustomerSubscription) {
    this.name = subscription.name;
    this.start = subscription.start;
    this.end = subscription.end;
  }
}

@Component({
  selector: 'app-customer-bookings',
  templateUrl: './customer-bookings.component.html',
  styleUrls: ['./customer-bookings.component.scss']
})
export class CustomerBookingsComponent {
  @Input() customerId: string
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @Input() customer: Customer;

  displayedColumns: string[] = ['name', 'start', 'end'];
  dataSource = new MatTableDataSource();

  constructor(private api: Api, private store: Store<CustomersState>) {
    this.store.select(currentBookings)
      .pipe(
        map(subscriptions => subscriptions?.map(subscription => new ListItem(subscription))
          .sort((a, b) => a.start.isBefore(b.start) ? 1 : -1)),
      )
      .subscribe(data => {
        this.dataSource.data = data;
      });
  }

  ngOnInit() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  applyFilter(value: any) {
    this.dataSource.filter = value;
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
}
