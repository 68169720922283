import {ApiActions} from '../actions/action-types';
import {throwError} from 'rxjs';
import {ApiError} from './error';
import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import * as Sentry from '@sentry/browser';
import {Session} from './session';
import {Store} from '@ngrx/store';
import {AppState} from '../actions/reducers';

export class BaseApi {
  private static sendSentryEvent(error: HttpErrorResponse) {
    Sentry.setExtra('message', error.message);
    Sentry.setExtra('statusText', error.statusText);
    Sentry.setExtra('status', error.status);
    Sentry.setExtra('header', error.headers);
    Sentry.captureException(new Error(`message: ${error.message}`));
  }

  constructor(protected http: HttpClient, protected session: Session, protected store: Store<AppState>) {
  }

  protected getServiceProviderIdentifier() {
    const result = this.session.serviceProviderIdentifier;
    if (result === '' || result == null) {
      this.store.dispatch(ApiActions.serviceProviderNotFound());
      return throwError(new ApiError('error.serviceprovider.notfound'));
    }
    return result;
  }

  protected getHeaders() {
    let headers = new HttpHeaders();

    headers = headers
      .append('X-Auth-Token', this.session?.getApiToken())
      .append('X-Requested-With', 'XMLHttpRequest')
      .append('Content-Type', 'application/json');

    return headers;
  }

  protected getMultipartFormdataHeaders() {
    let headers = new HttpHeaders();

    headers = headers
      .append('X-Auth-Token', this.session?.getApiToken())
      .append('X-Requested-With', 'XMLHttpRequest');
      // .append('Content-Type', 'multipart/form-data');

    return headers;
  }

  protected handleNetworkError(error: HttpErrorResponse) {
    if (error.status === 401) {
      this.store.dispatch(ApiActions.invalidSession());
      return throwError(new ApiError('session.invalid'));
    }

    if (error.status === 403) {
      this.store.dispatch(ApiActions.unauthorizedOperation());
      return throwError(new ApiError('operation.unauthorized'));
    }

    if (error.headers.has('X-terminus-error')) {
      const code = error.headers.get('X-terminus-error');
      BaseApi.sendSentryEvent(error);
      return throwError(new ApiError(code));
    }

    BaseApi.sendSentryEvent(error);
    return throwError(new ApiError('network.communication.error'));
  }


}
