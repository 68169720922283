import {createAction, props} from '@ngrx/store';
import {ApiError} from "../services/error";
import {AppointmentAttribute} from "../services/appointmentAttribute";
import {Wod} from "../services/wod";
import {AttributeTemplate} from "../services/attribute-template";

export const logoutSuccess = createAction('[Api] logout was successfull');
export const unauthorizedOperation = createAction('[Api] unauthorized operation');
export const invalidSession = createAction('[Api] invalid session');
export const serviceProviderNotFound = createAction('[Api] service provider not found');
export const changePasswordSuccess = createAction('[API] password was changed');
export const changePasswordError = createAction('[API] error at password changing. password was not changed', props<{error: ApiError}>());
export const loadAppointmentAttributesSuccess = createAction("[API] load appointment attributes was successful", props<{attributes: AppointmentAttribute[]}>());
export const loadAppointmentAttributesError = createAction("[API] load appointment attributes has a error", props<{appointmentId: string, error: ApiError}>());
export const updateAppointmentAttributesSuccess = createAction("[API] update appointment attributes was successful");
export const updateAppointmentAttributesError = createAction("[API] update appointment attributes has a error", props<{error: ApiError}>());

export const saveAppointmentSuccess = createAction('[API] save appointment data success', props<{appointmentId: string}>());
export const saveAppointmentError = createAction('[API] save appointment data error', props<{error: ApiError}>());

export const loadAppointmentsSuccess = createAction('[API] load appointments success', props<{appointments: Wod[]}>());
export const loadAppointmentsError = createAction('[API] load appointments error', props<{error: ApiError}>());

export const loadAppointmentError = createAction('[API] load appointment error', props<{error: ApiError}>());

export const saveNewAppointmentError = createAction('[Appointment Detail Data] save new appointment data error', props<{error: ApiError}>());

export const loadAttributeTemplatesSuccess = createAction('[API] load attribute templates success', props<{attributeTemplates: AttributeTemplate[]}>());
export const loadAttributeTemplatesError = createAction('[API] load attribute templates error', props<{error: ApiError}>());

export const updateVideoLinkSuccess = createAction('[API] update videoLink was successfull', props<{appointmentId: string}>());
export const updateVideoLinkError = createAction('[API] update videoLink has an error', props<{error: ApiError}>());

export const generateParticipantReportSuccess = createAction('[API] generate participant report success');
export const generateParticipantReportError = createAction('[API] generate participant report error', props<{error: ApiError}>());

