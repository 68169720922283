import {Component} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {MessageService} from "../../shared/message.service";
import {ApiError} from "../../services/error";
import {Api} from "../../services/api";

@Component({
  selector: 'app-login-by-link',
  templateUrl: './login-by-link.component.html',
  styleUrls: ['./login-by-link.component.scss']
})
export class LoginByLinkComponent {
  form: UntypedFormGroup;

  constructor(fb: UntypedFormBuilder, private api: Api, private messageService: MessageService) {
    this.form = fb.group({
      email: ['', [Validators.required, Validators.email]],
    })
  }

  submit() {
    this.api.sendLoginLinkWithEmail(this.form.get('email').value)
      .subscribe(
        () => {
          this.messageService.showMessage(`Wir haben Dir eine Mail an mit dem Link zum Login geschickt.`);
          // this._router.navigate(["/login"]);
        },
        (error: ApiError) => {
          this.messageService.showError(error.getDisplayMessage())
        }
      );
  }
}
