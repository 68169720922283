<h1 class="header mat-typography">Warteliste</h1>

<div class="actions">
</div>

<div class="main">
  <mat-form-field class="filter">
    <mat-label>Filter</mat-label>
    <input matInput (keyup)="applyFilter($event.target.value)" placeholder="z.B. Name">
  </mat-form-field>

  <mat-table [dataSource]="dataSource" matSort class="main mat-elevation-z8">
    <ng-container matColumnDef="name">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Name</mat-header-cell>
      <mat-cell *matCellDef="let element"> {{element.name}}  </mat-cell>
    </ng-container>

    <ng-container matColumnDef="subscribedOn">
      <mat-header-cell *matHeaderCellDef mat-sort-header>eingetragen am</mat-header-cell>
      <mat-cell *matCellDef="let element"> {{element.subscribedOn.format("D.M.YYYY HH:mm")}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="operations">
      <mat-header-cell *matHeaderCellDef>Aktionen</mat-header-cell>
      <mat-cell *matCellDef="let element">
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
  </mat-table>
</div>
