<div class="section">
  <div class="header">Passwort vergessen</div>
  <div class="body">
    <form [formGroup]="resetForm" autocomplete="off" novalidate>
      <div>
        <mat-form-field>
          <mat-label>E-Mail</mat-label>
          <input id="email" matInput formControlName="email">
        </mat-form-field>
      </div>
      <div class="actions">
        <button id="submit" mat-stroked-button color="primary" (click)="submit()" [disabled]="!this.resetForm.valid">
          Senden
        </button>
      </div>
    </form>
  </div>
</div>
