import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Api} from "../../../../services/api";
import {MessageService} from "../../../../shared/message.service";

@Component({
  selector: 'app-timeslot-detail-attachment-upload',
  templateUrl: './timeslot-detail-attachment-upload.component.html',
  styleUrls: ['./timeslot-detail-attachment-upload.component.scss']
})
export class TimeslotDetailAttachmentUploadComponent {
  @Input() timeslotId: string;
  @Output() attachmentAdded = new EventEmitter<void>();

  constructor(private api: Api, private messageService: MessageService) { }

  onChange(event) {
    let file = event.target.files[0];
    if (file) {
      this.api.addAttachment(file, this.timeslotId).subscribe(
        () => {
          this.attachmentAdded.emit();
          this.messageService.showMessage("Der Anhang wurde erfolgreich hochgeladen.")
        },
        (error) => {
          this.messageService.showError(error.message)
        }
      )
    }
  }
}
