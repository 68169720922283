<ng-container *ngIf="wod$ | async as wod">
  <div class="navigation">
    <div (click)="abort()">Zurück</div>
    <div (click)="activateSection('data')" [class.selected]="section=='data'" [class.dirty]="dataSectionIsDirty">allg. Daten</div>
    <div (click)="activateSection('links')" [class.selected]="section=='links'" *ngIf="wod.hasId()">Meeting-Link ({{wod.hasVideoLink() ? '1' : '0'}})</div>
    <ng-container *ngIf="!wod.informationalOnly">
      <div (click)="activateSection('attributes')"  [class.selected]="section=='attributes'"  [class.dirty]="attributeSectionIsDirty" *ngIf="wod.hasId()">Attribute ({{attributeCount$ | async}})</div>
      <div (click)="activateSection('attachments')" [class.selected]="section=='attachments'" *ngIf="wod.hasId()">Anhänge</div>
      <div (click)="activateSection('participants')" [class.selected]="section=='participants'" *ngIf="wod.hasId()">Teilnehmer ({{wod.numberOfParticipants}}  / {{wod.maxMembers}})</div>
      <div (click)="activateSection('waiting-list')" [class.selected]="section=='waiting-list'" *ngIf="canShowWaitingList(wod)">Warteliste ({{wod.waitingList.length}})</div>
      <div (click)="activateSection('enquirers')" *ngIf="canShowEnquirersLink(wod)" [class.selected]="section=='enquirers'">Anfragen ({{wod.requestedParticipants?.length}})</div>
    </ng-container>
  </div>

  <div class="content">
    <app-wod-detail-data *ngIf="section == 'data'"></app-wod-detail-data>
    <app-appointment-detail-attributes *ngIf="section == 'attributes'"></app-appointment-detail-attributes>
    <app-appointment-detail-links *ngIf="section == 'links'"></app-appointment-detail-links>
    <app-wod-detail-participants *ngIf="section == 'participants'"></app-wod-detail-participants>
    <app-wod-detail-enquirers *ngIf="section == 'enquirers'"></app-wod-detail-enquirers>
    <app-wod-detail-waitinglist *ngIf="section == 'waiting-list'"></app-wod-detail-waitinglist>
    <app-timeslot-detail-attachments *ngIf="section == 'attachments'" [timeslot]="wod"></app-timeslot-detail-attachments>
  </div>
</ng-container>

