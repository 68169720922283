<h1 class="header mat-typography">Ressourcengruppen</h1>

<div class="actions">
  <button (click)="addResource()" color="primary" mat-stroked-button>Gruppe anlegen</button>
</div>

<mat-table [dataSource]="dataSource" matSort class="list mat-elevation-z8">
  <ng-container matColumnDef="name">
    <mat-header-cell *matHeaderCellDef mat-sort-header>Name</mat-header-cell>
    <mat-cell *matCellDef="let element"> {{element.name}}  </mat-cell>
  </ng-container>

  <ng-container matColumnDef="label">
    <mat-header-cell *matHeaderCellDef mat-sort-header>Bezeichnung</mat-header-cell>
    <mat-cell *matCellDef="let element"> {{element.label}}</mat-cell>
  </ng-container>

  <ng-container matColumnDef="group">
    <mat-header-cell *matHeaderCellDef mat-sort-header>Ressourcengruppe</mat-header-cell>
    <mat-cell *matCellDef="let element"> {{element.group}}</mat-cell>
  </ng-container>

  <ng-container matColumnDef="type">
    <mat-header-cell *matHeaderCellDef mat-sort-header>Ressourcentyp</mat-header-cell>
    <mat-cell *matCellDef="let element"> {{element.type}}</mat-cell>
  </ng-container>

  <ng-container matColumnDef="customer_visible">
    <mat-header-cell *matHeaderCellDef mat-sort-header>für Kunden sichtbar</mat-header-cell>
    <mat-cell *matCellDef="let element"> {{element.visible ? 'Ja' : 'Nein'}}</mat-cell>
  </ng-container>

  <ng-container matColumnDef="operations">
    <mat-header-cell *matHeaderCellDef>Aktionen</mat-header-cell>
    <mat-cell *matCellDef="let element">
        <mat-icon (click)="edit(element)">edit</mat-icon>
        <mat-icon (click)="delete(element)">delete</mat-icon>
    </mat-cell>
  </ng-container>

  <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
  <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>

  <!-- Row shown when there is no matching data. -->
  <tr class="mat-row" *matNoDataRow>
    <td class="mat-cell" colspan="5">keine Gruppen gefunden.</td>
  </tr>
</mat-table>

<mat-paginator [pageSizeOptions]="[20, 40]"></mat-paginator>
