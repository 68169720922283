import { createAction, props } from '@ngrx/store';
import {Customer} from "../../services/customer";
import {CustomerSubscription} from "../../services/customerSubscription";


export const customersLoaded = createAction('[Customer API] customers loaded', props<{customers: Customer[]}>());
export const customerLoaded = createAction('[Customer API] customer loaded', props<{customer: Customer}>());
export const bookingsLoaded = createAction('[Customer API] bookings loaded', props<{bookings: CustomerSubscription[]}>());
export const deleteCustomerSuccess = createAction('[Customer API] customer delete success', props<{customerId: string}>());
export const customerDeleteError = createAction('[Customer API] customer delete error', props<{customerId: string}>());
export const sendResetPasswordMailSuccess = createAction('[Customer API] send reset password mail success', props<{customerId: string}>());
export const sendResetPasswordMailError = createAction('[Customer API] send reset password mail error', props<{customerId: string}>());
export const customerModified = createAction('[Customer API] customer modified', props<{customerId: string}>());
export const setInstructorSuccesful = createAction('[Customer API] set instructor success', props<{customerId: string}>());
export const unsetInstructorSuccesful = createAction('[Customer API] unset instructor success', props<{customerId: string}>());
export const bookPrepaidUnitsSuccessful = createAction('[Customer Api] book prepaid units was succesful', props<{customerId: string, units: number}>());

