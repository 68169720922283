import {Component, ViewChild} from '@angular/core';
import {AppointmentsState} from "../../actions/appointments";
import {Store} from "@ngrx/store";
import {currentAppointment} from "../../actions/selectors";
import {MatTableDataSource} from "@angular/material/table";
import {Participant} from "../../../services/participant";
import {MatSort} from "@angular/material/sort";
import {filter} from "rxjs/operators";

@Component({
  selector: 'app-wod-detail-waitinglist',
  templateUrl: './appointment-detail-waitinglist.component.html',
  styleUrls: ['./appointment-detail-waitinglist.component.scss']
})
export class AppointmentDetailWaitinglistComponent {
  displayedColumns = ['name', 'subscribedOn', 'operations'];
  dataSource = new MatTableDataSource<Participant>();
  @ViewChild(MatSort) set sort (sort: MatSort) {
    this.dataSource.sort = sort;
  };

  constructor(private store: Store<AppointmentsState>) {
    store.select(currentAppointment)
      .pipe(filter(it => it != null))
      .subscribe(
        (data) => {
          this.dataSource.data = data.waitingList
        }
      );
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }


}
