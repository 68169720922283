import {Component} from '@angular/core';
import {Api} from "../services/api";
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {filter, map} from "rxjs/operators";
import {AppState} from "../actions/reducers";
import {Store} from "@ngrx/store";
import {AppActions} from "../actions/action-types";
import {LoginService} from "./login-service";
import {MessageService} from "../shared/message.service";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {
  loginForm: UntypedFormGroup;
  errorMessage: string;

  private loginData: { email: string, password: string };

  constructor(fb: UntypedFormBuilder, private api: Api,
              private store: Store<AppState>,
              private loginService: LoginService,
              private messageService: MessageService) {
    this.loginForm = fb.group(
      {
        email: ['', [Validators.required]],
        password: ['', [Validators.required]]
      }
    )

    this.loginForm.valueChanges
      .pipe(
        filter(() => this.loginForm.valid),
        map(value => {
          return {email: value.email, password: value.password}
        }),
      )
      .subscribe(
        login => {
          this.loginData = login;
        }
      );
  }

  submit() {
    this.errorMessage = "";
    this.store.dispatch(AppActions.logout());
    this.login();
  }

  login() {
    this.api.login(this.loginData.email, this.loginData.password)
      .subscribe(
        token => {
          this.loginService.handleLoginWithToken(token);
        },
        () => {
          this.onInvalidLogin();
        }
      );
  }

  onInvalidLogin() {
    this.store.dispatch(AppActions.logout())
    this.messageService.showError("Der Login war leider nicht erfolgreich.");
  }
}
