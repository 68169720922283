import {Action, ActionReducer, createReducer, on} from "@ngrx/store";
import {ApiActions, AppActions} from "./action-types";
import * as AppointmentApiActions from "../appointments/actions/api.actions";
import * as CustomerApiActions from "../customers/actions/customer-api.actions";

export interface AppState {
  message: string
}

export const initialState: AppState = {
  message: ''
};

export const appFeatureKey = 'app';
const appReducer = createReducer(
  initialState,
  on(AppActions.messageDisplayed, (state) => {
    return {...state, message: ''}
  }),

  on(AppointmentApiActions.confirmSubscriptionSuccessful, (state, action) => {
    return {...state, message: "Die Anmeldung von " + action.subscriptionRequest.name + " wurde bestätigt."}
  }),
  on(AppointmentApiActions.rejectSubscriptionSuccessful, (state, action) => {
    return {...state, message: "Die Anmeldung von " + action.subscriptionRequest.name + " wurde abgelehnt."}
  }),
  on(AppointmentApiActions.subscribeUserSuccess, (state, action) => {
    return {...state, message: action.customer.name + " wurde zum Termin angemeldet."}
  }),
  on(AppointmentApiActions.unsubscribeParticipantSuccess, (state, action) => {
    return {...state, message: action.participant.name + " wurde vom Termin abgemeldet."}
  }),
  on(AppointmentApiActions.cancelAppointmentSuccess, (state) => {
    return {...state, message: "Der Termin wurde erfolgreich abgesagt."}
  }),
  on(AppointmentApiActions.deleteAppointmentSuccess, (state) => {
    return {...state, message: "Der Termin wurde erfolgreich gelöscht.", }
  }),
  on(AppointmentApiActions.deleteAppointmentError, (state) => {
    return {...state, message: "Beim Löschen des Termins ist ein Fehler aufgetreten."}
  }),
  on(ApiActions.saveNewAppointmentError, (state) => {
    return {...state, message: "Beim erstellen des Termins ist ein Fehler aufgetreten."}
  }),
  on(ApiActions.updateVideoLinkSuccess, (state) => {
    return {...state, message: "Der Videolink wurde erfolgreich gespeichert."}
  }),
  on(ApiActions.updateVideoLinkError, (state) => {
    return {...state, message: "Beim speichern des Videolinks trat ein Fehler auf."}
  }),
  on(ApiActions.generateParticipantReportSuccess, (state) => {
    return {...state, message: "Der Report wurde erfolgreich erstellt und gespeichert."}
  }),
  on(ApiActions.generateParticipantReportError, (state) => {
    return {...state, message: "Beim erstellen des Reports trat ein Fehler auf."}
  }),
  on(AppActions.messageDisplayed, (state) => {
    return {...state, message: ''}
  }),

  on(CustomerApiActions.bookPrepaidUnitsSuccessful, (state, action) => {
    let message: string;
    if (action.units >= 0)
      message = "Dem Kunden wurden " + action.units + " Einheiten gutgeschrieben."
    else
      message = "Dem Kunden wurden " + (action.units * -1) + " Einheiten abgebucht."
    return {...state, message: message}
  }),

  on(CustomerApiActions.deleteCustomerSuccess, (state) => {
    return {...state, message: 'Der Benutzer wurde erfolgreich gelöscht.'}
  }),
  on(AppActions.showMessage, (state, action) => {
    return {...state, message: action.message}
  }),
  on(CustomerApiActions.customerDeleteError, (state) => {
    return {...state, message: 'Beim löschen des Benutzers trat ein Fehler auf.'}
  }),
  on(CustomerApiActions.sendResetPasswordMailSuccess, (state) => {
    return {...state, message: 'Der Versand der E-Mail war erfolgreich.'}
  }),
  on(CustomerApiActions.sendResetPasswordMailError, (state) => {
    return {...state, message: 'Beim Versand der E-Mail trat ein Fehler auf.'}
  }),
  on(CustomerApiActions.setInstructorSuccesful, (state) => {
    return {...state, message: "Der Kunde wurde erfolgreich zum Mitarbeiter ernannt."}
  }),
  on(CustomerApiActions.unsetInstructorSuccesful, (state) => {
    return {...state, message: "Dem Kunden wurde der Mitarbeiterstatus entzogen."}
  }),
  on(ApiActions.changePasswordSuccess, (state) => {
    return {...state, message: "Das Passwort wurde erfolgreich geändert."}
  }),
  on(ApiActions.loadAppointmentAttributesError, (state, action) => {
    return {...state, message: "Beim laden der Attribute für den Termin trat ein Fehler auf. ["+action.error.getDisplayMessage()+"]"}
  }),
  on(ApiActions.saveAppointmentSuccess, (state) => {
    return {...state, message: "Der Termin wurde erfolgreich gespeichert."}
  }),
  on(ApiActions.saveAppointmentError, (state, action) => {
    return {...state, message: "Beim speichern des Termins ist ein Fehler aufgetreten. ["+action.error.getDisplayMessage()+"]"}
  }),
  on(ApiActions.loadAppointmentsError, (state, action) => {
    return {...state, message: "Beim laden der Termine ist ein Fehler aufgetreten.. ["+action.error.getDisplayMessage()+"]"}
  }),
  on(ApiActions.loadAppointmentError, (state, action) => {
    return {...state, message: "Beim laden des Termins ist ein Fehler aufgetreten.. ["+action.error.getDisplayMessage()+"]"}
  }),
  on(ApiActions.loadAttributeTemplatesError, (state, action) => {
    return {...state, message: "Beim laden der Templates ist ein Fehler aufgetreten.. ["+action.error.getDisplayMessage()+"]"}
  }),
);

export function reducer(state: AppState | undefined, action: Action) {
  return appReducer(state, action);
}

export function clearStateMetaReducer<State extends {}>(reducer: ActionReducer<State>): ActionReducer<State> {
  return function clearStateFn(state: State, action: Action) {
    console.log(action.type);
    if (action.type === '[APP] logout') {
      state = {} as State;
    }
    return reducer(state, action);
  };
}
