<app-timeslot-detail-attachment-upload  class="item" [timeslotId]="timeslotId" (attachmentAdded)="loadAttachments()"></app-timeslot-detail-attachment-upload>

<mat-table [dataSource]="dataSource" class="list mat-elevation-z8">
  <ng-container matColumnDef="name">
    <mat-header-cell *matHeaderCellDef>Name</mat-header-cell>
    <mat-cell *matCellDef="let element"> {{element.name}}  </mat-cell>
  </ng-container>

  <ng-container matColumnDef="size">
    <mat-header-cell *matHeaderCellDef>Größe</mat-header-cell>
    <mat-cell *matCellDef="let element" class="size"> {{element.size | number:'':'de'}} Byte  </mat-cell>
  </ng-container>

  <ng-container matColumnDef="operation">
    <mat-header-cell *matHeaderCellDef></mat-header-cell>
    <mat-cell *matCellDef="let element">
      <button mat-icon-button [matMenuTriggerFor]="itemMenu" [matMenuTriggerData]="{item: element}">
        <mat-icon>more_horiz</mat-icon>
      </button>
    </mat-cell>
  </ng-container>

  <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
  <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>

  <!-- Row shown when there is no matching data. -->
  <tr class="mat-row" *matNoDataRow>
    <td class="mat-cell" colspan="4">keine Anhänge vorhanden.</td>
  </tr>
</mat-table>

<mat-menu #itemMenu="matMenu">
  <ng-template matMenuContent let-item="item">
    <button mat-menu-item (click)="download(item)"><mat-icon>download</mat-icon><span>Herunterladen</span></button>
    <button mat-menu-item (click)="delete(item)"><mat-icon>delete</mat-icon><span>Löschen</span></button>
  </ng-template>
</mat-menu>

