import {createAction, props} from '@ngrx/store';
import {Wod} from "../../services/wod";
import {SubscriptionRequest} from "../../services/subscription-request";
import {Participant} from "../../services/participant";
import {Customer} from "../../services/customer";
import {ApiError} from "../../services/error";

export const customersLoaded = createAction('[API] customers loaded', props<{customers: Customer[]}>());
export const appointmentLoaded = createAction('[API] appointment loaded', props<{appointment: Wod}>());
export const subscriptionRequestsLoaded = createAction('[API] subscription requests loaded', props<{items: SubscriptionRequest[]}>());
export const confirmSubscriptionSuccessful = createAction('[API] confirm subscription was successful', props<{appointmentId: string, subscriptionRequest: SubscriptionRequest}>());
export const rejectSubscriptionSuccessful = createAction('[API] refuse subscription was successful', props<{appointmentId: string, subscriptionRequest: SubscriptionRequest}>());
export const unsubscribeParticipantSuccess = createAction('[API] unsubscribe participant from Appointment was successfull', props<{appointmentId: string, participant: Participant}>());
export const subscribeUserSuccess = createAction('[API] subscribe user to Appointment  was successfull', props<{appointmentId: string, customer: Customer}>());
export const cancelAppointmentSuccess = createAction('[API] cancel appointment was successfull', props<{appointmentId: string}>());

export const deleteAppointmentSuccess = createAction('[API] delete appointment was successfull', props<{appointment: Wod}>());
export const deleteAppointmentError = createAction('[API] delete appointment has an error', props<{appointment: Wod, error: ApiError}>());

export const subscribeAnonymSuccess = createAction('[API] subscribe anonym was successful', props<{appointmentId: string}>());
export const subscribeAnonymError = createAction('[API] subscribe anonym has an error', props<{appointmentId: string, error: ApiError}>());


