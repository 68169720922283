<div class="section">
  <div class="header">Password ändern</div>
  <div class="body">
    <form [formGroup]="form" autocomplete="off" novalidate>
      <div>
        <mat-form-field>
          <mat-label>neues Passwort</mat-label>
          <input id="password" matInput formControlName="password" type="password">
        </mat-form-field>
      </div>
      <div class="actions">
        <button id="submit" mat-stroked-button (click)="submit()" [disabled]="!this.form.valid">Passwort ändern</button>
      </div>
    </form>
  </div>
</div>
