import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';

import {CustomerListComponent} from "./customer-list/customer-list.component";
import {CustomerDetailComponent} from "./customer-detail/customer-detail.component";
import {CustomerAddComponent} from "./customer-add/customer-add.component";

const routes: Routes = [
  {path: '', component: CustomerListComponent},
  {path: "detail/:id", component: CustomerDetailComponent},
  {path: "add", component: CustomerAddComponent},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CustomersRoutingModule {
}
