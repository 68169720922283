import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {Customer} from "../../services/customer";
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {Api} from "../../services/api";
import {MessageService} from "../../shared/message.service";
import * as moment from "moment";
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from "@angular/material/core";
import {MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter} from "@angular/material-moment-adapter";

export interface ChangeCovidDialogData {
  customer: Customer;
}

export const MY_DATE_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'DD.MM.YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'DD.MM.YYYY',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-change-covid-state',
  templateUrl: './change-covid-state.component.html',
  styleUrls: ['./change-covid-state.component.scss'],
  providers: [
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]},
    {provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS},
  ],
})
export class ChangeCovidStateComponent {
  fg: UntypedFormGroup;
  maxDate: Date;

  constructor(
    public dialogRef: MatDialogRef<ChangeCovidStateComponent>,
    @Inject(MAT_DIALOG_DATA) private data: ChangeCovidDialogData,
    fb: UntypedFormBuilder,
    private api: Api,
    private messageService: MessageService) {

    let covid = data.customer.covid;
    this.maxDate = new Date();

    this.fg = fb.group({
      state: [covid.state ?? ""],
      date: [covid.date,covid.state == null ? [] : [Validators.required]],
      allowed: [false, covid.state == null ? [] : [Validators.requiredTrue]]
    });

    this.fg.get('state').valueChanges.subscribe(val => {
      if (val == '') { // for setting validations
        this.fg.get('date').clearValidators();
        this.fg.get('date').setValue(null);
        this.fg.get('allowed').clearValidators();
      } else {
        this.fg.get('date').setValidators(Validators.required);
        this.fg.get('allowed').setValidators(Validators.requiredTrue);
      }
      this.fg.get('date').updateValueAndValidity();
      this.fg.get('allowed').updateValueAndValidity();
    });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  update() {
    let date = (this.fg.get('date').value == null) ? null : moment(this.fg.get('date').value);
    this.api.updateCovid(this.data.customer.id, this.fg.get('state').value, date)
      .subscribe(
        () => {
          this.messageService.showMessage("Der Covid Status wurde aktualisiert.");
          this.dialogRef.close();
        },
        () => {
          this.messageService.showError("Beim aktualisieren des Covid-Status trat ein Fehler auf.")
        }
      )
  }
}
