import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ConfirmDialogComponent} from './confirm-dialog/confirm-dialog.component';
import {AppMaterialModule} from "../material.module";
import {MatButtonModule} from "@angular/material/button";
import {MatDialogModule} from "@angular/material/dialog";
import {TextInputDialogComponent} from "./text-input-dialog/text-input-dialog.component";
import {ReactiveFormsModule} from "@angular/forms";
import {SubscribeConditionComponent} from "./subscribe-condition/subscribe-condition.component";


@NgModule({
  imports: [
    CommonModule,
    AppMaterialModule,
    MatButtonModule,
    MatDialogModule,
    ReactiveFormsModule,
  ],
  declarations: [ConfirmDialogComponent, TextInputDialogComponent, SubscribeConditionComponent],
  exports: [ConfirmDialogComponent, SubscribeConditionComponent]

})
export class SharedModule {
}
