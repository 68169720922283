<ng-container *ngIf="(customer$ | async) as customer">
  <h1 class="header mat-typography">{{customer.displayName}}</h1>

  <div class="actions">
  </div>

  <div class="main">
    <span class="label">Name</span>
    <span class="value">{{customer.displayName}}</span>
    <button (click)="updateCustomer(customer)" matSuffix aria-label="Name anpassen" matTooltip="Name ändern" class="action">
      <mat-icon>mode_edit</mat-icon>
    </button>

    <span class="label">Email</span>
    <span class="value"><a href="mailto:{{customer.email}}>">{{customer.email}}</a></span>
    <span class="action"></span>

    <span class="label">Mitglied seit</span>
    <span class="value">{{customer.created | amDateFormat: 'DD.MM.YYYY'}}</span>
    <span class="action"></span>

    <span class="label">ist Mitarbeiter</span>
    <span class="value">{{customer.isInstructor() ? 'ja' : 'nein'}}</span>
    <button *ngIf="!customer.isInstructor()" (click)="setInstructor(customer.id)" matSuffix aria-label="zum Mitarbeiter ernennen" matTooltip="zum Trainer ernennen" class="action">
        <mat-icon>wifi_protected_setup</mat-icon>
    </button>
    <button *ngIf="customer.isInstructor()" (click)="unsetInstructor(customer.id)" matSuffix aria-label="Mitarbeiterstatus entfernen" matTooltip="Trainerstatus entfernen" class="action">
        <mat-icon>wifi_protected_setup</mat-icon>
    </button>

    <span class="label">Prepaideinheiten</span>
    <span class="value">{{customer.prepaidUnits}}</span>
    <button  (click)="bookPrepaid(customer.id)" matSuffix [attr.aria-label]="'Guthaben aufladen / entfernen'" matTooltip="Gutenhaben aufladen / entfernen" class="action">
      <mat-icon>import_export</mat-icon>
    </button>

    <span class="label">Vertrag</span>
    <span class="value">{{customer.contractDisplayName}}
      <span *ngIf="customer.contractUntil"> bis {{customer.contractUntil | amDateFormat: 'DD.MM.YYYY'}}</span>
    </span>
    <span class="action"></span>

    <span class="label">Covid Status</span>
    <span class="value" *ngIf="customer.covid.state">{{customer.covid.stateStr}} am {{customer.covid.date  | amDateFormat: 'DD.MM.YYYY'}}</span>
    <span class="value" *ngIf="!customer.covid.state">{{customer.covid.stateStr}}</span>
    <button  (click)="changeCovid(customer)" matSuffix aria-label="Covid Status ändern" matTooltip="Covid Status ändern" class="action">
      <mat-icon>mode_edit</mat-icon>
    </button>

    <span class="label">Anmerkung</span>
    <span class="value">{{customer.note}}</span>
    <button  (click)="updateCustomer(customer)" matSuffix aria-label="Anmerkung ändern" matTooltip="Anmerkung ändern" class="action">
      <mat-icon>mode_edit</mat-icon>
    </button>
  </div>
</ng-container>
