import {Period} from "../appointments/period";

export class PublicSubscription {
  public subscribeCondition: PublicSubscriptionCondition;
  public subscribeConfirmationDays: number;
  public subscriptionOpening: Period;
  public subscriptionClosing: Period;
  public unsubscriptionClosing: Period;

  constructor() {
    this.subscribeCondition = PublicSubscriptionCondition.NO_SUBSCRIPTION_ALLOWED;
    this.subscriptionOpening = Period.buildAsAtStart();
    this.subscriptionClosing = Period.buildAsAtStart();
    this.unsubscriptionClosing = Period.buildAsAtStart();
  }

  public static fromJson(data: any): PublicSubscription {
    let result = new PublicSubscription();

    switch (data.subscribeCondition) {
      case 'CONFIRMATION':
        result.subscribeCondition = PublicSubscriptionCondition.CONFIRMATION;
        break;
      case 'OPEN_TO_ALL':
        result.subscribeCondition = PublicSubscriptionCondition.OPEN_TO_ALL;
        break;
      case 'NO_SUBSCRIPTION_ALLOWED':
      default:
        result.subscribeCondition = PublicSubscriptionCondition.NO_SUBSCRIPTION_ALLOWED;
        break;
    }
    result.subscribeConfirmationDays = data.subscribeConfirmationDays;

    result.subscriptionOpening = PublicSubscription.toPeriod(data.subscriptionOpening);
    result.subscriptionClosing = PublicSubscription.toPeriod(data.subscriptionClosing);
    result.unsubscriptionClosing = PublicSubscription.toPeriod(data.unsubscriptionClosing);
    return result;
  }

  /**
   * erstellt aus den beiden Werten von der Schnittstelle die jeweils richtige Periode
   * @param rtBom
   */
  private static toPeriod(rtBom: { atTime: string; daysBeforeBeginOfMonth: number, minBefore: number }) {
    if (rtBom == undefined) {
      return Period.buildAsAtEnd();
    }

    if (rtBom.atTime) {
      return Period.buildAsRtBom(
        rtBom.daysBeforeBeginOfMonth,
        +rtBom.atTime.split(":", 2)[0],
        +rtBom.atTime.split(":", 2)[1]
      )
    }

    if (rtBom.minBefore != null) {
      return Period.buildAsRta(rtBom.minBefore);
    }

    return Period.buildAsAtEnd();
  }
}

export enum PublicSubscriptionCondition {
  CONFIRMATION = 'CONFIRMATION',
  OPEN_TO_ALL = 'OPEN_TO_ALL',
  NO_SUBSCRIPTION_ALLOWED = 'NO_SUBSCRIPTION_ALLOWED'
}
