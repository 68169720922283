import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {EMPTY, of} from 'rxjs';
import {catchError, map, mergeMap} from 'rxjs/operators';
import {Api} from "../../services/api";
import * as CustomerApiActions from "./customer-api.actions";
import {CustomerActions, CustomerContractPageActions, CustomerListPageActions} from "./action-types";
import {Router} from "@angular/router";

@Injectable()
export class CustomerEffects {

  deleteCustomer = createEffect(() => this.actions$.pipe(
    ofType(CustomerActions.deleteCustomer),
    mergeMap((action) => {
        return this.api.deleteCustomer(action.customerId)
          .pipe(
            map(() => (CustomerApiActions.deleteCustomerSuccess({customerId: action.customerId}))),
            catchError(() => of(CustomerApiActions.customerDeleteError({customerId: action.customerId}))
            )
          )
      }
    )
    ),
  );

  customerDeleteSuccess = createEffect(() => this.actions$.pipe(
    ofType(CustomerApiActions.deleteCustomerSuccess),
    mergeMap(() => {this.router.navigate(['/customers']); return EMPTY})
    ), {dispatch: false},
  );

  sendResetPasswordMail = createEffect(() => this.actions$.pipe(
    ofType(CustomerActions.sendResetPasswordMail),
    mergeMap((action) => {
        return this.api.sendPasswordChangeMail(action.customerId)
          .pipe(
            map(() => (CustomerApiActions.sendResetPasswordMailSuccess({customerId: action.customerId}))),
            catchError(() => of(CustomerApiActions.sendResetPasswordMailError({customerId: action.customerId}))
            )
          )
      }
    )
    ),
  );

  bookFlatrate = createEffect(() => this.actions$.pipe(
    ofType(CustomerContractPageActions.bookFlatrate),
    mergeMap((action) => {
        return this.api.bookFlatrate(action.customerId, action.from, action.until)
          .pipe(
            map(() => (CustomerApiActions.customerModified({customerId: action.customerId}))),
            catchError(() => EMPTY)
          )
      }
    )
    ),
  );

  bookWeeklyContingent = createEffect(() => this.actions$.pipe(
    ofType(CustomerContractPageActions.bookWeekly),
    mergeMap((action) => {
        return this.api.bookWeeklyContingent(action.customerId, action.units, action.from, action.until)
          .pipe(
            map(() => (CustomerApiActions.customerModified({customerId: action.customerId}))),
            catchError(() => EMPTY)
          )
      }
    )
    ),
  );

  bookMonthlyContingent = createEffect(() => this.actions$.pipe(
    ofType(CustomerContractPageActions.bookMonthly),
    mergeMap((action) => {
        return this.api.bookMonthlyContingent(action.customerId, action.units, action.from, action.until)
          .pipe(
            map(() => (CustomerApiActions.customerModified({customerId: action.customerId}))),
            catchError(() => EMPTY)
          )
      }
    )
    ),
  );

  removeContract = createEffect(() => this.actions$.pipe(
    ofType(CustomerContractPageActions.removeContract),
    mergeMap((action) => {
        return this.api.removeContract(action.customerId)
          .pipe(
            map(() => (CustomerApiActions.customerModified({customerId: action.customerId}))),
            catchError(() => EMPTY)
          )
      }
    )
    ),
  );

  loadCustomer = createEffect(() => this.actions$.pipe(
    ofType(
      CustomerActions.loadCustomer,
      CustomerApiActions.customerModified,
      CustomerApiActions.unsetInstructorSuccesful,
      CustomerApiActions.setInstructorSuccesful,
      CustomerApiActions.bookPrepaidUnitsSuccessful,
    ),
    mergeMap((action) => {
        return this.api.getCustomer(action.customerId)
          .pipe(
            map(customer => (CustomerApiActions.customerLoaded({customer: customer}))),
            catchError(() => EMPTY)
          )
      }
    )
    ),
  );

  loadCustomers = createEffect(() => this.actions$.pipe(
    ofType(CustomerListPageActions.loadCustomers),
    mergeMap(() => {
        return this.api.getCustomers()
          .pipe(
            map(customers => (CustomerApiActions.customersLoaded({customers: customers}))),
            catchError(() => EMPTY)
          )
      }
    )
    ),
  );

  getBookingsForCustomer = createEffect(() => this.actions$.pipe(
    ofType(CustomerActions.loadCustomer),
    mergeMap((action) => {
        return this.api.getBookingsForCustomer(action.customerId)
          .pipe(
            map(bookings => (CustomerApiActions.bookingsLoaded({bookings: bookings}))),
            catchError(() => EMPTY)
          )
      }
    )
    ),
  );

  setInstructor = createEffect(() => this.actions$.pipe(
    ofType(CustomerActions.setInstructor),
    mergeMap((action) => {
        return this.api.markCustomerAsInstructor(action.customerId)
          .pipe(
            // map(bookings => (CustomerApiActions.customerModified({customerId: action.customerId}))),
            map(() => (CustomerApiActions.setInstructorSuccesful({customerId: action.customerId}))),
            catchError(() => EMPTY)
          )
      }
    )
    ),
  );

  unsetInstructor = createEffect(() => this.actions$.pipe(
    ofType(CustomerActions.unsetInstructor),
    mergeMap((action) => {
        return this.api.removeInstructorStatus(action.customerId)
          .pipe(
            // map(bookings => (CustomerApiActions.customerModified({customerId: action.customerId}))),
            map(() => (CustomerApiActions.unsetInstructorSuccesful({customerId: action.customerId}))),
            catchError(() => EMPTY)
          )
      }
    )
    ),
  );

  bookPrepaidUnits = createEffect(() => this.actions$.pipe(
    ofType(CustomerActions.bookPrepaidUnits),
    mergeMap((action) => {
        return this.api.bookPrepaidUnits(action.customerId, action.units)
          .pipe(
            map(() => (CustomerApiActions.bookPrepaidUnitsSuccessful({customerId: action.customerId, units: action.units}))),
            catchError(() => EMPTY)
          )
      }
    )
    ),
  );

  constructor(
    private actions$: Actions,
    private api: Api,
    private router: Router
  ) {
  }
}
