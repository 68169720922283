import {Injectable} from '@angular/core';
import {Action, Selector, State, StateContext} from '@ngxs/store';
import {ResourceGroupsActions} from "./resource-groups.actions";
import {tap} from "rxjs/operators";
import {ResourceGroupsApi, ResourceGroupUpate} from "../../services/ResourceGroupsApi";
import {MessageService} from "../../../shared/message.service";
import {Observable} from "rxjs";
import {ResetForm} from "@ngxs/form-plugin";
import {ResourceGroup} from "../../model/resource-group";

export interface ResourceGroupsStateModel {
  items: ResourceGroup[],
  current_item: ResourceGroup,
  editForm: {
    model: any,
    dirty: boolean,
    status: string,
    errors: any
  }
}

@State<ResourceGroupsStateModel>({
  name: 'resourceGroups',
  defaults: {
    items: [],
    current_item: null,
    editForm: undefined
  }
})
@Injectable()
export class ResourceGroupsState {
  constructor(private resourceGroupsApi: ResourceGroupsApi, private messageService: MessageService) {
  }

  @Selector()
  static items(state: ResourceGroupsStateModel): ResourceGroup[] {
    return state.items;
  }

  @Selector()
  static canSave(state: ResourceGroupsStateModel): boolean {
    return state.editForm.dirty && (state.editForm.status == 'VALID');
  }

  @Selector()
  static currentItem(state: ResourceGroupsStateModel): ResourceGroup {
    return state.current_item;
  }

  @Action(ResourceGroupsActions.LoadAll)
  loadAll(ctx: StateContext<ResourceGroupsStateModel>) {
    return this.resourceGroupsApi.getAll().pipe(
      tap(
        it => {
          ctx.patchState({items: it})
        },
        error => {
          ctx.patchState({items: []});
          this.messageService.showMessage(error.getDisplayMessage())
        }
      )
    )
  }

  @Action(ResourceGroupsActions.Create)
  create(ctx: StateContext<ResourceGroupsStateModel>) {
    let newItem = new ResourceGroup();
    ctx.dispatch(new ResetForm({path: "resourceGroups.editForm"}))
    ctx.patchState({
      current_item: newItem,
    })
  }

  @Action(ResourceGroupsActions.Edit)
  edit(ctx: StateContext<ResourceGroupsStateModel>, action: ResourceGroupsActions.Edit) {
    return this.resourceGroupsApi.getById(action.id).pipe(
      tap(
        it => {
          ctx.dispatch(new ResetForm({path: "resourceGroups.editForm"}))
          ctx.patchState({
            current_item: it,
            editForm: {
              model: {
                name: it.name,
              },
              dirty: false,
              status: '',
              errors: {}
            }
          })
        },
        error => {
          ctx.patchState({current_item: null});
          console.log(error);
          this.messageService.showMessage(error.getDisplayMessage())
        }
      )
    )
  }

  @Action(ResourceGroupsActions.Save)
  save(ctx: StateContext<ResourceGroupsStateModel>) {
    let obs: Observable<any>;
    let apiModel = this.formModelToApiModel(ctx.getState().editForm.model);
    let resourceId = ctx.getState().current_item.id;

    if (resourceId)
      obs = this.resourceGroupsApi.update(resourceId, apiModel);
    else
      obs = this.resourceGroupsApi.create(apiModel);

    return obs.pipe(
      tap(
        () => {
          ctx.dispatch(new ResourceGroupsActions.LoadAll());
          ctx.dispatch(new ResetForm({path: "resourceGroups.editForm"}))
          ctx.patchState({current_item: null});
          this.messageService.showMessage("Die Gruppe wurde erfolgreich gespeichert.");
        },
        error => {
          this.messageService.showMessage(error.getDisplayMessage())
        }
      )
    )
  }

  @Action(ResourceGroupsActions.Delete)
  delete(ctx: StateContext<ResourceGroupsStateModel>, action: ResourceGroupsActions.Delete): Observable<any> {
    return this.resourceGroupsApi.delete(action.id).pipe(
      tap(
        () => {
          ctx.dispatch(new ResourceGroupsActions.LoadAll());
          ctx.dispatch(new ResetForm({path: "resourceGroups.editForm"}))
          ctx.patchState({current_item: null});
          this.messageService.showMessage("Die Gruppe wurde erfolgreich gelöscht.");
        },
        error => {
          this.messageService.showMessage(error.getDisplayMessage())
        }
      )
    )
  }

  private formModelToApiModel(model: any): ResourceGroupUpate {
    return {
      name: model.name,
    }
  }
}
