import {Moment} from "moment";
import {AppointmentDto} from "./appointmentDto";
import * as moment from "moment";
import {Resource} from "../resource-managment/model/resource";

export class Appointment {
  id: string
  name: string
  start: Moment
  end: Moment
  resources: Resource[]
  numberOfPersons: number
  email: string
  note: string
  accessCheck: string
  bookingCode: string

  public static fromDto(dto: AppointmentDto) : Appointment {
    let result = new Appointment();
    result.id = dto.id;
    result.name = dto.name;
    result.start = moment(dto.start);
    result.end = moment(dto.end);
    result.numberOfPersons = dto.numberOfPersons;
    result.email = dto.email;
    result.note = dto.note;
    result.bookingCode = dto.bookingCode;
    result.accessCheck = dto.accessCheck;
    result.resources = [];

    dto.resources.forEach(it => result.resources.push(Resource.fromJson(it)));


    return result;
  }

  static createForVariant(serviceProviderVariant: string, start: moment.Moment, end: moment.Moment) : Appointment{
    let result = new Appointment();

    // switch (serviceProviderVariant){
    //   default:
    //     result.maxMembers = 8;
    // }

    result.start = start.clone();
    result.end = end.clone();
    result.numberOfPersons = 1;
    result.accessCheck = 'NONE';
    return result;
  }
}
