import {Component} from '@angular/core';
import {MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-wod-update-fixed',
  templateUrl: './appointment-update-fixed.component.html',
  styleUrls: ['./appointment-update-fixed.component.scss']
})
export class AppointmentUpdateFixedComponent {

  constructor(public dialogRef: MatDialogRef<AppointmentUpdateFixedComponent>) {
  }

  onNoClick(): void {
    this.dialogRef.close({update: false});
  }

  onSaveClick() {
    this.dialogRef.close({update: true});
  }
}
