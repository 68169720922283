import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {Customer} from "../../services/customer";
import {Api} from "../../services/api";
import {CustomerContractLog} from "../../services/customer-contract-log";
import {FormControl} from "@angular/forms";

@Component({
  selector: 'app-customer-log',
  templateUrl: './customer-log.component.html',
  styleUrls: ['./customer-log.component.scss']
})
export class CustomerLogComponent implements OnChanges {
  @Input() customer: Customer

  displayedColumns: string[] = ['date', 'change-type', 'unit-amount', 'from', 'until', 'author'];
  _data: CustomerContractLog[];
  data: CustomerContractLog[] = []; //filtered Data
  changeTypes: string[];
  changeTypesCtrl = new FormControl();
  loading = true;

  constructor(private api: Api) {
    this.changeTypesCtrl.valueChanges.subscribe({
      next: () => this.filterData()
    })
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.loading = true;
    this.api.getContractLog(this.customer.id).subscribe({
      next: (data) => {
        this.loading = false;
        this._data = data;
        this.filterData();
      },
      error: () => {this.loading = false}
    })
  }

  private filterData() {
    let selectedChangeTypes : string[] | null = this.changeTypesCtrl.value;

    if (selectedChangeTypes === null || selectedChangeTypes.length == 0) {
      this.data = [...this._data];
      return
    }

    this.data = this._data.filter(it => selectedChangeTypes.includes(it.changeType))
  }
}
