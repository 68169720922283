export namespace ResourceTypesActions {
  export class LoadAll {
    static readonly type = '[ResourceTypes] load all ResourceTypes for a serviceProvider';
    constructor() {
    }
  }

  export class Create {
    static readonly type = '[ResourceTypes-List] create new ResourceType';

    constructor() {
    }
  }

  export class Edit {
    static readonly type = '[ResourceTypes-List] edit ResourceType'
    constructor(public id: string) {}
  }

  export class Save {
    static readonly type = '[ResourceTypes-Edit] save ResourceType';
  }

  export class Delete {
    static readonly type = '[ResourceType-List] delete ResourceType'
    constructor(public id: string) {}
  }

}
