<h1 class="header mat-typography">{{(customer$ | async).displayName}}</h1>

<div class="content" *ngIf="customer$ | async as customer">
  <div class="item" (click)="delete(customer)">
    <div class="icon">
      <mat-icon>notifications</mat-icon>
    </div>
    <div class="title">Benutzer Löschen</div>
    <div class="description">Lösche einen Benutzer aus deinem Bestand.</div>
  </div>
  <div class="item" (click)="sendPasswordMail(customer)">
    <div class="icon">
      <mat-icon>email</mat-icon>
    </div>
    <div class="title">Reset-Passwort senden</div>
    <div class="description">Sende eine E-Mail an den Benutzer, mit deren Hilfe er sein Passwort neu setzen kann.</div>
  </div>
</div>
