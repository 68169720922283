import {Injectable} from "@angular/core";
import {ReplaySubject} from "rxjs";
import {ServiceProvider} from "./service-provider";

abstract class  AppEvent {
  constructor(public key: "timeslotModified" | "appointmentModified" | "serviceProviderChanged") {
  }
}
export class TimeslotModified extends AppEvent {
  constructor() {
    super("timeslotModified");
  }
}

export class AppointmentModified extends  AppEvent {
  constructor() {
    super("appointmentModified");
  }
}

/**
 * der Seriveprovider wurde gewechselt
 */
export class ServiceProviderChanged extends  AppEvent {
  constructor(public serviceProvider: ServiceProvider) {
    super("serviceProviderChanged");
  }
}

@Injectable()
export class EventStore {
  private _events$ = new ReplaySubject<AppEvent>();

  trigger(event: AppEvent) {
    this._events$.next(event)
  }

  subscribe(next?: (value: AppEvent) => void, error?: (error: any) => void, complete?: () => void){
    return this._events$.subscribe(next, error, complete);
  }
}

