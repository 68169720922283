import { Component, OnInit } from '@angular/core';
import {Session} from "../services/session";
import {Router} from "@angular/router";
import {Observable} from "rxjs";
import {AppState} from "../actions/reducers";
import {Store} from "@ngrx/store";
import {AppActions} from "../actions/action-types";
import {environment} from "../../environments/environment";

@Component({
  selector: 'app-top-menu',
  templateUrl: './top-menu.component.html',
  styleUrls: ['./top-menu.component.scss']
})
export class TopMenuComponent implements OnInit {

  isLoggedIn$: Observable<boolean>;
  serviceProviderName$: Observable<string>;

  constructor(private session: Session, private router: Router, private store: Store<AppState>) { }

  ngOnInit(): void {
      this.isLoggedIn$ = this.session.isLoggedIn();
      this.serviceProviderName$ = this.session.serviceProviderName$;
  }

  logout() {
    this.store.dispatch(AppActions.logout());
    this.router.navigate(["/login"]);
  }

  serviceProviderSelect() {
    this.router.navigate(["/service-provider-select"]);
  }

  get isResourceManagmentOn() {
    return environment.feature_resource_managment
  }

  get hasSchoolParentTalks() {
    return this.session.serviceProviderVariant == 'school';
  }

  get hasSections() {
    return this.session.serviceProviderVariant == 'school';
  }

  get hasServiceProvider() {
    return this.session.hasServiceProdiver()
  }
}
