import {Component} from '@angular/core';
import {MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-wod-update-serie',
  templateUrl: './appointment-update-serie.component.html',
  styleUrls: ['./appointment-update-serie.component.scss']
})
export class AppointmentUpdateSerieComponent {
  public selectedType: string = 'single';
  public types: { value: string, label: string }[] = [
    {value: 'single', label: 'Diesen Termin'},
    {value: 'all', label: 'Diesen Termin und alle folgenden'}];

  constructor(public dialogRef: MatDialogRef<AppointmentUpdateSerieComponent>) {
  }

  onCancelClick(): void {
    this.dialogRef.close({update: false});
  }

  onUpdateClick() {
    switch (this.selectedType) {
      case 'single':
        this.updateSingle();
        break;
      case 'all':
        this.updateAll();
        break;
      default:
        this.dialogRef.close({update: false});
    }
  }

  private updateSingle() {
    this.dialogRef.close({update: true, mode: 'single'});
  }

  private updateAll() {
    this.dialogRef.close({update: true, mode: 'all'});
  }
}
